import React, { Component } from 'react'
import { sortable } from 'react-sortable';

import { NavLink } from 'react-router-dom'

import SidebarSubMenuComponents from './sidebarSubMenuComponents';

// export default class SidebarMenuComponents extends Component {
class Item extends React.Component {
    state = {};

    onSortItems = (items) => {
        console.log(items)
        this.setState({
            items: items
        });
    }
    
    render() {
        const prop_data = this.props.items[this.props['data-id']];
        const items = prop_data.child;
        var counter_flag = 0;
        // if(prop_data.child && counter_flag == 0) {
        //     this.setState({
        //         items: items
        //     });
        //     counter_flag = 1;
        // }
        // console.log(this.state);


        var listItems = items.map((item, i) => {
            return (
                <SidebarSubMenuComponents
                    key={i}
                    onSortItems={this.onSortItems}
                    items={items}
                    sortId={i}>{item.menu_name}</SidebarSubMenuComponents>
            );
        });


        if(prop_data.type == "accordian")
            return (

                <li className="nav-item" {...this.props}>
                    <div className="accordion" role="tablist" id={`accordion-${prop_data.id}`} style={{background: 'rgba(255,255,255,0)',borderRadius: '0'}}>
                        <div className="accordion-item" style={{borderStyle: 'none', borderRadius: '0px'}}>
                            <h2 className="accordion-header" role="tab" style={{borderStyle: 'none', borderRadius: '0px'}}>
                                <button className="accordion-button collapsed text-light" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-${prop_data.id} .item-1`} aria-expanded="false" aria-controls={`accordion-${prop_data.id} .item-1`} style={{background: '#073A5D', borderStyle: 'none', padding: '16px', fontSize: '13.6px',borderRadius: '0px'}}><img className='menuIcon' src={prop_data.logo} />{this.props.children}</button>
                            </h2>
                            <div className="accordion-collapse collapse item-1" role="tabpanel" data-bs-parent={`#accordion-${prop_data.id}`} style={{padding: '0px',margin: '0px'}}>
                                <div className="accordion-body">
                                    {listItems}
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            )
        else if(prop_data.type == "link")
            return (

                <li className="nav-item" {...this.props}>
                    <NavLink className="nav-link indent-18px" to={`${prop_data.link}`}><span>{this.props.children}</span></NavLink>
                </li>
            )
        else 
            return (

                <li className="nav-item" {...this.props}>
                    <NavLink className="nav-link indent-18px" to={`${prop_data.link}`}><span>{this.props.children}</span></NavLink>
                </li>
            )

    }
}
var SidebarMenuComponents = sortable(Item);

export default SidebarMenuComponents;