import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import { retrieve, retrieveSingle, retrieveChangedLocations } from './actions/Locations'


const LocationDetails = ({ locationId }) => {
  const [singleLocationData, setSingleLocationData] = useState(null);
  const dispatch = useDispatch();

  let fetchSingleLocation = () => {
    let data = {
      locationId: String(locationId),
      partnerCode: 'OpenAnswers',
    };
    dispatch(retrieveSingle(data)).then(response => {
      // console.log(response)
      setSingleLocationData(response.response)
    });
  }

  useEffect(() => {
    if(locationId)
      fetchSingleLocation();
  }, [locationId]);

  return (
    <>{singleLocationData && singleLocationData.message ? singleLocationData.message : !singleLocationData ? '' :
      <ul className='locationDetails'>
        <li>{singleLocationData.brandName?singleLocationData.brandName:''}</li>
        <li>Brand Id: {singleLocationData.brandId?singleLocationData.brandId:''}</li>
        <li>Care Home: {singleLocationData.careHome?singleLocationData.careHome:''}</li>
        <li>Constituency: {singleLocationData.constituency?singleLocationData.constituency:''}</li>
        {/* <li>currentRatings: {singleLocationData.currentRatings?singleLocationData.currentRatings:''}</li> */}
        <li>Dormancy: {singleLocationData.dormancy?singleLocationData.dormancy:''}</li>
        {/* <li>gacServiceTypes: {singleLocationData.gacServiceTypes?singleLocationData.gacServiceTypes:''}</li> */}
        {/* <li>inspectionAreas: {singleLocationData.inspectionAreas?singleLocationData.inspectionAreas:''}</li> */}
        {/* <li>inspectionCategories: {singleLocationData.inspectionCategories?singleLocationData.inspectionCategories:''}</li> */}
        <li>Inspection Directo Rate: {singleLocationData.inspectionDirectorate?singleLocationData.inspectionDirectorate:''}</li>
        {/* <li>lastInspection: {singleLocationData.lastInspection?singleLocationData.lastInspection:''}</li> */}
        {/* <li>lastReport: {singleLocationData.lastReport?singleLocationData.lastReport:''}</li> */}
        <li>Local Authority: {singleLocationData.localAuthority?singleLocationData.localAuthority:''}</li>
        <li>Location Id: {singleLocationData.locationId?singleLocationData.locationId:''}</li>
        {/* <li>locationTypes: {singleLocationData.locationTypes?singleLocationData.locationTypes:''}</li> */}
        <li>Name: {singleLocationData.name?singleLocationData.name:''}</li>
        <li>Number Of Beds: {singleLocationData.numberOfBeds?singleLocationData.numberOfBeds:''}</li>
        <li>ODS Code: {singleLocationData.odsCode?singleLocationData.odsCode:''}</li>
        <li>ONSPD Ccg Code: {singleLocationData.onspdCcgCode?singleLocationData.onspdCcgCode:''}</li>
        <li>ONSPD Ccg Name: {singleLocationData.onspdCcgName?singleLocationData.onspdCcgName:''}</li>
        <li>ONSPD Icb Code: {singleLocationData.onspdIcbCode?singleLocationData.onspdIcbCode:''}</li>
        <li>ONSPD Icb Name: {singleLocationData.onspdIcbName?singleLocationData.onspdIcbName:''}</li>
        <li>ONSPD Latitude: {singleLocationData.onspdLatitude?singleLocationData.onspdLatitude:''}</li>
        <li>ONSPD Longitude: {singleLocationData.onspdLongitude?singleLocationData.onspdLongitude:''}</li>
        <li>Organisation Type: {singleLocationData.organisationType?singleLocationData.organisationType:''}</li>
        <li>Postal Address Line 1: {singleLocationData.postalAddressLine1?singleLocationData.postalAddressLine1:''}</li>
        <li>Postal Address Town City: {singleLocationData.postalAddressTownCity?singleLocationData.postalAddressTownCity:''}</li>
        <li>Postal Code: {singleLocationData.postalCode?singleLocationData.postalCode:''}</li>
        <li>provider Id: {singleLocationData.providerId?singleLocationData.providerId:''}</li>
        <li>Region: {singleLocationData.region?singleLocationData.region:''}</li>
        <li>Registered Manager Absent Date: {singleLocationData.registeredManagerAbsentDate?singleLocationData.registeredManagerAbsentDate:''}</li>
        <li>Registration Date: {singleLocationData.registrationDate?singleLocationData.registrationDate:''}</li>
        <li>Registration Status: {singleLocationData.registrationStatus?singleLocationData.registrationStatus:''}</li>
        {/* <li>regulatedActivities: {singleLocationData.regulatedActivities?singleLocationData.regulatedActivities:''}</li> */}
        {/* <li>relationships: {singleLocationData.relationships?singleLocationData.relationships:''}</li> */}
        {/* <li>reports: {singleLocationData.reports?singleLocationData.reports:''}</li> */}
        {/* <li>specialisms: {singleLocationData.specialisms?singleLocationData.specialisms:''}</li> */}
        <li>Type: {singleLocationData.type?singleLocationData.type:''}</li>
        <li>UPRN: {singleLocationData.uprn?singleLocationData.uprn:''}</li>
      </ul>
    }
    </>
  );
}



export default function Locations() {
  const [locationData, setLocationData] = useState(null);
  const [changedLocationData, setChangedLocationData] = useState(null);
  const [Locations, setLocations] = useState([]);
  const [currentPageLocation, setCurrentPageLocation] = useState(1);
  const [perPageLocation, setPerPageLocation] = useState(10);
  const [currentPageChangedLocation, setCurrentPageChangedLocation] = useState(1);
  const [perPageChangedLocation, setPerPageChangedLocation] = useState(10);
  const [locationIdLocation, setLocationIdLocation] = useState('');
  const [locationIdChangedLocation, setLocationIdChangedLocation] = useState('');
  const dispatch = useDispatch();

  let fetchLocations = (page, perPage) => {
    setCurrentPageLocation(page)
    setPerPageLocation(perPage)
    let data = {
      page: String(page),
      perPage: String(perPage),
      partnerCode: 'OpenAnswers',
    };
    dispatch(retrieve(data)).then(response => {
      setLocationData(response.response)
      // console.log(response)
    });
  }

  let fetchChangedLocations = (page, perPage) => {
    setCurrentPageChangedLocation(page);
    setPerPageChangedLocation(perPage);
    let data = {
      page: String(currentPageChangedLocation),
      perPage: String(perPage),
      startTimestamp: "2019-03-10T06:30:00Z",
      endTimestamp: String(new Date().toISOString().split('.')[0]) + 'Z',
      partnerCode: 'OpenAnswers',
    };
    dispatch(retrieveChangedLocations(data)).then(response => {
      setChangedLocationData(response.response)
    });
  }


  useEffect(() => {
    fetchLocations(currentPageLocation, perPageLocation);
    fetchChangedLocations(currentPageChangedLocation, perPageChangedLocation);
  }, []);

  const handleLocationPreviousClick = () => {
    if (currentPageLocation > 1) {
      fetchLocations(currentPageLocation - 1, perPageLocation);
    }
  };

  const handleLocationNextClick = () => {
    if (currentPageLocation < locationData.totalPages) {
      fetchLocations(currentPageLocation + 1, perPageLocation);
    }
  };

  const handleChangedLocationPreviousClick = () => {
    if (currentPageChangedLocation > 1) {
      setCurrentPageChangedLocation(currentPageChangedLocation - 1);
      fetchChangedLocations(currentPageChangedLocation - 1, perPageChangedLocation);
    }
  };

  const handleChangedLocationNextClick = () => {
    if (currentPageChangedLocation < locationData.totalPages) {
      setCurrentPageChangedLocation(currentPageChangedLocation + 1);
      fetchChangedLocations(currentPageChangedLocation + 1, perPageChangedLocation);
    }
  };
  return (
    <>
      <div class="container-fluid mt-5">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item mr-2" role="presentation">
            <button class="nav-link active" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="true">Locations</button>
          </li>
          <li class="nav-item mr-2" role="presentation">
            <button class="nav-link" id="pills-inactive-tab" data-bs-toggle="pill" data-bs-target="#pills-inactive" type="button" role="tab" aria-controls="pills-inactive" aria-selected="false">Changed Locations</button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab">
            <div className='row'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                      <h3 class="mb-0 fg-theme">Locations</h3>
                    </div>
                    <div class="card shadow">
                      <div class="card-body">
                        <div className='container-fluid'>
                          <div class="row">
                            <div class="col-md-6 text-nowrap">
                              <div id="dataTable_length" class="dataTables_length" aria-controls="dataTable">
                                <label class="form-label">Show&nbsp;
                                  <select class="d-inline-block form-select form-select-sm" name="record_length" value={perPageLocation} onChange={(e) => { console.log("Location", e.target.value); fetchLocations(perPageLocation, e.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </label>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="text-md-end dataTables_filter" id="dataTable_filter">
                                <label class="form-label">
                                  <input type="search" class="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='container-fluid'>
                          {locationData && (
                            <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                              <table class="table my-0" id="dataTable">
                                <thead>
                                  <tr>
                                    <th>Location ID</th>
                                    <th>Location Name</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {locationData && locationData.locations && locationData.locations.length > 0 ? locationData.locations.map((location) => {
                                    return (
                                      <tr key={location.locationId}>
                                        <td>{location.locationId}</td>
                                        <td>{location.locationName}</td>
                                        <td><button className='btn btn-primary btn-sm' type='button' onClick={() => setLocationIdLocation(location.locationId)}><i className='fa fa-angle-right'></i></button></td>
                                      </tr>
                                    )
                                  }) : ''}
                                </tbody>
                              </table>
                              <div class="">
                                {/* <div class="col-md-6 align-self-center">
                        <p id="dataTable_info" class="dataTables_info" role="status" aria-live="polite">Total Records 5</p>
                      </div> */}
                                <div class="offset-md-6 col-md-6">
                                  <nav class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination">
                                      <li class="page-item" onClick={handleLocationPreviousClick} disabled={currentPageLocation === 1}>
                                        <a class="page-link" aria-label="Previous" href="#">
                                          <span aria-hidden="true">«</span> Previous</a>
                                      </li>
                                      <li class="page-item" onClick={handleLocationNextClick} disabled={currentPageLocation === locationData.totalPages}>
                                        <a class="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                      <h3 class="mb-0 fg-theme">&nbsp;</h3>
                    </div>
                    <div class="card shadow">
                      <div class="card-body">
                        <div className='container-fluid'>
                          <div class="row">
                            <LocationDetails locationId={locationIdLocation} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-inactive" role="tabpanel" aria-labelledby="pills-inactive-tab">
            <div className='row'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                      <h3 class="mb-0 fg-theme">Changed Locations</h3>
                      <div class="text-right">
                      </div>
                    </div>
                    <div class="card shadow">
                      <div class="card-body">
                        <div className='container-fluid'>
                          <div class="row">
                            <div class="col-md-6 text-nowrap">
                              <div id="dataTable_length" class="dataTables_length" aria-controls="dataTable">
                                <label class="form-label">Show&nbsp;
                                  <select class="d-inline-block form-select form-select-sm" name="record_length" value={perPageChangedLocation} onChange={(e) => { console.log("ChangedLocation", e.target.value); fetchChangedLocations(perPageChangedLocation, e.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </label>
                              </div>
                            </div>
                            {/* <div class="col-md-6">
                          <div class="text-md-end dataTables_filter" id="dataTable_filter">
                            <label class="form-label">
                              <input type="search" class="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" />
                            </label>
                          </div>
                        </div> */}
                          </div>
                        </div>
                        <div className='container-fluid'>
                          {changedLocationData && (
                            <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                              <table class="table my-0" id="dataTable">
                                <thead>
                                  <tr>
                                    <th>Location ID</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {changedLocationData && changedLocationData.changes && changedLocationData.changes.length > 0 ? changedLocationData.changes.map((location, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{location}</td>
                                        <td><button className='btn btn-primary btn-sm' type='button' onClick={() => setLocationIdChangedLocation(location)}><i className='fa fa-angle-right'></i></button></td>
                                      </tr>
                                    )
                                  }) : ''}
                                </tbody>
                              </table>
                              <div class="">
                                {/* <div class="col-md-6 align-self-center">
                              <p id="dataTable_info" class="dataTables_info" role="status" aria-live="polite">Total Records 5</p>
                            </div> */}
                                <div class="offset-md-6 col-md-6">
                                  <nav class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination">
                                      <li class="page-item" onClick={handleChangedLocationPreviousClick} disabled={currentPageLocation === 1}>
                                        <a class="page-link" aria-label="Previous" href="#">
                                          <span aria-hidden="true">«</span> Previous</a>
                                      </li>
                                      <li class="page-item" onClick={handleChangedLocationNextClick} disabled={currentPageLocation === changedLocationData.totalPages}>
                                        <a class="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                      <h3 class="mb-0 fg-theme">&nbsp;</h3>
                    </div>
                    <div class="card shadow">
                      <div class="card-body">
                        <div className='container-fluid'>
                          <div class="row">
                            <LocationDetails locationId={locationIdChangedLocation} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
