import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink, useParams } from 'react-router-dom';

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";


import {
    create, update, retrieve
} from "./actions/arcCarePlan";

export default function ArchitectureCarePlan_add(props) {
    const { server_id, service_user_id } = useParams();
    const dispatch = useDispatch();
    const InitialData = {
        care_plan_id: '',
        user_type: 'admin',
        server_id: server_id,
        admin_id: 'superadmin',
        service_user_id: service_user_id,
        date: new Date(),
        month: '',
        year: '',
        start_time: '10:00',
        end_time: '10:00',
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [createModalVisibility, setCreateModalVisibility] = useState(false);

    const [errorData, setErrorData] = useState(InitialErrData);

    const [date, setDate] = useState(InitialData.date);

    const [startTimeValue, setStartTimeValue] = useState(InitialData.start_time);
    const [endTimeValue, setEndTimeValue] = useState(InitialData.end_time);
    const [oldProp, setOldProp] = useState({});
    const [formData, setFormData] = useState((props && props.is_edit && props.props && props.props.care_plan_id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);

    useEffect(() => {
        if (props && props.is_edit && props.props && props.props.care_plan_id) {
            setCreateModalVisibility(true);
            setFormData({
                care_plan_id: props.props.care_plan_id,
                date: props.props.record_data.date,
            })
        } else {
            setCreateModalVisibility(false);
            setFormData(InitialData)
        }
    }, [oldProp, props]);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        // if(props.callingExternal)
        props.updateParentState(false)
    }

    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    const onChange = (key, timeValue) => {
        if (key == 'start_time')
            setStartTimeValue(timeValue.time);
        if (key == 'end_time')
            setEndTimeValue(timeValue.time);
        // console.log(timeValue.time)
    }




    let checkValidation = (name, value) => {
        let errorDataTemp = { name: name, status: 0, message: ' Invalid Data. ' };
        let errorstatus = 0;
        setErrorData({});
        if (value == '' && name == 'date') {
            // console.log(value)
            setErrorData(errorDataTemp);
            errorstatus = 1;
        } else if (value == '' && name == 'start_time') {
            // console.log(value)
            setErrorData(errorDataTemp);
            errorstatus = 1;
        } else if (value == '' && name == 'end_time') {
            // console.log(value)
            setErrorData(errorDataTemp);
            errorstatus = 1;
        } else { }
        // console.log(name, value, name == 'ContactNumber', isText(value), errorstatus)
        return errorstatus;
    }

    let validationHandler = () => {
        const re = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$&*~]).{8,16}$');
        let response = { res: 1, msg: 'Validated.' };
        if (formData?.name === undefined || formData?.name === '' || formData?.name.length == 0)
            response = { res: 0, key: 'name', msg: 'name is required.' };
        else if (formData?.order === undefined || formData?.order === '' || formData?.order.length == 0)
            response = { res: 0, key: 'order', msg: 'Order is required' };
        else { }
        return response;
    }

    let handleSave = () => {
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            if (formData?.care_plan_id == undefined || formData?.care_plan_id == '' || formData?.care_plan_id.length == 0)
                dispatch(create(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false);
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            // server_id: server_id,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
            else
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false);
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            // server_id: server_id,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
        }
    }
    // console.log("props", props.props, (props && props.props && props.props.care_plan_id)?1:0);

    return (
        <>
            <div className="card card-default" role="document">
                <div className="card-body">
                    <div className="card-header">
                        <h4 className="modal-title">Add Care Plan</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}></button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Date</label>
                                            <DatePicker className='form-control' selected={date} dateFormat="dd/MM/yyyy" onChange={(date) => setDate(date)} />
                                            {/* {(errorData?.res == 0 && errorData?.key == 'date') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errorData?.msg}</small>
                                    : ''} */}
                                            {/* <input className="form-control" type="text" name="date" placeholder="Date" required="" /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Start Time</label>
                                            <input type='time' className='form-control' step="1" placeholder="Start Time" onChange={(ev) => { onChange('start_time', { time: ev.target.value }) }} value={startTimeValue} />
                                            {/* {(errorData?.res == 0 && errorData?.key == 'time') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errorData?.msg}</small>
                                    : ''} */}
                                            {/* <input className="form-control" type="text" name="start_time" placeholder="Start Time" required="" /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">End Time</label>
                                            <input type='time' className='form-control' step="1" placeholder="End Time" onChange={(ev) => { onChange('end_time', { time: ev.target.value }) }} value={endTimeValue} />
                                            {/* {(errorData?.res == 0 && errorData?.key == 'time') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errorData?.msg}</small>
                                    : ''} */}
                                            {/* <input className="form-control" type="text" name="end_time" placeholder="End Time" required="" /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-light" type="button" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}>Close</button>
                        <button className="btn btn-primary" type="button" onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>
        </>
    )
}
