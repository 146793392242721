import {
    CREATE_ARCHITECTUREACTIVITIES,
    RETRIEVE_ARCHITECTUREACTIVITIES,
    RETRIEVESINGLE_ARCHITECTUREACTIVITIES,
    UPDATE_ARCHITECTUREACTIVITIES,
    DELETE_ARCHITECTUREACTIVITIES,
  } from "../actions/type";
  const initialState = [];
  function ARCActivitiesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTUREACTIVITIES:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTUREACTIVITIES:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTUREACTIVITIES:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTUREACTIVITIES:
        return {data: state.data, payload};
      case DELETE_ARCHITECTUREACTIVITIES:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCActivitiesReducer};