import {
    // FILE_UPLOAD,
    CREATE_MEDICINES,
    RETRIEVE_MEDICINES,
    RETRIEVESINGLE_MEDICINES,
    UPDATE_MEDICINES,
    DELETE_MEDICINES,
} from "./type";
import Medicines from "../service/Medicines";
export const create = (data) => async (dispatch) => {
    try {
        const res = await Medicines.create(data);
        dispatch({
            type: CREATE_MEDICINES,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await Medicines.fetchList(data);
        dispatch({
            type: RETRIEVE_MEDICINES,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await Medicines.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_MEDICINES,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await Medicines.update(data);
        dispatch({
            type: UPDATE_MEDICINES,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await Medicines.changeStatus(data);
//         dispatch({
//             type: UPDATE_MEDICINES,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await Medicines.trash(data);
        dispatch({
            type: DELETE_MEDICINES,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};