import React, {useState, useEffect} from 'react'

import { Loader, Placeholder } from 'rsuite';
import SupportTicketsDatatable from './architectureSupportTicketsDatatable';

export default function ArchitectureSupportTickets() {
    const [loading, setLoading] = useState(false);
    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item mr-2" role="presentation">
                            <button className="nav-link active" id="pills-admin-tab" data-bs-toggle="pill" data-bs-target="#pills-admin" type="button" role="tab" aria-controls="pills-admin" aria-selected="true">Admin</button>
                        </li>
                        <li className="nav-item mr-2" role="presentation">
                            <button className="nav-link" id="pills-employee-tab" data-bs-toggle="pill" data-bs-target="#pills-employee" type="button" role="tab" aria-controls="pills-employee" aria-selected="false">Employee</button>
                        </li>
                        <li className="nav-item mr-2" role="presentation">
                            <button className="nav-link" id="pills-service-users-tab" data-bs-toggle="pill" data-bs-target="#pills-service-users" type="button" role="tab" aria-controls="pills-service-users" aria-selected="false">Service Users</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-admin" role="tabpanel" aria-labelledby="pills-admin-tab">
                            <SupportTicketsDatatable type={'admin'} />
                        </div>
                        <div className="tab-pane fade" id="pills-employee" role="tabpanel" aria-labelledby="pills-employee-tab">
                            <SupportTicketsDatatable type={'staff'} />
                        </div>
                        <div className="tab-pane fade" id="pills-service-users" role="tabpanel" aria-labelledby="pills-service-users-tab">
                            <SupportTicketsDatatable type={'service_users'} />
                        </div>
                    </div>
                </>
            }
        </>
    )
}
