import {
	RETRIEVE_GOOGLE_CALENDAR_EVENTS,
	CHANGE_RESERVATION_VIEW,

	EQUIPMENT_LIST,
	EQUIPMENT_TYPE_LIST,
} from "./type";
import ReservePageService from "../service/reservepage.service";

export const retrieveAllCalendarEvents = (_, requestOptions) => async (dispatch) => {
	try {
		const res = await ReservePageService.getAllCalendarEvents(_, requestOptions);
		dispatch({
			type: RETRIEVE_GOOGLE_CALENDAR_EVENTS,
			payload: res,
		});
	} catch (err) {
		console.log(err);
	}
};

export const changeReservationView = (view) => async (dispatch) => {
	try {
		dispatch({
			type: CHANGE_RESERVATION_VIEW,
			payload: view,
		});
		// console.log(view)
		return Promise.resolve(view);

	} catch (err) {
      return Promise.reject(err);
	//   console.log(err);
	}
};
// export const retrieveEquipmentList = (data) => async (dispatch) => {
//   try {
// 	const res = await ReservePageService.fetchEquipmentList(data);
// 	// console.log(res.data);
// 	dispatch({
// 	  type: EQUIPMENT_LIST,
// 	  status: '',
// 	  response: {},
// 	  payload: res.data,
// 	});
//   } catch (err) {
// 	console.log(err);
//   }
// };
// export const retrieveEquipmentTypeList = (data) => async (dispatch) => {
//   try {
// 	const res = await ReservePageService.fetchEquipmentTypeList(data);
// 	dispatch({
// 	  type: EQUIPMENT_TYPE_LIST,
// 	  status: '',
// 	  response: {},
// 	  payload: res.data,
// 	});
//   } catch (err) {
// 	console.log(err);
//   }
// };