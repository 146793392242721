import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

// import avatar1 from '../../assets/avatars/avatar1.jpeg'
// import avatar4 from '../../assets/avatars/avatar4.jpeg'
// import avatar2 from '../../assets/avatars/avatar2.jpeg'
// import avatar3 from '../../assets/avatars/avatar3.jpeg'
// import avatar5 from '../../assets/avatars/avatar5.jpeg'

import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';

import { Loader, Placeholder } from 'rsuite';


import {
    retrieve, trash,
} from "./actions/ActiveSharableLinks";
import ActiveSharableLinks_add from './ActiveSharableLinks_add';
import { NavLink } from 'react-router-dom';
export default function ActiveSharableLinks() {
    const active_sharable_link_data = useSelector(state => state.ActiveSharableLinksReducer, shallowEqual);
    const [loading, setLoading] = useState(true);
    const initial_single_data = {
        id: '', 
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [page, setPage] = useState(0);
    const [record_length, setRecordLength] = useState(10);
    const [showTrashAlert, setShowTrashAlert] = useState({show: false});

    const dispatch = useDispatch();

    useEffect(() => {
        let data = {
            limit: 10,
            first_active_sharable_link_id: '',
            last_active_sharable_link_id: '',
        };
        dispatch(retrieve(data)).then(() => setLoading(false));
    }, []);

    let formatDate = (datex) => {
      const date = new Date(datex);
      let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const yyyy = date.getFullYear();
      let mm = monthArr[date.getMonth()]; // Months start at 0!
      // let mm = date.getMonth() + 1; // Months start at 0!
      let dd = date.getDate();
  
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
  
      const formattedToday = mm + ' ' + dd + ', ' + yyyy;
      return formattedToday;
    }

    let handleChange = (e) => {
        if(e.target.name == 'record_length')
            setRecordLength(e.target.value);
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            limit: record_length,
            last_uid: '',
            first_uid: active_sharable_link_data.data.first_uid,
        };
        dispatch(retrieve(data)).then(() => {
            setPage(page?page - 1:page)
            setLoading(false)
        });
    }
    let handleNext = () => {
        setLoading(true);
        // console.log(
        //     parseInt((
        //         active_sharable_link_data && 
        //         active_sharable_link_data.data && 
        //         active_sharable_link_data.data.total ? active_sharable_link_data.data.total:1
        //     ) / record_length), page);
        setPage((
            page <= (
                parseInt((
                    active_sharable_link_data && 
                    active_sharable_link_data.data && 
                    active_sharable_link_data.data.total ? active_sharable_link_data.data.total:1
                ) / record_length) + 1
            ))
            ?page + 1
            :page
        );
        let data = {
            limit: record_length,
            last_uid: active_sharable_link_data.data.last_uid,
            first_uid: '',
        };
        dispatch(retrieve(data)).then(() => setLoading(false));
    }

    let handleEdit = (record) => {
        setSingleData(
            {
                sector_id: record.id,
                record_data: record
            }
        )
        // console.log(single_data)
    }

    let handleShowTrashAlert = (uid) => {
        // console.log(showTrashAlert, uid)
        setShowTrashAlert({uid: uid, show: !showTrashAlert.show});
    }

    let handleAlertResponse = () => {
        handleDelete();
    }

    let handleDelete = () => {
        setLoading(true);
        let data = {
            uid: showTrashAlert.uid,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            let data = {
                limit: record_length,
                last_uid: '',
                first_uid: '',
            };
            dispatch(retrieve(data)).then(() => {
                setLoading(false);
                setTimeout(() => {
                    setShowTrashAlert({show: !showTrashAlert.show});
                    // console.log(!showTrashAlert.show)
                }, 1000);
            });
        });
    }




    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
            :
                <>
                    <div className="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 className="mb-0 fg-theme">Active Sharable Links</h3>
                        <div className='text-right'>
                            {/* <ActiveSharableLinks_add props={single_data} is_edit={true} />
                            <ActiveSharableLinks_add is_edit={false} /> */}
                            <NavLink to={'/SharableLinks/active/add'} ><button className='btn btn-primary' type='button'><i className='fa fa-plus'></i> Add New Link</button></NavLink>
                        </div>
                    </div>
                    <div className="card shadow">
                        {/* <div className="card-header py-3">
                            <p className="text-primary m-0 fw-bold">Active Sharable Link List</p>
                        </div> */}
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 text-nowrap">
                                    <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                        <label className="form-label">Show&nbsp;
                                            <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                                </div>
                            </div>
                            <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                <table className="table my-0" id="dataTable">
                                    <thead>
                                        <tr>
                                            <th>Email</th>
                                            <th>URL</th>
                                            <th>Valid Upto</th>
                                            <th>Visit Count</th>
                                            {/* <th>Tags</th> */}
                                            <th>Created On</th>
                                            <th>Access Blocked</th>
                                            {/* <th>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            active_sharable_link_data &&
                                            active_sharable_link_data.data &&
                                            active_sharable_link_data.data.links &&
                                            active_sharable_link_data.data.links.length > 0 ?
                                            active_sharable_link_data.data.links.map((val, index) => {
                                                return (
                                                    <tr>
                                                        <td>{val.email}</td>
                                                        <td>{val.url}</td>
                                                        <td>{val.validity}</td>
                                                        <td>{val.visit_counter}</td>
                                                        <td>{formatDate(val.created_on)}</td>
                                                        {/* <td>{val.architecture_tags}<br />{val.sjf_tags}</td> */}
                                                        <td>{val.access_blocked}</td>
                                                        {/* <td>
                                                            <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.uid)}><i className="fas fa-trash fg-theme"></i></button>
                                                        </td> */}
                                                    </tr>
                                                )
                                            })
                                            : ''
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {active_sharable_link_data && active_sharable_link_data.data && active_sharable_link_data.data.total?active_sharable_link_data.data.total:0}</p>
                                </div>
                                <div className="col-md-6">
                                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                        <ul className="pagination">
                                            <li className={page?"page-item":"page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                            <li className={(page <= (parseInt((active_sharable_link_data && active_sharable_link_data.data && active_sharable_link_data.data.total?active_sharable_link_data.data.total:1) / record_length) - 1))?"page-item":"page-item disabled"}  onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SweetAlert
                        show={showTrashAlert.show}
                        title=""
                        text="Are you sure you want to permanently delete this record?"
                        onCancel={() => handleShowTrashAlert(false)}
                        showCancelButton
                        onConfirm={handleAlertResponse}
                    />
                </>
            }
        </>
    )
}
