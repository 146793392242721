import {
    // FILE_UPLOAD,
    CREATE_MEDICINE_DETAILS,
    RETRIEVE_MEDICINE_DETAILS,
    RETRIEVESINGLE_MEDICINE_DETAILS,
    UPDATE_MEDICINE_DETAILS,
    DELETE_MEDICINE_DETAILS,
} from "./type";
import MedicineDetails from "../service/MedicineDetails";
export const create = (data) => async (dispatch) => {
    try {
        const res = await MedicineDetails.create(data);
        dispatch({
            type: CREATE_MEDICINE_DETAILS,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await MedicineDetails.fetchList(data);
        dispatch({
            type: RETRIEVE_MEDICINE_DETAILS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await MedicineDetails.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_MEDICINE_DETAILS,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await MedicineDetails.update(data);
        dispatch({
            type: UPDATE_MEDICINE_DETAILS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await MedicineDetails.changeStatus(data);
//         dispatch({
//             type: UPDATE_MEDICINE_DETAILS,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await MedicineDetails.trash(data);
        dispatch({
            type: DELETE_MEDICINE_DETAILS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};