import http from "../../../../http-axios.function";


const create = (data) => {
    return http.post(`/super_admin/architecture/support_tickets/create`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchAdminList = (data) => {
    return http.post(`/super_admin/architecture/support_tickets/fetch_admin`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchStaffList = (data) => {
    return http.post(`/super_admin/architecture/support_tickets/fetch_employee`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchServiceUserList = (data) => {
    return http.post(`/super_admin/architecture/support_tickets/fetch_service_user`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchSingle = (data) => {
    return http.post(`/super_admin/architecture/support_tickets/fetch_single`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const update = (data) => {
    return http.post(`/super_admin/architecture/support_tickets/update`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


// const changeStatus = (data) => {
//     return http.post(`/super_admin/architecture/support_tickets/changestatus`, {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             "Access-Control-Allow-Origin": "*",
//         }, data
//     });
// };


const trash = (data) => {
    return http.post(`/super_admin/architecture/support_tickets/delete`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};



const SupportTickets = {
    fetchAdminList,
    fetchStaffList,
    fetchServiceUserList,
    fetchSingle,
    create,
    update,
    // changeStatus,
    trash,
};
export default SupportTickets;