import React, { useState } from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Loader, Placeholder } from 'rsuite';

import Popup from '../../../../components/popup';

import logo from '../../../../assets/logo.png';
import '../Employee/Employee.css';
import Avatar from '../../../../assets/avatars/avatar1.jpeg';

import SingleEmployee from './components/SingleEmployee';
import EmployeeForm from './components/EmployeeForm';
import ReactSession from '../../../../components/ReactSession';
// import { firebase } from '../../Firebase';
// import { collection, onSnapshot, doc, query, getFirestore, orderBy } from 'firebase/firestore';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { retrieveStats } from './actions/Employee';
import { retrieveEmployeeStats } from '../VisitManagement/actions/VisitManagement';

export default function Employee() {
  const { server_id, admin_id } = useParams();
  const InitialData = [];
  let [employeeData, setEmployeeData] = useState(InitialData);

  let [loading, setLoading] = useState(true);
  let [visibility_status, setVisibilityStatus] = useState(false);
  let [progress_data_employees, setProgressDataEmployees] = useState({})

  const dispatch = useDispatch();

  const [employeeCount, setEmployeeCount] = useState({
    total: 0,
    active: 0,
  });

  const getEmployeeData = (server_id, admin_id) => {
    let filter = {
      server_id: server_id,
      uid: admin_id,
    }

    dispatch(retrieveStats(filter)).then(response => {
      setLoading(false)
      // console.log(response)
      setEmployeeData(response.stats);
    });
    
    dispatch(retrieveEmployeeStats(filter)).then(response => {
      // console.log(response)
      setProgressDataEmployees(response.data.stats)
    });
  };
  useEffect(() => {
    getEmployeeData(server_id, admin_id);
  }, [server_id, admin_id]);
  return (
    <>
      {loading ? (
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="d-sm-flex justify-content-between align-items-center mb-4">
                <h3 className="mb-0 Poppins fg-theme">Employee</h3>
                {/* <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="#"><i className="fas fa-download fa-sm"></i>&nbsp;Generate Report</a> */}
                {/* <button className="btn btn-primary" onClick={() => setVisibilityStatus(true)}>
                  Add New Employee Account
                </button>

                <Popup visibility_status={visibility_status}>
                  <EmployeeForm />
                </Popup> */}
              </div>
            </div>
            <div className="row">
              <div className="container-fluid text-right"></div>
            </div>
            <div className="row text-center justify-content-center">
              <div className="col-xs-6 col-sm-6 col-md-3 p-2 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="fw-bold fg-dark">Active Employees</h5>
                    <h3 className="fw-bold mt-3 fg-theme">{progress_data_employees?.total}</h3>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-3 p-2 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="fw-bold fg-dark">Total Employees</h5>
                    <h3 className="fw-bold mt-3 fg-theme">{progress_data_employees?.active}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {employeeData.length > 0
                ? employeeData.map((single_employee, index) => {
                    return <SingleEmployee single_employee={single_employee} key={index} />;
                  })
                : ''}
            </div>
          </div>
        </>
      )}
    </>
  );
}
