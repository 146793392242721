import {
    // FILE_UPLOAD,
    CREATE_ARCHITECTURESERVERSERVICEUSERS,
    RETRIEVE_ARCHITECTURESERVERSERVICEUSERS,
    RETRIEVESINGLE_ARCHITECTURESERVERSERVICEUSERS,
    UPDATE_ARCHITECTURESERVERSERVICEUSERS,
    DELETE_ARCHITECTURESERVERSERVICEUSERS,
    RETRIEVE_ARCHITECTURESERVERADMIN,
} from "./type";
import ARCServerServiceUsers from "../service/arcServerServiceUsers";
import ARCServerAdmin from "../service/arcServerAdmin";
// export const fileUpload = (data) => async (dispatch) => {
//   try {
//     const res = await ARCServerServiceUsers.fileUpload(data);
//     dispatch({
//       type: FILE_UPLOAD,
//       payload: res.data.data,
//     });
//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
export const create = (data) => async (dispatch) => {
    try {
        const res = await ARCServerServiceUsers.create(data);
        dispatch({
            type: CREATE_ARCHITECTURESERVERSERVICEUSERS,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await ARCServerServiceUsers.fetchList(data);
        dispatch({
            type: RETRIEVE_ARCHITECTURESERVERSERVICEUSERS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveServiceUser = (data) => async (dispatch) => {
    try {
        const res = await ARCServerServiceUsers.fetchList(data);
        dispatch({
            type: RETRIEVE_ARCHITECTURESERVERSERVICEUSERS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveAdmin = (data) => async (dispatch) => {
    try {
        const res = await ARCServerAdmin.fetchList(data);
        dispatch({
            type: RETRIEVE_ARCHITECTURESERVERADMIN,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await ARCServerServiceUsers.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_ARCHITECTURESERVERSERVICEUSERS,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await ARCServerServiceUsers.update(data);
        dispatch({
            type: UPDATE_ARCHITECTURESERVERSERVICEUSERS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const updateEmail = (data) => async (dispatch) => {
    try {
        const res = await ARCServerAdmin.updateEmail(data);
        // dispatch({
        //     type: UPDATE_ARCHITECTURESERVERADMIN,
        //     status: '',
        //     response: {},
        //     payload: res.data,
        // });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const updatePhone = (data) => async (dispatch) => {
    try {
        const res = await ARCServerAdmin.updatePhoneNumber(data);
        // dispatch({
        //     type: UPDATE_ARCHITECTURESERVERADMIN,
        //     status: '',
        //     response: {},
        //     payload: res.data,
        // });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const changeStatus = (data) => async (dispatch) => {
    try {
        const res = await ARCServerServiceUsers.changeStatus(data);
        dispatch({
            type: UPDATE_ARCHITECTURESERVERSERVICEUSERS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const trash = (data) => async (dispatch) => {
    try {
        const res = await ARCServerServiceUsers.trash(data);
        dispatch({
            type: DELETE_ARCHITECTURESERVERSERVICEUSERS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};