import {
    CREATE_ARCHITECTURESERVERADMIN,
    RETRIEVE_ARCHITECTURESERVERADMIN,
    RETRIEVESINGLE_ARCHITECTURESERVERADMIN,
    UPDATE_ARCHITECTURESERVERADMIN,
    DELETE_ARCHITECTURESERVERADMIN,
  } from "../actions/type";
  const initialState = [];
  function ARCServerAdminReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTURESERVERADMIN:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTURESERVERADMIN:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTURESERVERADMIN:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTURESERVERADMIN:
        return {data: state.data, payload};
      case DELETE_ARCHITECTURESERVERADMIN:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCServerAdminReducer};