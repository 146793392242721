import {
    CREATE_ARCHITECTURESERVERSERVICEUSERS,
    RETRIEVE_ARCHITECTURESERVERSERVICEUSERS,
    RETRIEVESINGLE_ARCHITECTURESERVERSERVICEUSERS,
    UPDATE_ARCHITECTURESERVERSERVICEUSERS,
    DELETE_ARCHITECTURESERVERSERVICEUSERS,
  } from "../actions/type";
  const initialState = [];
  function ARCServerServiceUsersReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTURESERVERSERVICEUSERS:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTURESERVERSERVICEUSERS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTURESERVERSERVICEUSERS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTURESERVERSERVICEUSERS:
        return {data: state.data, payload};
      case DELETE_ARCHITECTURESERVERSERVICEUSERS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCServerServiceUsersReducer};