import http from "../../../../http-axios.function";


const create = (data) => {
    return http.post(`/super_admin/sjf/regulators/create`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchList = (data) => {
    return http.post(`/super_admin/cqc/locations/list`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};


const fetchSingle = (data) => {
    return http.post(`/super_admin/cqc/locations/location`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};


const fetchChangedLocationList = (data) => {
    return http.post(`/super_admin/cqc/locations/changes`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};


const update = (data) => {
    return http.post(`/super_admin/sjf/regulators/update`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


// const changeStatus = (data) => {
//     return http.post(`/super_admin/sjf/regulators/changestatus`, {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             "Access-Control-Allow-Origin": "*",
//         }, data
//     });
// };


const trash = (data) => {
    return http.post(`/super_admin/sjf/regulators/delete`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};



const Locations = {
    fetchList,
    fetchSingle,
    create,
    update,
    // changeStatus,
    trash,
    fetchChangedLocationList,
};
export default Locations;