import React, { useState } from 'react';
// import Avatar from '../../../assets/avatars/avatar1.jpeg';
import CircularProgressBar from './CircularProgressBar';
import { Loader, Placeholder } from 'rsuite';

export default function SingleEmployee(props) {
  console.log(props.single_employee)
  let [Loading, setLoading] = useState(props.single_employee ? false : true);
  let [shortView, setShortView] = useState(true);
  let [progressValue, setProgressValue] = useState({
    value: 20,
    total_value: 100,
  })
  return (
    <>
      {Loading ? (
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
      ) :
        <div
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-2 d-grid"
          onClick={() => setShortView(!shortView)}
          key={props.key}
        >
          {/* <a href='/Employee/Overview' className="card"> */}
          <a href={'/Employee/' + props.single_employee.username} className="card">
            <div className="card-body">
              <div className="row align-items-center no-gutters">
                {/* <div className="col-auto">
                  <img src={props.single_employee.username} className="box-image p-1 img-circle" />
                </div> */}
                <div className="col me-2">
                  <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                    <span>{props.single_employee.username}</span>
                  </div>
                  {/* <div className="text-ash mb-0 Poppins">
                    <span>{props.single_employee.username}</span>
                  </div> */}
                </div>
                {/* <div className="col-auto"><i className={shortView ? "fas fa-angle-up fa-2x text-gray-300" : "fas fa-angle-down fa-2x text-gray-300"}></i></div> */}
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9">
                  <div className="row">
                    <div className="col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 p-4 mt-1">
                      <div className="container-fluid text-center p-0">Early</div>
                      {/* <div className='container-fluid'> */}
                      <CircularProgressBar data={{ value: props.single_employee.stats.early, total_value: props.single_employee.stats.total }} />
                      {/* </div> */}
                      <div className="container-fluid text-center">{props.single_employee.stats.early}</div>
                    </div>

                    <div className="col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 p-4 mt-1">
                      <div className="container-fluid text-center p-0">Late</div>
                      {/* <div className='container-fluid'> */}
                      <CircularProgressBar data={{ value: props.single_employee.stats.late, total_value: props.single_employee.stats.total }} />
                      {/* </div> */}
                      <div className="container-fluid text-center">{props.single_employee.stats.late}</div>
                    </div>

                    <div className="col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 p-4 mt-1">
                      <div className="container-fluid text-center p-0">Missed</div>
                      {/* <div className='container-fluid'> */}
                      <CircularProgressBar data={{ value: props.single_employee.stats.missed, total_value: props.single_employee.stats.total }} />
                      {/* </div> */}
                      <div className="container-fluid text-center">{props.single_employee.stats.missed}</div>
                    </div>

                    <div className="col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 p-4 mt-1">
                      <div className="container-fluid text-center p-0">Total</div>
                      {/* <div className='container-fluid'> */}
                      <CircularProgressBar data={{ value: props.single_employee.stats.total, total_value: props.single_employee.stats.total }} />
                      {/* </div> */}
                      <div className="container-fluid text-center">{props.single_employee.stats.total}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      }
    </>
  )
}
