import React, { useState, useEffect } from 'react';

import { Loader, Placeholder } from 'rsuite';
import './style.css';
import GoogleMapComponent from './components/GoogleMap'
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import Popup from '../../../../components/popup'

export default function ArchitectureServerEmployeeRun() {
    const { server_id, user_type } = useParams();
    const [Loading, setLoading] = useState(true);
    const MonthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [CurrentUser, setCurrentUser] = useState(true);
    const [OnlineEmployeeVisibility, setOnlineEmployeeVisibility] = useState(!user_type || user_type == 'online_employee' ? true : false);
    const [OfflineEmployeeVisibility, setOfflineEmployeeVisibility] = useState(!user_type || user_type == 'offline_employee' ? true : false);
    const [OnlineServiceUserVisibility, setOnlineServiceUserVisibility] = useState(!user_type || user_type == 'online_service_user' ? true : false);
    const [OfflineServiceUserVisibility, setOfflineServiceUserVisibility] = useState(!user_type || user_type == 'offline_service_user' ? true : false);
    const [AddressInfoVisibility, setAddressInfoVisibility] = useState(false);
    const [VisitInfoVisibility, setVisitInfoVisibility] = useState(false);
    const [VisitStatTimeVisibility, setVisitStatTimeVisibility] = useState(false);
    const [VisitStatDistanceVisibility, setVisitStatDistanceVisibility] = useState(false);
    const [RescheduleVisibility, setRescheduleVisibility] = useState(false);
    const [StaffPlanVisibility, setStaffPlanVisibility] = useState(false);
    const [ServiceUserPlanVisibility, setServiceUserPlanVisibility] = useState(false);
    const [CopyScheduleVisibility, setCopyScheduleVisibility] = useState(false);
    const [AutoReplanVisibility, setAutoReplanVisibility] = useState(false);
    const [AutoRebookVisibility, setAutoRebookVisibility] = useState(false);

    const [FiltersVisibility, setFiltersVisibility] = useState(false);
    const [SearchUsersVisibility, setSearchUsersVisibility] = useState(false);
    const [SmartReallocateVisibility, setSmartReallocateVisibility] = useState(false);
    const [TimeAllowancesVisibility, setTimeAllowancesVisibility] = useState(false);
    const [AlertTriggersVisibility, setAlertTriggersVisibility] = useState(false);
    const [ViewAvailableStaffVisibility, setViewAvailableStaffVisibility] = useState(false);
    const [ViewUnallocatedVisitsVisibility, setViewUnallocatedVisitsVisibility] = useState(false);
    const [HandOffShiftVisibility, setHandOffShiftVisibility] = useState(false);
    const [AutoPlanVisibility, setAutoPlanVisibility] = useState(false);
    const [ReplanUserVisibility, setReplanUserVisibility] = useState(false);
    const [ReplanEmployeeVisibility, setReplanEmployeeVisibility] = useState(false);
    const [SicknessOrLeaveVisibility, setSicknessOrLeaveVisibility] = useState(false);
    const [ConfigureParametersVisibility, setConfigureParametersVisibility] = useState(false);
    const [NonNegotiableThingsVisibility, setNonNegotiableThingsVisibility] = useState(false);
    const [EmployeeMenuVisibility, setEmployeeMenuVisibility] = useState(false);
    const [PerHourTime, setPerHourTime] = useState(20);
    
    const InitialColumnData = [
        '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30',
        '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00',
        '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30',
        '22:00', '22:30', '23:00', '23:30',
    ];

    const InitialDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    const EVENTDATA = [{ 'DAY': 'Mon', 'TIMESTART': '01:30', 'TIMEEND': '02:30' }];

    const [Slots, setSlots] = useState(InitialColumnData);
    const [Days, setDays] = useState(InitialDays);
    const [Events, setEvents] = useState(EVENTDATA);
    const [draggedTime, setDraggedTime] = useState(null);

    const [SingleUserDetails, setSingleUserDetails] = useState({});
    const [EmployeeDetails, setEmployeeDetails] = useState({});
    const [ServiceUsersDetails, setServiceUsersDetails] = useState({});
    const [TotalDistance, setTotalDistance] = useState(0);



    const [VisitDetails, setVisitDetails] = useState({});

    const handleDragStart = (event, day, time) => {
        event.dataTransfer.setData('text/plain', JSON.stringify({ day, time }));
    };

    const handleDrop = (event, droppedDay, droppedTime) => {
        event.preventDefault();
        setDraggedTime(null); // Reset the draggedTime state after dropping
        const data = JSON.parse(event.dataTransfer.getData('text/plain'));
        alert(`Dropped timeslot: ${data.day} ${data.time} to ${droppedDay} ${droppedTime}`);
    };

    const handleDragEnter = (time) => {
        setDraggedTime(time);
    };

    const allowDrop = (event) => {
        event.preventDefault();
    };


    useEffect(() => {
        fetchEmployeeStats(server_id);
        // fetchEmployeeDetails(server_id);
        fetchServiceUserStats(server_id);
        // fetchServiceUserDetails(server_id);
        // fetchUserAddress(server_id);
        // fetchVisits(server_id);
        // fetchVisits(server_id);
    }, [server_id])



    const fetchDistance = async (address_1, address_2) => {
        try {
            let data = {
                server_id: server_id ? server_id : 0,
                address_1: address_1,
                address_2: address_2,
            };
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/run/getDistanceFromAddresses`, data);
            
            const distance = response?.data?.data?.data;
            if (distance !== undefined && distance !== null) {
                return distance;
            } else {
                console.error('Invalid distance value:', distance);
                return 0; // or another default value if appropriate
            }
        } catch (error) {
            console.error('Error fetching distance:', error);
            return 0;
        }
    };
    let fetchVisitStatsDistance = async () => {
        setVisitStatDistanceVisibility(true);
        // try {
            let data = {
                server_id: server_id ? server_id : 0,
                date: new Date().toISOString().split("T")[0],
                employee_id: CurrentUser.username,
            };
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/run/day_visits`, data);

            setVisitDetails(response.data.data);
            setLoading(false);

            if (response?.data?.data && response?.data?.data?.data && response?.data?.data?.data.length > 0) {
                // Create an array of promises for each distance calculation
                const distancePromises = response?.data?.data?.data.map(async (visit, index) => {
                    const address1 = index > 0 ?
                    response?.data?.data?.data[index-1]['coordinates'] ? response?.data?.data?.data[index-1]['coordinates'] : ''
                    :((SingleUserDetails?.coordinates ? SingleUserDetails?.coordinates : ''));
                    const address2 = visit['coordinates'] || '';
                    console.clear();
                    // console.log('Calculating distance between:', address1, 'and', address2);
                    const response2 = await fetchDistance(address1.split(","), address2);
                    // console.log('Distance response2:', response2);
                    return response2;
                });
                // try {
                    const distances = await Promise.all(distancePromises);
                    // console.log("distances", distances);n
                
                    const totalDistance = distances.reduce((acc, distance) => acc + distance, 0);
                    // console.log("totalDistance", totalDistance);
                
                    setTotalDistance(totalDistance);
                // } catch (error) {
                //     console.error('Error in Promise.all:', error);
                // }
            }
        // } catch (error) {
        //     console.error('Error:', error);
        // }
    };
    let fetchVisitStatsTime = async () => {
        setVisitStatTimeVisibility(true);
        // try {
            let data = {
                server_id: server_id ? server_id : 0,
                date: new Date().toISOString().split("T")[0],
                employee_id: CurrentUser.username,
            };
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/run/day_visits`, data);

            setVisitDetails(response.data.data);
            setLoading(false);

            if (response?.data?.data && response?.data?.data?.data && response?.data?.data?.data.length > 0) {
                // Create an array of promises for each distance calculation
                const distancePromises = response?.data?.data?.data.map(async (visit, index) => {
                    const address1 = index > 0 ?
                    response?.data?.data?.data[index-1]['coordinates'] ? response?.data?.data?.data[index-1]['coordinates'] : ''
                    :((SingleUserDetails?.coordinates ? SingleUserDetails?.coordinates : ''));
                    const address2 = visit['coordinates'] || '';
                    console.clear();
                    // console.log('Calculating distance between:', address1, 'and', address2);
                    const response2 = await fetchDistance(address1.split(","), address2);
                    // console.log('Distance response2:', response2);
                    return response2;
                });
                // try {
                    const distances = await Promise.all(distancePromises);
                    // console.log("distances", distances);
                
                    const totalDistance = distances.reduce((acc, distance) => acc + distance, 0);
                    // console.log("totalDistance", totalDistance);
                
                    setTotalDistance(totalDistance);
                // } catch (error) {
                //     console.error('Error in Promise.all:', error);
                // }
            }
        // } catch (error) {
        //     console.error('Error:', error);
        // }
    };

    let fetchVisitInformation = async () => {
        setVisitInfoVisibility(true)
        try {
            let data = {
                // user_type: user_type ? user_type : '',
                server_id: server_id ? server_id : 0,
                date: new Date().toISOString().split("T")[0],
                employee_id: CurrentUser.username,
            };
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/run/day_visits`, data);
            // console.log('Response:', response.data.data);
            setVisitDetails(response.data.data)
            setLoading(false)
        } catch (error) {
            console.error('Error:', error);
        }
    }

    let fetchEmployeeStats = async (server_id) => {
        try {
            let data = {
                user_type: user_type ? user_type : '',
                server_id: server_id ? server_id : 0,
            };
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/run/employee_stats`, data);
            // console.log(response)
            // const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/run/employee_stats`, data);
            // if(response.data.data.mdl_user > 0) {
            // 	setTempStatus(!(tempStatus?tempStatus:0))
            // }
            // console.log('Response:', response.data.data);
            setEmployeeDetails(response.data.data)
            setLoading(false)
        } catch (error) {
            console.error('Error:', error);
        }
    }

    let fetchServiceUserStats = async (server_id) => {
        try {
            let data = {
                user_type: user_type ? user_type : '',
                server_id: server_id ? server_id : 0,
            };
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/run/service_user_stats`, data);
            // const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/run/employee_stats`, data);
            // if(response.data.data.mdl_user > 0) {
            // 	setTempStatus(!(tempStatus?tempStatus:0))
            // }
            // console.log('Response:', response.data.data);
            setServiceUsersDetails(response.data.data)
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const onMarkerClick = (e, { markerId, lat, lng}, mapRef) => {
        let userType = e.target.getAttribute('userType');
        let userStatus = e.target.getAttribute('userStatus');
        // console.log('This is ->', markerId, userType, userStatus)
        setCurrentUser({
            "username": markerId,
            "user_type": userType,
            "user_status": userStatus,
        })
        if(userType == 'Employee')
            if (EmployeeDetails && EmployeeDetails.data && EmployeeDetails.data.length > 0)
                EmployeeDetails.data.map((value, index) => {
                    if (value['username'] == markerId) {
                        setSingleUserDetails(value);
                        setEmployeeMenuVisibility(true)
                        // console.log("Test Selected Employee Data : ")
                        // console.log(value)
                    }
                })
        if(userType == 'User')
            if (ServiceUsersDetails && ServiceUsersDetails.data && ServiceUsersDetails.data.length > 0)
                ServiceUsersDetails.data.map((value, index) => {
                    if (value['username'] == markerId) {
                        setSingleUserDetails(value);
                        setEmployeeMenuVisibility(false)
                        // console.log(value)
                    }
                })

        // inside the map instance you can call any google maps method
        mapRef.current.setCenter({ lat, lng })
        // ref. https://developers.google.com/maps/documentation/javascript/reference?hl=it
    }

    // console.log("TotalDistance : ", TotalDistance)


    return (
        <>
            {Loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <Popup visibility_status={AddressInfoVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <h5 className='mt-2 fg-theme'>{SingleUserDetails?.username}</h5>
                            <h5 className='mt-2'>Personal Info</h5>
                            {SingleUserDetails?.username ?
                                <>
                                    <p>Full Name: {SingleUserDetails?.fullName}</p>
                                    <p>Email ID: {SingleUserDetails?.email}</p>
                                    <p>Phone: {SingleUserDetails?.phoneCode} {SingleUserDetails?.phoneNumber}</p>
                                    <p>Gender: {SingleUserDetails?.gender}</p>
                                    <p>Religion: {SingleUserDetails?.religion}</p>
                                    {/* <p>Postal Code: {SingleUserDetails?.address}</p> */}
                                </>
                                : ''}
                            <h5 className='mt-2'>Address Info</h5>
                            {SingleUserDetails?.username ?
                                <>
                                    <p>Address Line 1: {SingleUserDetails?.address_line_one ? SingleUserDetails?.address_line_one : ''}</p>
                                    <p>Address Line 2: {SingleUserDetails?.address_line_two ? SingleUserDetails?.address_line_two : ''}</p>
                                    <p>Town: {SingleUserDetails?.town ? SingleUserDetails?.town : ''}</p>
                                    <p>County: {SingleUserDetails?.county ? SingleUserDetails?.county : ''}</p>
                                    <p>Country: {SingleUserDetails?.country ? SingleUserDetails?.country : ''}</p>
                                    <p>Postal Code: {SingleUserDetails?.post_code ? SingleUserDetails?.post_code : ''}</p>
                                </>
                                : ''}
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setAddressInfoVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={VisitInfoVisibility}>
                        <div className='container-fluid text-center mt-2 mb-3'>
                            <h5 className='fg-theme'>Visit Information</h5>
                        </div>
                        <div className='container-fluid p-5 h60vh overflow-y-scroll'>
                            {VisitDetails && VisitDetails.data && VisitDetails.data.length > 0 ? 
                            VisitDetails.data.map((val, index) => {
                                return(
                                    <div className='card mt-1'>
                                        <div className='card-body'>
                                            <h5 className='fg-theme'>{val.service_user_id}</h5>
                                            <p>Date: {MonthArr[new Date(val.date).getMonth() + 1]} {new Date(val.date).getDate()}, {new Date(val.date).getFullYear()}</p>
                                            <p>Start Time: {val.start_time}</p>
                                            <p>End Time: {val.end_time}</p>
                                            <p>From Address: { index > 0 ?
                                            VisitDetails.data[index-1]['service_user_address'] ? VisitDetails.data[index-1]['service_user_address'] : ''
                                            :((SingleUserDetails?.address_line_one ? SingleUserDetails?.address_line_one : '') + ', ' + (SingleUserDetails?.address_line_two ? SingleUserDetails?.address_line_two : '') + ', ' + (SingleUserDetails?.town ? SingleUserDetails?.town : '') + ', ' + (SingleUserDetails?.county ? SingleUserDetails?.county : '') + ', ' + (SingleUserDetails?.country ? SingleUserDetails?.country : '') + ', ' + (SingleUserDetails?.post_code ? SingleUserDetails?.post_code : ''))
                                            }</p>
                                            <p>To Address: {val.service_user_address ? val.service_user_address : ''}</p>
                                            <p>Co-ordinates: {val.coordinates ? val.coordinates : ''}</p>
                                            <p>Distance: <DistanceBetweenAddress address_1={ index > 0 ?
                                            VisitDetails.data[index-1]['coordinates'] ? VisitDetails.data[index-1]['coordinates'] : ''
                                            :((SingleUserDetails?.coordinates ? SingleUserDetails?.coordinates : ''))
                                            } address_2={val.coordinates ? val.coordinates : ''} server_id={server_id} TotalDistance={TotalDistance} setTotalDistance={setTotalDistance} /> KM</p>
                                            <p>Estimated Time: <DistanceTimeBetweenAddress address_1={ index > 0 ?
                                            VisitDetails.data[index-1]['coordinates'] ? VisitDetails.data[index-1]['coordinates'] : ''
                                            :((SingleUserDetails?.coordinates ? SingleUserDetails?.coordinates : ''))
                                            } address_2={val.coordinates ? val.coordinates : ''} server_id={server_id} PerHourTime={PerHourTime} /></p>
                                        </div>
                                        <button type='button' className='btn btn-primary btn-sm mt-2 ml-1 fw-500 f-poppins' onClick={() => setRescheduleVisibility(true)}>Reschedule</button>
                                        <button type='button' className='btn btn-primary btn-sm mt-2 mb-2 ml-1 fw-500 f-poppins' onClick={() => setCopyScheduleVisibility(true)}>Copy Schedule</button>
                                    </div>
                                )
                            })
                            : ''}
                        </div>
                        <div className='container-fluid p-5 pt-1 pb-1 mt-3'>
                            <h5 className='fg-theme'>Total: <span className='float-right'>{parseFloat(TotalDistance).toFixed(2)} KM</span></h5>
                        </div>
                        <div className='container-fluid p-5 pt-1 pb-1 mt-3'>
                            <h5 className='fg-theme'>Total Estimated Time: <span className='float-right'>{(parseFloat(TotalDistance).toFixed(2) / PerHourTime) > 0 ? (parseFloat(TotalDistance).toFixed(2) / PerHourTime) * 60 > 60 ? parseFloat(parseFloat(TotalDistance).toFixed(2) / PerHourTime).toFixed(2) + " Hr" : parseFloat(parseFloat(parseFloat(TotalDistance).toFixed(2) / PerHourTime) * 60).toFixed(2) + " Min" : 0}</span></h5>
                        </div>
                        <div className='container-fluid text-center mt-2 mb-3'>
                            <button type='button' className='btn btn-primary btn-sm mt-2 ml-1 fw-500 f-poppins' onClick={() => setAutoReplanVisibility(true)}>Auto-Replan</button>
                            <button type='button' className='btn btn-primary btn-sm mt-2 ml-1 fw-500 f-poppins' onClick={() => setAutoRebookVisibility(true)}>Auto Rebook</button>
                            <button className='btn btn-primary btn-sm mt-2 ml-1 fw-500 f-poppins' type="button" onClick={() => setVisitInfoVisibility(false)}>Close</button>
                        </div>
                    </Popup>
                    {/* <Popup visibility_status={VisitStatTimeVisibility}>
                        <div className='row mt-2 mb-3'>
                            <div className='container-fluid text-center mt-2 mb-3'>
                                <h5 className='fg-theme'>Estimated Travel Time</h5>
                            </div>
                            <div className='container-fluid p-5 h60vh overflow-y-scroll'>
                                {VisitDetails && VisitDetails.data && VisitDetails.data.length > 0 ? 
                                VisitDetails.data.map((val, index) => {
                                    return(
                                        <div className='card mt-1'>
                                            <div className='card-body'>
                                                <h5 className='fg-theme'>{val.service_user_id}</h5>
                                                <p>From Address: { index > 0 ?
                                                VisitDetails.data[index-1]['service_user_address'] ? VisitDetails.data[index-1]['service_user_address'] : ''
                                                :((SingleUserDetails?.address_line_one ? SingleUserDetails?.address_line_one : '') + ', ' + (SingleUserDetails?.address_line_two ? SingleUserDetails?.address_line_two : '') + ', ' + (SingleUserDetails?.town ? SingleUserDetails?.town : '') + ', ' + (SingleUserDetails?.county ? SingleUserDetails?.county : '') + ', ' + (SingleUserDetails?.country ? SingleUserDetails?.country : '') + ', ' + (SingleUserDetails?.post_code ? SingleUserDetails?.post_code : ''))
                                                }</p>
                                                <p>To Address: {val.service_user_address ? val.service_user_address : ''}</p>
                                                <p>Co-ordinates: {val.coordinates ? val.coordinates : ''}</p>
                                                <p>Distance: <DistanceBetweenAddress address_1={ index > 0 ?
                                                VisitDetails.data[index-1]['coordinates'] ? VisitDetails.data[index-1]['coordinates'] : ''
                                                :((SingleUserDetails?.coordinates ? SingleUserDetails?.coordinates : ''))
                                                } address_2={val.coordinates ? val.coordinates : ''} server_id={server_id} /> KM</p>
                                                <p>Estimated Time: <DistanceTimeBetweenAddress address_1={ index > 0 ?
                                                VisitDetails.data[index-1]['coordinates'] ? VisitDetails.data[index-1]['coordinates'] : ''
                                                :((SingleUserDetails?.coordinates ? SingleUserDetails?.coordinates : ''))
                                                } address_2={val.coordinates ? val.coordinates : ''} server_id={server_id} PerHourTime={PerHourTime} /></p>
                                            </div>
                                        </div>
                                    )
                                })
                                : ''}
                            </div>
                        </div>
                        <div className='container-fluid p-5 pt-1 pb-1 mt-3'>
                            <h5 className='fg-theme'>Total Estimated Time: <span className='float-right'>{(parseFloat(TotalDistance).toFixed(2) / PerHourTime) > 0 ? (parseFloat(TotalDistance).toFixed(2) / PerHourTime) * 60 > 60 ? parseFloat(parseFloat(TotalDistance).toFixed(2) / PerHourTime).toFixed(2) + " Hr" : parseFloat(parseFloat(parseFloat(TotalDistance).toFixed(2) / PerHourTime) * 60).toFixed(2) + " Min" : 0}</span></h5>
                        </div>
                        <div className='container-fluid text-center mt-2 mb-3'>
                            <button className='btn btn-primary' type="button" onClick={() => setVisitStatTimeVisibility(false)}>Close</button>
                        </div>
                    </Popup> */}
                    {/* <Popup visibility_status={VisitStatDistanceVisibility}>
                        <div className='row mt-2 mb-3'>
                            <div className='container-fluid text-center mt-2 mb-3'>
                                <h5 className='fg-theme'>Estimated Travel Distance</h5>
                            </div>
                            <div className='container-fluid p-5 h60vh overflow-y-scroll'>
                                {VisitDetails && VisitDetails.data && VisitDetails.data.length > 0 ? 
                                VisitDetails.data.map((val, index) => {
                                    return(
                                        <div className='card mt-1'>
                                            <div className='card-body'>
                                                <h5 className='fg-theme'>{val.service_user_id}</h5>
                                                <p>From Address: { index > 0 ?
                                                VisitDetails.data[index-1]['service_user_address'] ? VisitDetails.data[index-1]['service_user_address'] : ''
                                                :((SingleUserDetails?.address_line_one ? SingleUserDetails?.address_line_one : '') + ', ' + (SingleUserDetails?.address_line_two ? SingleUserDetails?.address_line_two : '') + ', ' + (SingleUserDetails?.town ? SingleUserDetails?.town : '') + ', ' + (SingleUserDetails?.county ? SingleUserDetails?.county : '') + ', ' + (SingleUserDetails?.country ? SingleUserDetails?.country : '') + ', ' + (SingleUserDetails?.post_code ? SingleUserDetails?.post_code : ''))
                                                }</p>
                                                <p>To Address: {val.service_user_address ? val.service_user_address : ''}</p>
                                                <p>Co-ordinates: {val.coordinates ? val.coordinates : ''}</p>
                                                <p>Distance: <DistanceBetweenAddress address_1={ index > 0 ?
                                                VisitDetails.data[index-1]['coordinates'] ? VisitDetails.data[index-1]['coordinates'] : ''
                                                :((SingleUserDetails?.coordinates ? SingleUserDetails?.coordinates : ''))
                                                } address_2={val.coordinates ? val.coordinates : ''} server_id={server_id} TotalDistance={TotalDistance} setTotalDistance={setTotalDistance} /> KM</p>
                                            </div>
                                        </div>
                                    )
                                })
                                : ''}
                            </div>
                        </div>
                        <div className='container-fluid p-5 pt-1 pb-1 mt-3'>
                            <h5 className='fg-theme'>Total: <span className='float-right'>{parseFloat(TotalDistance).toFixed(2)} KM</span></h5>
                        </div>
                        <div className='container-fluid text-center mt-2 mb-3'>
                            <button className='btn btn-primary' type="button" onClick={() => setVisitStatDistanceVisibility(false)}>Close</button>
                        </div>
                    </Popup> */}
                    {/* <Popup visibility_status={VisitInfoVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setVisitInfoVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup> */}
                    <Popup visibility_status={RescheduleVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setRescheduleVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    {/* <Popup visibility_status={StaffPlanVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                        </div>
                    </Popup> */}
                    <Popup visibility_status={ServiceUserPlanVisibility}>
                        <div className='container-fluid p-5 hStaffPlanVisibility'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setServiceUserPlanVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={CopyScheduleVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setCopyScheduleVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={AutoReplanVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setAutoReplanVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={AutoRebookVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setAutoRebookVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    {/*  */}
                    <Popup visibility_status={FiltersVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setFiltersVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={SearchUsersVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setSearchUsersVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    {/*  */}
                    <Popup visibility_status={SmartReallocateVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setSmartReallocateVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={TimeAllowancesVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setTimeAllowancesVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={AlertTriggersVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setAlertTriggersVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    {/*  */}
                    <Popup visibility_status={ViewAvailableStaffVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setViewAvailableStaffVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={ViewUnallocatedVisitsVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setViewUnallocatedVisitsVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={HandOffShiftVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setHandOffShiftVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={AutoPlanVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setAutoPlanVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={ReplanUserVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setReplanUserVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={ReplanEmployeeVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setReplanEmployeeVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={SicknessOrLeaveVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setSicknessOrLeaveVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={ConfigureParametersVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setConfigureParametersVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <Popup visibility_status={NonNegotiableThingsVisibility}>
                        <div className='container-fluid p-5 h60vh'>
                            <div className='container-fluid text-center'>
                                <button className='btn btn-primary' type="button" onClick={() => setNonNegotiableThingsVisibility(false)}>Close</button>
                            </div>
                        </div>
                    </Popup>
                    <div className="container-fluid p-0">
                        <div className="row p-0 m-0">
                            <div className="col-md-12 topBar">
                                <div className="overflow-hidden w-70pc bg-white pad-5-25 m-auto rad20px">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <a href={`/Architecture/Servers/${server_id}/Run/online_employee`}><button type='button' className={`btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins ${OnlineEmployeeVisibility ? 'active online_employee' : ''}`}>Online Employees ({EmployeeDetails.total_active})</button></a>
                                                </div>
                                                <div className="col-md-6">
                                                    <a href={`/Architecture/Servers/${server_id}/Run/offline_employee`}><button type='button' className={`btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins ${OfflineEmployeeVisibility ? 'active offline_employee' : ''}`}>Offline Employees ({EmployeeDetails.total_inactive})</button></a>
                                                </div>
                                                <div className="col-md-6">
                                                    <a href={`/Architecture/Servers/${server_id}/Run/online_service_user`}><button type='button' className={`btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins ${OnlineServiceUserVisibility ? 'active online_service_user' : ''}`}>Online Users ({ServiceUsersDetails.total_active})</button></a>
                                                </div>
                                                <div className="col-md-6">
                                                    <a href={`/Architecture/Servers/${server_id}/Run/offline_service_user`}><button type='button' className={`btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins ${OfflineServiceUserVisibility ? 'active offline_service_user' : ''}`}>Offline Users ({ServiceUsersDetails.total_inactive})</button></a>
                                                    {/* <h5>Travelling Staff : </h5> */}
                                                    {/* <h5>Travelling Staff : {ServiceUsersDetails.total_inactive}</h5> */}
                                                    {/* <h5>Search User: </h5> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <a href={`/Architecture/Servers/${server_id}/Run/`}><button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins'>Reset</button></a>
                                            {/* <a href={`/Architecture/Servers/${server_id}/Run/online_service_user`}><button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins' onClick={() => setFiltersVisibility(true)}>Filters</button></a> */}
                                            <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins' onClick={() => setSearchUsersVisibility(true)}>Search User</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {EmployeeMenuVisibility ?
                                <div className="col-md-12 p-2 leftBar">
                                    {/* <h5>Current Modal</h5> */}
                                    <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins' onClick={() => setAddressInfoVisibility(true)}>Address Info</button>
                                    {/* <h5 className='mt-2'>Visit Info</h5> */}
                                    {/* <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins' onClick={fetchVisitStatsTime}>Estimated Travel Time</button> */}
                                    {/* <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins' onClick={fetchVisitStatsDistance}>Estimated Mileage</button> */}
                                    <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins' onClick={fetchVisitInformation}>Visit Information</button>
                                    <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins' onClick={() => setStaffPlanVisibility(true)}>View Staff Plan</button>
                                    <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins' onClick={() => setServiceUserPlanVisibility(true)}>View Service User Plan</button>

                                    <br />
                                    <br />
                                    <div className='card mt-1'>
                                        <div className='card-body p-2'>
                                            <p className='fw-500 f-poppins'>Total Traveled</p>
                                            <h5 className='fg-theme f-courior-prime'><span>{parseFloat(TotalDistance).toFixed(2)}</span> KM</h5>
                                            <i className='fas fa-suitcase-rolling fs-40px fg-theme float-right'></i>
                                        </div>
                                    </div>
                                    <div className='card mt-1'>
                                        <div className='card-body p-2'>
                                            <p className='fw-500 f-poppins'>Estimated Travel Time</p>
                                            <h5 className='fg-theme f-courior-prime'>{TotalDistance / PerHourTime > 0 ? TotalDistance / PerHourTime * 60 > 60 ? parseFloat(TotalDistance / PerHourTime).toFixed(2) + " Hr" : parseFloat(TotalDistance / PerHourTime * 60).toFixed(2) + " Min" : 0}</h5>
                                            <i className='fas fa-stopwatch fs-40px fg-theme float-right'></i>
                                        </div>
                                    </div>
                                    <div className='card mt-1'>
                                        <div className='card-body p-2'>
                                            <p className='fw-500 f-poppins'>Estimated Cost of Shift</p>
                                            <h5 className='fg-theme f-courior-prime'>159.92</h5>
                                            {/* Estimated Travel Time * TravelTimeRate + (end datetime - start datetime) * hourlyRate */}
                                            <i className='fas fa-coins fs-40px fg-theme float-right'></i>
                                        </div>
                                    </div>
                                    <div className='card mt-1'>
                                        <div className='card-body p-2'>
                                            <p className='fw-500 f-poppins'>Estimated Profit Margin</p>
                                            <h5 className='fg-theme f-courior-prime'>15992.00</h5>
                                            <i className='fas fa-rupee-sign fs-40px fg-theme float-right'></i>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                            {CurrentUser?.user_type == 'Employee' ?
                                <div className="col-md-12 p-2 rightBar">
                                    {/* <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins' onClick={() => setServiceUserPlanVisibility(true)}>Live Visit Module</button> */}
                                    <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins' onClick={() => setSmartReallocateVisibility(true)}>Smart Re-allocate</button>
                                    <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins' onClick={() => setTimeAllowancesVisibility(true)}>Time Allowances</button>
                                    <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 fw-500 f-poppins' onClick={() => setAlertTriggersVisibility(true)}>Alert Triggers</button>
                                </div>
                                : ''}
                            <div className="col-md-12 p-0 map-markers" style={{ height: '100vh' }}>
                                <GoogleMapComponent onMarkerClick={onMarkerClick} EmployeeDetails={EmployeeDetails} ServiceUsersDetails={ServiceUsersDetails} labelText={"Service User"}
                                    OnlineEmployeeVisibility={OnlineEmployeeVisibility}
                                    OfflineEmployeeVisibility={OfflineEmployeeVisibility}
                                    OnlineServiceUserVisibility={OnlineServiceUserVisibility}
                                    OfflineServiceUserVisibility={OfflineServiceUserVisibility}
                                />
                            </div>
                            {StaffPlanVisibility && SingleUserDetails?.username ?
                                <div className="col-md-12 schedular-table mt-5">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="overflow-hidden w-70pc bg-white pad-5-25 m-auto">
                                                <div className="row">
                                                    <div className='col-md-9 x-scroll   '>
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <td>Day</td>
                                                                    {Slots && Slots.length > 0 ? Slots.map((val, index) => <th colSpan={30} key={'slotname-' + index}>{val}</th>) : ''}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Days && Days.length > 0 && Slots && Slots.length > 0
                                                                    ? Days.map((day, day_index) => (
                                                                        <tr key={'dayname-' + day_index}>
                                                                            <td>{day}</td>
                                                                            {Slots.map((val, index) => (
                                                                                <>
                                                                                    {
                                                                                        [...Array(30)].map((_, index_) => (
                                                                                            // <td
                                                                                            //     key={index_}
                                                                                            //     onDragOver={(e) => allowDrop(e)}
                                                                                            //     onDrop={(e) => handleDrop(e, day, (val.split(":")[0] + ':' + ((parseInt(val.split(":")[1]) + index_ + 1) < 10 ? '0' + (parseInt(val.split(":")[1]) + index_ + 1) : (parseInt(val.split(":")[1]) + index_ + 1))))}
                                                                                            //     onDragEnter={() => handleDragEnter((val.split(":")[0] + ':' + ((parseInt(val.split(":")[1]) + index_ + 1) < 10 ? '0' + (parseInt(val.split(":")[1]) + index_ + 1) : (parseInt(val.split(":")[1]) + index_ + 1))))}
                                                                                            // >
                                                                                            //     {draggedTime && draggedTime === (val.split(":")[0] + ':' + ((parseInt(val.split(":")[1]) + index_ + 1) < 10 ? '0' + (parseInt(val.split(":")[1]) + index_ + 1) : (parseInt(val.split(":")[1]) + index_ + 1))) && <div className="tooltip">{draggedTime}</div>}
                                                                                            // </td>
                                                                                            <td
                                                                                                key={'slot-' + index}
                                                                                                onDragOver={(e) => allowDrop(e)}
                                                                                                onDrop={(e) => handleDrop(e, day, (val.split(":")[0] + ':' + ((parseInt(val.split(":")[1]) + index_) < 10 ? '0' + (parseInt(val.split(":")[1]) + index_) : (parseInt(val.split(":")[1]) + index_))))}
                                                                                                onDragEnter={() => handleDragEnter((val.split(":")[0] + ':' + ((parseInt(val.split(":")[1]) + index_) < 10 ? '0' + (parseInt(val.split(":")[1]) + index_) : (parseInt(val.split(":")[1]) + index_))))}
                                                                                            >
                                                                                                {Events && Events.length > 0
                                                                                                    ? Events.map((event, event_index) => {
                                                                                                        // if (event['DAY'] === day && index > 0) {
                                                                                                        if (event['DAY'] === day && event['TIMESTART'] === (val.split(":")[0] + ':' + ((parseInt(val.split(":")[1]) + index_) < 10 ? '0' + (parseInt(val.split(":")[1]) + index_) : (parseInt(val.split(":")[1]) + index_)))) {
                                                                                                            return (
                                                                                                                <>

                                                                                                                    <span
                                                                                                                        key={'event-' + event_index}
                                                                                                                        className={event['TIMESTART'] === (val.split(":")[0] + ':' + ((parseInt(val.split(":")[1]) + index_) < 10 ? '0' + (parseInt(val.split(":")[1]) + index_) : (parseInt(val.split(":")[1]) + index_))) ? 'scheduled' : ''}
                                                                                                                        draggable
                                                                                                                        onDragStart={(e) => handleDragStart(e, day, (val.split(":")[0] + ':' + ((parseInt(val.split(":")[1]) + index_) < 10 ? '0' + (parseInt(val.split(":")[1]) + index_) : (parseInt(val.split(":")[1]) + index_))))}
                                                                                                                    >
                                                                                                                        <span>
                                                                                                                            <div>{draggedTime ? draggedTime : event['TIMESTART']}</div>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </>
                                                                                                            );
                                                                                                        } else {
                                                                                                            // return (<><>);
                                                                                                        }
                                                                                                        // } else {
                                                                                                        //     return (<></>);
                                                                                                        // }
                                                                                                    })
                                                                                                    : ''}
                                                                                            </td>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                            ))}
                                                                        </tr>
                                                                    ))
                                                                    : ''}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 f-poppins' onClick={() => setViewAvailableStaffVisibility(true)}>View Available Staff</button>
                                                        <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 f-poppins' onClick={() => setViewUnallocatedVisitsVisibility(true)}>View Unallocated Visits</button>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 f-poppins' onClick={() => setHandOffShiftVisibility(true)}>Auto-Plan</button>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 f-poppins' onClick={() => setAutoPlanVisibility(true)}>Hand-off Shift</button>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 f-poppins' onClick={() => setReplanUserVisibility(true)}>Replan User</button>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 f-poppins' onClick={() => setReplanEmployeeVisibility(true)}>Replan Employee</button>
                                                            </div>
                                                        </div>
                                                        <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 f-poppins' onClick={() => setSicknessOrLeaveVisibility(true)}>Sickness or Leave</button>
                                                        <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 f-poppins' onClick={() => setConfigureParametersVisibility(true)}>Configure Parameters</button>
                                                        <button type='button' className='btn btn-primary btn-sm btn-block w-100pc mt-2 f-poppins' onClick={() => setNonNegotiableThingsVisibility(true)}>Non-negotiable Timings</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                        </div>
                    </div>
                </>
            }
        </>
    );
}





function DistanceBetweenAddress({address_1, address_2, server_id}) {
    console.log("address1", address_1)
    const [ Distance, setDistance ] = useState(0);
    useEffect(() => {
        fetchDistance(address_1, address_2)
    }, [address_1, address_2])

    let fetchDistance = async (address_1, address_2) => {
        let data = {
            // user_type: user_type ? user_type : '',
            server_id: server_id ? server_id : 0,
            address_1: address_1.split(','),
            address_2: address_2,
        };
        const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/run/getDistanceFromAddresses`, data);
        // console.log('Distance:', response.data.data);
        setDistance(response.data.data)
        // setVisitDetails(response.data.data)
    }
    return (
        <span>{parseFloat(Distance?.data ? Distance?.data : 0).toFixed(2)}</span>
    )
}





function DistanceTimeBetweenAddress({address_1, address_2, server_id, PerHourTime}) {
    console.log("--address1", address_1)
    const [ Distance, setDistance ] = useState(0);
    useEffect(() => {
        fetchDistance(address_1, address_2)
    }, [address_1, address_2])

    let fetchDistance = async (address_1, address_2) => {
        let data = {
            // user_type: user_type ? user_type : '',
            server_id: server_id ? server_id : 0,
            address_1: address_1.split(','),
            address_2: address_2,
        };
        const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/run/getDistanceFromAddresses`, data);
        // console.log('Distance:', response.data.data);
        setDistance(parseFloat(response?.data?.data?.data) / PerHourTime)
        // setVisitDetails(response.data.data)
    }
    return (
        // <span>{Distance}</span>
        <span>{Distance > 0 ? Distance * 60 > 60 ? parseFloat(Distance).toFixed(2) + " Hr" : parseFloat(Distance * 60).toFixed(2) + " Min" : 0}</span>
    )
}





function TotalVisitDistance({TotalDistance}) {
    return (
        <span>{parseFloat(TotalDistance ? TotalDistance : 0).toFixed(2)}</span>
    )
}

