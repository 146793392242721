import React, { useState, useEffect } from 'react'

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import {
    retrieveSingle
} from "./actions/arcServerServiceUsers";



export default function ArchitectureServerServiceUserCourses() {
    let tempFlag = 0;
    const { server_id, service_user_id } = useParams();
    let [loading, setLoading] = useState(true);
    let [loadingCertificateList, setLoadingCertificateList] = useState(false);
    let [EmailId, setEmailId] = useState(0);
    let [CompanyList, setCompanyList] = useState([]);
    let [CertificateList, setCertificateList] = useState([]);
    let [ElearningCertificateList, setElearningCertificateList] = useState([]);
    let [ElearningCompanyList, setElearningCompanyList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {

        setLoading(true);
        let data = {
            server_id: server_id ? server_id : '',
            username: service_user_id ? service_user_id : '',
            limit: 1,
            last_id: '',
            first_id: '',
        };
        dispatch(retrieveSingle(data)).then((response) => {
            // console.log(response)
            setEmailId(response.email);
            fetchCompany(server_id, service_user_id, response.email);
            fetchElearningCompany(server_id, service_user_id, response.email);
            // setFormData(response);
            // setLoading(false);
        });
        // fetchCourseList(server_id, service_user_id);
    }, [server_id, service_user_id]);

    let fetchCompany = async (server_id, service_user_id, email) => {
        let data = {
            user_email: email
        };
        await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/fetch_training_companies`, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }, data
        }).then(response => {
            // console.log("CompanyList", response.data.data);
            setCompanyList(response.data.data);
            setLoading(false);
        })
    }

    let fetchElearningCompany = async (server_id, service_user_id, email) => {
        let data = {
            user_id: email
        };
        // {
        //     headers: {
        //         'Content-Type': 'application/json;charset=UTF-8',
        //         "Access-Control-Allow-Origin": "*",
        //     }, data
        // }
        await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_unique_certificate_companies`, data).then(response => {
            // console.log("CompanyList", response.data.data);
            setElearningCompanyList(response.data.data.mdl_course);
            setLoading(false);
        });
    }

    let handleCompany = (company_id, user_id) => {
        fetchCourseList(server_id, service_user_id, company_id, user_id);
        fetchElearningCourseList(server_id, service_user_id, company_id, user_id);
    }


    let fetchCourseList = async (server_id, service_user_id, company_id, user_id) => {
        let data = {
            user_email: EmailId, 
            company_id: company_id, 
            // server_id: server_id, 
            // service_user_id: service_user_id,
        };
        await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/fetch_training_certificates`, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }, data
        }).then(response => {
            setLoadingCertificateList(true);
            setCertificateList(response.data.data);
            // console.log("CertificateList", response.data.data);
            setLoading(false);
        })
    }


    let fetchElearningCourseList = async (server_id, service_user_id, company_id, user_id) => {
        let data = {
            user_id: user_id, 
            company_id: company_id, 
            // server_id: server_id, 
            // service_user_id: service_user_id,
        };
        // {
        //     headers: {
        //         'Content-Type': 'application/json;charset=UTF-8',
        //         "Access-Control-Allow-Origin": "*",
        //     }, data
        // }
        await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_all_certificate_details`, data).then(response => {
            console.log("CertificateList", response);
            setLoadingCertificateList(true);
            setElearningCertificateList(response.data.data.mdl_course);
            // console.log("CertificateList", response.data.data);
            setLoading(false);
        })
    }



    let handleSync = async (company_id, user_id, elearning_data) => {
        let data = {
            user_email: service_user_id,
            elearning_data: elearning_data
            // company_id: company_id,
            // server_id: server_id, 
            // service_user_id: service_user_id,
        };
        await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/sync_training_certificate`, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }, data
        }).then(response => {
            // console.log(response.data.data.res);
            if(response.data.data.res)
                handleCompany(company_id, user_id)
        })

    }

    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <div className="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 className="text-dark mb-0">Server Service User Courses</h3>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="card shadow">
                                {/* <div className="card-header py-3">
                                    <p className="text-primary m-0 fw-bold">Server List</p>
                                </div> */}
                                <div className="card-body">
                                    <div className="row">
                                        {/* <div className="col-md-6 text-nowrap">
                                            <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                                <label className="form-label">Show&nbsp;
                                                    <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-6">
                                            <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                                        </div> */}
                                    </div>
                                    <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                        <table className="table my-0" id="dataTable">
                                            <thead>
                                                <tr>
                                                    {/* <th>#</th> */}
                                                    <th>Company Name</th>
                                                    <th>Company Shortname</th>
                                                    <th style={{ width: "110px" }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {CompanyList && CompanyList.length > 0 ? ElearningCompanyList && ElearningCompanyList.length > 0 ?
                                                    ElearningCompanyList.map((val, index) => {
                                                        tempFlag = 0;
                                                        return (
                                                            <>
                                                                <>
                                                                    {CompanyList.map((val1, index1) => {
                                                                        if (
                                                                            val1.certificate_code == val.certificate_code
                                                                            && val1.certificate_id == val.certificate_id
                                                                        ) {
                                                                            tempFlag = 1;
                                                                            return (
                                                                                <tr>
                                                                                    {/* <th>{(index + 1)}</th> */}
                                                                                    <th>{val.company_name}</th>
                                                                                    <th>{val.company_shortname}</th>
                                                                                    <th style={{ width: "110px" }}>
                                                                                        <button type='button' className='btn btn-primary btn-sm' onClick={() => handleCompany(val.company_id, val.user_id)}><i className='fa fa-angle-right'></i></button>
                                                                                    </th>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    })
                                                                    }
                                                                </>
                                                                <>
                                                                    {
                                                                        (tempFlag == 0) ?
                                                                        <tr>
                                                                            {/* <th>{(index + 1)}</th> */}
                                                                            <th>{val.company_name}</th>
                                                                            <th>{val.company_shortname}</th>
                                                                            <th style={{ width: "110px" }}>
                                                                                <button type='button' className='btn btn-primary btn-sm' onClick={() => handleCompany(val.company_id, val.user_id)}><i className='fa fa-angle-right'></i></button>
                                                                            </th>
                                                                        </tr>
                                                                        : ''
                                                                    }
                                                                </>
                                                            </>
                                                        )
                                                    })
                                                    : <tr><td colspan={3}>No Data Found.</td></tr> :
                                                    ElearningCompanyList && ElearningCompanyList.length > 0 ?
                                                        ElearningCompanyList.map((val, index) => {
                                                            return (
                                                                <tr>
                                                                    {/* <th>{(index + 1)}</th> */}
                                                                    <th>{val.company_name}</th>
                                                                    <th>{val.company_shortname}</th>
                                                                    <th style={{ width: "110px" }}>
                                                                        <button type='button' className='btn btn-primary btn-sm' onClick={() => handleCompany(val.company_id, val.user_id)}><i className='fa fa-angle-right'></i></button>
                                                                    </th>
                                                                </tr>
                                                            )
                                                }) : <tr><td colspan={3}>No Data Found.</td></tr>}









                                                {/* {CompanyList && CompanyList.length > 0 ?
                                                    CompanyList.map((val, index) => {
                                                        return (
                                                            <tr>
                                                                <th>{(index + 1)}</th>
                                                                <th>{val.company_name}</th>
                                                                <th>{val.company_shortname}</th>
                                                                <th style={{ width: "110px" }}>
                                                                    <button type='button' className='btn btn-primary btn-sm' onClick={() => handleCompany(val.company_id, val.user_id)}><i className='fa fa-angle-right'></i></button>
                                                                </th>
                                                            </tr>
                                                        )
                                                    })
                                                : <tr><td colspan={4}>No Data Found.</td></tr>
                                                } */}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {loadingCertificateList ?
                            <div className="col-md-6">
                                <div className="card shadow">
                                    {/* <div className="card-header py-3">
                                    <p className="text-primary m-0 fw-bold">Server List</p>
                                </div> */}
                                    <div className="card-body">
                                        <div className="row">
                                            {/* <div className="col-md-6 text-nowrap">
                                            <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                                <label className="form-label">Show&nbsp;
                                                    <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div> */}
                                            {/* <div className="col-md-6">
                                            <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                                        </div> */}
                                        </div>
                                        <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                            <table className="table my-0" id="dataTable">
                                                <thead>
                                                    <tr>
                                                        {/* <th>#</th> */}
                                                        <th>Certificate Code</th>
                                                        <th>Course Name</th>
                                                        <th style={{ width: "110px" }}>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* {CertificateList && CertificateList.length > 0 ?
                                                        CertificateList.map((val, index) => {
                                                            return (
                                                                <tr>
                                                                    <th>{val.certificate_code}</th>
                                                                    <th>{val.course_name}</th>
                                                                    <th style={{ width: "110px" }}>
                                                                        <i className='fa fa-eye'></i>
                                                                    </th>
                                                                </tr>
                                                            )
                                                        })
                                                        : ''} */}
                                                    {CertificateList && CertificateList.length > 0 ? ElearningCertificateList && ElearningCertificateList.length > 0 ?

                                                        ElearningCertificateList.map((val, index) => {
                                                            tempFlag = 0;
                                                            return (
                                                                <>
                                                                    <>
                                                                        {CertificateList.map((val1, index1) => {
                                                                            if (
                                                                                val1.certificate_code == val.certificate_code
                                                                                && val1.certificate_id == val.certificate_id
                                                                            ) {
                                                                                tempFlag = 1;
                                                                                return (
                                                                                    <tr>
                                                                                        {/* <th>{(index + 1)}</th> */}
                                                                                        <th>{val.certificate_code}</th>
                                                                                        <th>{val.course_name}</th>
                                                                                        <th style={{ width: "110px" }}>
                                                                                            {/* <i className='fa fa-eye'></i> */}
                                                                                            {/* <button type='button' className='btn btn-primary btn btn-sm' onClick={() => handleCompany(val.company_id, val.user_id)}><i className='fa fa-eye'></i></button> */}
                                                                                        </th>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        })
                                                                        }
                                                                    </>
                                                                    <>
                                                                        {
                                                                            (tempFlag == 0) ?
                                                                                <tr>
                                                                                    <td>{val.certificate_code}</td>
                                                                                    <td>{val.course_name}</td>
                                                                                    <td style={{ width: "110px" }}>
                                                                                        <button type='button' className='btn btn-primary btn-sm' onClick={() => handleSync(val.company_id, val.user_id, val)}><i className='fa fa-sync'></i></button>
                                                                                    </td>
                                                                                </tr>
                                                                            : ''
                                                                        }
                                                                    </>
                                                                </>
                                                            )
                                                        })
                                                        : <tr><td colspan={3}>No Data Found.</td></tr> :
                                                        ElearningCertificateList && ElearningCertificateList.length > 0 ?
                                                            ElearningCertificateList.map((val, index) => {
                                                                return (
                                                                    <tr>
                                                                        {/* <th>{(index + 1)}</th> */}
                                                                        <th>{val.certificate_code}</th>
                                                                        <th>{val.course_name}</th>
                                                                        <th style={{ width: "110px" }}>
                                                                            <button type='button' className='btn btn-primary btn-sm' onClick={() => handleSync(val.company_id, val.user_id, val)}><i className='fa fa-sync'></i></button>
                                                                        </th>
                                                                    </tr>
                                                                )
                                                            }) 
                                                        : <tr><td colspan={3}>No Data Found.</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            : ''}
                    </div>
                </>
            }
        </>
    )
}
