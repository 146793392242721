import { LatLngLiteral } from 'google-maps-react-markers'
import React from 'react'

import { Icon } from '@iconify/react';

const Marker = ({
	size,
	color,
	className,
	lat,
	lng,
	markerId,
	onClick,
	draggable,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onDrag,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onDragEnd,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onDragStart,
	...props
}) =>
	lat && lng ? (
		<>
			<div class="tooltip-custom-test">{markerId}</div>
			<Icon icon="tdesign:location" className={`${className} pin-icon`} 
				// lat={lat}
				// lng={lng}
				onClick={(e) => (onClick ? onClick(e, { markerId, lat, lng }) : null)}
				style={{ fontSize: size, color: color }}
				alt={markerId}
				{...props}
			/>
		</>
	) : null

export default Marker