import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import { retrieve, retrieveSingle, retrieveChangedProviders } from './actions/Providers'

import Popup from '../../../components/popup';
import ArchitectureServersAddModal from '../../Architecture/architectureServers/architectureServers_add';


const ProviderDetails = ({ providerId }) => {
  const [singleProviderData, setsingleProviderData] = useState(null);
  const dispatch = useDispatch();
  const [createModalVisibility, setCreateModalVisibility] = useState(false);
  const [TempFormData, setTempFormData] = useState(false);


  let handleCreateModalVisibility = (record) => {
    setTempFormData(record);
    setCreateModalVisibility(!createModalVisibility);
  }

  let updateParentState = () => {
    setCreateModalVisibility(!createModalVisibility);
  }

  let fetchSingleProvider = () => {
    let data = {
      providerId: String(providerId),
      partnerCode: 'OpenAnswers',
    };
    dispatch(retrieveSingle(data)).then(response => {
      console.log(response)
      setsingleProviderData(response.response)
    });
  }

  useEffect(() => {
    if (providerId)
      fetchSingleProvider();
  }, [providerId]);

  return (
    <>{singleProviderData && singleProviderData.message ? singleProviderData.message : !singleProviderData ? '' :
      <>
        <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><ArchitectureServersAddModal tempData={TempFormData} updateParentState={updateParentState} /></Popup>

        <ul className='providerDetails'>
          <li>
            {!singleProviderData.name ? '' : singleProviderData.name}
            <button className='btn btn-primary btn-sm mr-2 float-right' type='button' onClick={() => handleCreateModalVisibility(singleProviderData)}><i className='fa fa-link'></i></button>
          </li>
          <li>Phone Number: {!singleProviderData.mainPhoneNumber ? '' : singleProviderData.mainPhoneNumber}</li>
          <li>Last Inspection: {!singleProviderData.lastInspection ? '' : !singleProviderData.lastInspection.date ? 'Never' : singleProviderData.lastInspection.date}</li>
          <li>Companies House Number: {!singleProviderData.companiesHouseNumber ? '' : singleProviderData.companiesHouseNumber}</li>
          <li>Constituency: {!singleProviderData.constituency ? '' : singleProviderData.constituency}</li>
          {/* <li>contacts: {!singleProviderData.contacts ? '' : singleProviderData.contacts}</li> */}
          {/* <li>inspectionAreas: {!singleProviderData.inspectionAreas ? '' : singleProviderData.inspectionAreas}</li> */}
          {/* <li>inspectionCategories: {!singleProviderData.inspectionCategories ? '' : singleProviderData.inspectionCategories}</li> */}
          <li>Inspection Directorate: {!singleProviderData.inspectionDirectorate ? '' : singleProviderData.inspectionDirectorate}</li>
          <li>Local Authority: {!singleProviderData.localAuthority ? '' : singleProviderData.localAuthority}</li>
          {/* <li>locationIds: {!singleProviderData.locationIds ? '' : singleProviderData.locationIds}</li> */}
          <li>onspd Icb Code: {!singleProviderData.onspdIcbCode ? '' : singleProviderData.onspdIcbCode}</li>
          <li>onspd Icb Name: {!singleProviderData.onspdIcbName ? '' : singleProviderData.onspdIcbName}</li>
          <li>onspd Latitude: {!singleProviderData.onspdLatitude ? '' : singleProviderData.onspdLatitude}</li>
          <li>onspd Longitude: {!singleProviderData.onspdLongitude ? '' : singleProviderData.onspdLongitude}</li>
          <li>Organisation Type: {!singleProviderData.organisationType ? '' : singleProviderData.organisationType}</li>
          <li>Ownership Type: {!singleProviderData.ownershipType ? '' : singleProviderData.ownershipType}</li>
          <li>Postal Address Line 1: {!singleProviderData.postalAddressLine1 ? '' : singleProviderData.postalAddressLine1}</li>
          <li>Postal Address Town City: {!singleProviderData.postalAddressTownCity ? '' : singleProviderData.postalAddressTownCity}</li>
          <li>Postal Code: {!singleProviderData.postalCode ? '' : singleProviderData.postalCode}</li>
          <li>Provider Id: {!singleProviderData.providerId ? '' : singleProviderData.providerId}</li>
          <li>Region: {!singleProviderData.region ? '' : singleProviderData.region}</li>
          <li>Registration Date: {!singleProviderData.registrationDate ? '' : singleProviderData.registrationDate}</li>
          <li>Registration Status: {!singleProviderData.registrationStatus ? '' : singleProviderData.registrationStatus}</li>
          {/* <li>regulatedActivities: {!singleProviderData.regulatedActivities ? '' : singleProviderData.regulatedActivities}</li> */}
          {/* <li>relationships: {!singleProviderData.relationships ? '' : singleProviderData.relationships}</li> */}
          <li>Type: {!singleProviderData.type ? '' : singleProviderData.type}</li>
          <li>UPRN: {!singleProviderData.uprn ? '' : singleProviderData.uprn}</li>
        </ul>
      </>
    }
    </>
  );
}



export default function Providers() {
  const [providerData, setProviderData] = useState(null);
  const [changedProviderData, setChangedProviderData] = useState(null);
  const [Providers, setProviders] = useState([]);
  const [currentPageProvider, setCurrentPageProvider] = useState(1);
  const [perPageProvider, setPerPageProvider] = useState(10);
  const [currentPageChangedProvider, setCurrentPageChangedProvider] = useState(1);
  const [perPageChangedProvider, setPerPageChangedProvider] = useState(10);
  const [providerIdProvider, setProviderIdProvider] = useState('');
  const [providerIdChangedProvider, setProviderIdChangedProvider] = useState('');
  const dispatch = useDispatch();

  let fetchProviders = (page, perPage) => {
    setCurrentPageProvider(page)
    setPerPageProvider(perPage)
    let data = {
      page: String(page),
      perPage: String(perPage),
      partnerCode: 'OpenAnswers',
    };
    dispatch(retrieve(data)).then(response => {
      setProviderData(response.response)
      // console.log(response)
    });
  }

  let fetchChangedProviders = (page, perPage) => {
    setCurrentPageChangedProvider(page);
    setPerPageChangedProvider(perPage);
    let data = {
      page: String(currentPageChangedProvider),
      perPage: String(perPage),
      startTimestamp: "2019-03-10T06:30:00Z",
      endTimestamp: String(new Date().toISOString().split('.')[0]) + 'Z',
      partnerCode: 'OpenAnswers',
    };
    dispatch(retrieveChangedProviders(data)).then(response => {
      setChangedProviderData(response.response)
    });
  }


  useEffect(() => {
    fetchProviders(currentPageProvider, perPageProvider);
    fetchChangedProviders(currentPageChangedProvider, perPageChangedProvider);
  }, []);

  const handleProviderPreviousClick = () => {
    if (currentPageProvider > 1) {
      fetchProviders(currentPageProvider - 1, perPageProvider);
    }
  };

  const handleProviderNextClick = () => {
    if (currentPageProvider < providerData.totalPages) {
      fetchProviders(currentPageProvider + 1, perPageProvider);
    }
  };

  const handleChangedProviderPreviousClick = () => {
    if (currentPageChangedProvider > 1) {
      setCurrentPageChangedProvider(currentPageChangedProvider - 1);
      fetchChangedProviders(currentPageChangedProvider - 1, perPageChangedProvider);
    }
  };

  const handleChangedProviderNextClick = () => {
    if (currentPageChangedProvider < providerData.totalPages) {
      setCurrentPageChangedProvider(currentPageChangedProvider + 1);
      fetchChangedProviders(currentPageChangedProvider + 1, perPageChangedProvider);
    }
  };
  return (
    <>
      <div class="container-fluid mt-5">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item mr-2" role="presentation">
            <button class="nav-link active" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="true">Providers</button>
          </li>
          <li class="nav-item mr-2" role="presentation">
            <button class="nav-link" id="pills-inactive-tab" data-bs-toggle="pill" data-bs-target="#pills-inactive" type="button" role="tab" aria-controls="pills-inactive" aria-selected="false">Changed Providers</button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab">
            <div className='row'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                      <h3 class="mb-0 fg-theme">Providers</h3>
                    </div>
                    <div class="card shadow">
                      <div class="card-body">
                        <div className='container-fluid'>
                          <div class="row">
                            <div class="col-md-6 text-nowrap">
                              <div id="dataTable_length" class="dataTables_length" aria-controls="dataTable">
                                <label class="form-label">Show&nbsp;
                                  <select class="d-inline-block form-select form-select-sm" name="record_length" value={perPageProvider} onChange={(e) => { console.log("Provider", e.target.value); fetchProviders(perPageProvider, e.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </label>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="text-md-end dataTables_filter" id="dataTable_filter">
                                <label class="form-label">
                                  <input type="search" class="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='container-fluid'>
                          {providerData && (
                            <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                              <table class="table my-0" id="dataTable">
                                <thead>
                                  <tr>
                                    <th>Provider ID</th>
                                    <th>Provider Name</th>
                                    <th style={{ width: '20%' }}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {providerData && providerData.providers && providerData.providers.length > 0 ? providerData.providers.map((provider) => {
                                    return (
                                      <tr key={provider.providerId}>
                                        <td>{provider.providerId}</td>
                                        <td>{provider.providerName}</td>
                                        <td>
                                          <button className='btn btn-primary btn-sm mr-2' type='button' onClick={() => setProviderIdProvider(provider.providerId)}><i className='fa fa-angle-right'></i></button>
                                        </td>
                                      </tr>
                                    )
                                  }) : ''}
                                </tbody>
                              </table>
                              <div class="">
                                {/* <div class="col-md-6 align-self-center">
                        <p id="dataTable_info" class="dataTables_info" role="status" aria-live="polite">Total Records 5</p>
                      </div> */}
                                <div class="offset-md-6 col-md-6">
                                  <nav class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination">
                                      <li class="page-item" onClick={handleProviderPreviousClick} disabled={currentPageProvider === 1}>
                                        <a class="page-link" aria-label="Previous" href="#">
                                          <span aria-hidden="true">«</span> Previous</a>
                                      </li>
                                      <li class="page-item" onClick={handleProviderNextClick} disabled={currentPageProvider === providerData.totalPages}>
                                        <a class="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                      <h3 class="mb-0 fg-theme">&nbsp;</h3>
                    </div>
                    <div class="card shadow">
                      <div class="card-body">
                        <div className='container-fluid'>
                          <div class="row">
                            <ProviderDetails providerId={providerIdProvider} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-inactive" role="tabpanel" aria-labelledby="pills-inactive-tab">
            <div className='row'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                      <h3 class="mb-0 fg-theme">Changed Providers</h3>
                      <div class="text-right">
                      </div>
                    </div>
                    <div class="card shadow">
                      <div class="card-body">
                        <div className='container-fluid'>
                          <div class="row">
                            <div class="col-md-6 text-nowrap">
                              <div id="dataTable_length" class="dataTables_length" aria-controls="dataTable">
                                <label class="form-label">Show&nbsp;
                                  <select class="d-inline-block form-select form-select-sm" name="record_length" value={perPageChangedProvider} onChange={(e) => { console.log("ChangedProvider", e.target.value); fetchChangedProviders(perPageChangedProvider, e.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </label>
                              </div>
                            </div>
                            {/* <div class="col-md-6">
                          <div class="text-md-end dataTables_filter" id="dataTable_filter">
                            <label class="form-label">
                              <input type="search" class="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" />
                            </label>
                          </div>
                        </div> */}
                          </div>
                        </div>
                        <div className='container-fluid'>
                          {changedProviderData && (
                            <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                              <table class="table my-0" id="dataTable">
                                <thead>
                                  <tr>
                                    <th>Provider ID</th>
                                    <th style={{ width: '20%' }}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {changedProviderData && changedProviderData.changes && changedProviderData.changes.length > 0 ? changedProviderData.changes.map((provider, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{provider}</td>
                                        <td>
                                          <button className='btn btn-primary btn-sm mr-2' type='button' onClick={() => setProviderIdChangedProvider(provider)}><i className='fa fa-angle-right'></i></button>
                                        </td>
                                      </tr>
                                    )
                                  }) : ''}
                                </tbody>
                              </table>
                              <div class="">
                                {/* <div class="col-md-6 align-self-center">
                              <p id="dataTable_info" class="dataTables_info" role="status" aria-live="polite">Total Records 5</p>
                            </div> */}
                                <div class="offset-md-6 col-md-6">
                                  <nav class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination">
                                      <li class="page-item" onClick={handleChangedProviderPreviousClick} disabled={currentPageProvider === 1}>
                                        <a class="page-link" aria-label="Previous" href="#">
                                          <span aria-hidden="true">«</span> Previous</a>
                                      </li>
                                      <li class="page-item" onClick={handleChangedProviderNextClick} disabled={currentPageProvider === changedProviderData.totalPages}>
                                        <a class="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                      <h3 class="mb-0 fg-theme">&nbsp;</h3>
                    </div>
                    <div class="card shadow">
                      <div class="card-body">
                        <div className='container-fluid'>
                          <div class="row">
                            <ProviderDetails providerId={providerIdChangedProvider} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
