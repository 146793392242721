import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import { Loader, Placeholder } from 'rsuite';

import ServerVisitCountGraph from './components/graphs/VisitCounts/VisitCounts'
import ServerVisitManagementGraph from './components/graphs/VisitManagement/VisitManagement'
import AdminStatusGraph from './components/graphs/AdminStatusGraph/AdminStatusGraph'
import StaffStatusGraph from './components/graphs/StaffStatusGraph/StaffStatusGraph'
import ServiceUserStatusGraph from './components/graphs/ServiceUserStatusGraph/ServiceUserStatusGraph'



import {
    retrieve
} from './actions/dashboard'

export default function Architecture() {
    const dashboardStats = useSelector(state => state.ARCDashboardReducer, shallowEqual)
    const initial_single_data = {};
    const [single_data, setSingleData] = useState(initial_single_data);
    const [record_length, setRecordLength] = useState(10);
    const [page, setPage] = useState(0);

    const [showTrashAlert, setShowTrashAlert] = useState({ show: false });
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        let data = {
        };
        dispatch(retrieve(data)).then((response) => {
            setLoading(false);
        });
    }, []);




    let printDocument = () => {
        const input = document.getElementById('printDashboard');
        html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                format: 'A1',
                orientation: 'landscape'
            });
            pdf.addImage(imgData, 'JPEG', 0, 0);
            // pdf.output('dataurlnewwindow');
            pdf.save("download.pdf");
        })
        ;

        // var printContents = input.innerHTML;
        // var originalContents = document.body.innerHTML;

        // document.body.innerHTML = printContents;

        // window.print();

        // document.body.innerHTML = originalContents;
    }


    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <div  id='printDashboard'>
                    <div className="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 className="text-dark mb-0">Dashboard</h3>
                        {/* <div className="row mt-2 text-right"><i onClick={printDocument} className='fa fa-download'></i></div> */}

                        <button className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="#"  onClick={printDocument} ><i className="fas fa-download fa-sm"></i>&nbsp;Print</button>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="card shadow border-start-primary py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-primary fw-bold text-xs mb-1"><span>Total Companys</span></div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.total_companies) ? JSON.stringify(dashboardStats.data.total_companies) : ''}</span></div>
                                            {/* <i className="fas fa-calendar fa-2x text-gray-300"></i> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="card shadow border-start-success py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-success fw-bold text-xs mb-1"><span>Unread Emergency Alerts</span></div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.emergency_alerts) ? JSON.stringify(dashboardStats.data.emergency_alerts) : ''}</span></div>
                                        </div>
                                        {/* <div className="col-auto"><i className="fas fa-dollar-sign fa-2x text-gray-300"></i></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="card shadow border-start-info py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-info fw-bold text-xs mb-1"><span>Total Attorneys</span></div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="text-dark fw-bold h5 mb-0 me-3"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.total_poas) ? JSON.stringify(dashboardStats.data.total_poas) : ''}</span></div>
                                        </div>
                                        {/* <div className="col-auto"><i className="fas fa-clipboard-list fa-2x text-gray-300"></i></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="card shadow border-start-warning py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-warning fw-bold text-xs mb-1"><span>Contact Us</span></div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.contact_us) ? JSON.stringify(dashboardStats.data.contact_us) : ''}</span></div>
                                        </div>
                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="card shadow border-start-warning py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-purple fw-bold text-xs mb-1"><span>Employees</span></div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.total_employees) ? JSON.stringify(dashboardStats.data.total_employees) : ''}</span></div>
                                        </div>
                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="card shadow border-start-warning py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-danger fw-bold text-xs mb-1"><span>Total Leads</span></div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.total_leads) ? JSON.stringify(dashboardStats.data.total_leads) : ''}</span></div>
                                        </div>
                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="card shadow border-start-warning py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-orange fw-bold text-xs mb-1"><span>Total Service Users</span></div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.total_service_users) ? JSON.stringify(dashboardStats.data.total_service_users) : ''}</span></div>
                                        </div>
                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="card shadow border-start-warning py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-theme fw-bold text-xs mb-1"><span>Total Visits</span></div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.total_visits) ? JSON.stringify(dashboardStats.data.total_visits) : ''}</span></div>
                                        </div>
                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="card shadow border-start-warning py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total End Of Life Users</span></div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.total_service_users_more_geche) ? JSON.stringify(dashboardStats.data.total_service_users_more_geche) : ''}</span></div>
                                        </div>
                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6 col-xl-4 mb-4">
                            <ServerVisitManagementGraph titleTemp="Overall Visit Statistics" graphData={dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.visit_stats) ? JSON.stringify(dashboardStats.data.visit_stats) : ''} />
                        </div>
                        <div className="col-md-6 col-xl-8 mb-4">
                            <div className="row">
                                <div className="col-md-6 col-xl-4 mb-4">
                                    <AdminStatusGraph titleTemp="Online / Offline Admin" graphData={dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.m_active_inactive_admins) ? JSON.stringify(dashboardStats.data.m_active_inactive_admins) : ''} />
                                </div>
                                <div className="col-md-6 col-xl-4 mb-4">
                                    <StaffStatusGraph titleTemp="Online / Offline Staff" graphData={dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.m_active_inactive_staffs) ? JSON.stringify(dashboardStats.data.m_active_inactive_staffs) : ''} />
                                </div>
                                <div className="col-md-6 col-xl-4 mb-4">
                                    <ServiceUserStatusGraph titleTemp="Online / Offline Service User" graphData={dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.m_service_users_type_count) ? JSON.stringify(dashboardStats.data.m_service_users_type_count) : ''} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-12 mb-4">
                            <div className='row'>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Average Visit Duration</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_average_visit_duration) ? JSON.stringify(dashboardStats.data.n_average_visit_duration) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Companies With Active E-learning Subscription</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_companies_with_active_elearning_subscription) ? JSON.stringify(dashboardStats.data.n_companies_with_active_elearning_subscription) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Companies With SJF Subscription</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_companies_with_sjf_subscription) ? JSON.stringify(dashboardStats.data.n_companies_with_sjf_subscription) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Conversion From Lead To Customer Percentage</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_conversion_from_lead_to_customer_percentage) ? JSON.stringify(dashboardStats.data.n_conversion_from_lead_to_customer_percentage) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total AI Interections</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_ai_interections) ? JSON.stringify(dashboardStats.data.n_total_ai_interections) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Alerts Raised</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_alerts_raised) ? JSON.stringify(dashboardStats.data.n_total_alerts_raised) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Cancelled Companies</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_cancelled_companies) ? JSON.stringify(dashboardStats.data.n_total_cancelled_companies) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Total Car Insurances Uploaded</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_car_insurances_uploaded) ? JSON.stringify(dashboardStats.data.n_total_car_insurances_uploaded) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Care Hours Required</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>Time: {dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_care_hours_required.time) ? JSON.stringify(dashboardStats.data.n_total_care_hours_required.time) : ''}</span></div>
                                                    <div className="text-dark fw-bold h5 mb-0"><span>Visits: {dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_care_hours_required.visits) ? JSON.stringify(dashboardStats.data.n_total_care_hours_required.visits) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Care Plans Added</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_care_plans_added) ? JSON.stringify(dashboardStats.data.n_total_care_plans_added) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total DBS Certificates Uploaded</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_dbs_certificates_uploaded) ? JSON.stringify(dashboardStats.data.n_total_dbs_certificates_uploaded) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Employee Transfers</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_employee_transfers) ? JSON.stringify(dashboardStats.data.n_total_employee_transfers) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Health Authorities</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_health_authorities) ? JSON.stringify(dashboardStats.data.n_total_health_authorities) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Income Billed</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_income_billed) ? JSON.stringify(dashboardStats.data.n_total_income_billed) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Local Authorities</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_local_authorities) ? JSON.stringify(dashboardStats.data.n_total_local_authorities) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* </div>
                        </div>
                        <div className="col-md-12 col-xl-12 mb-4">
                            <div className='row'> */}
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Medications Tracked</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_medications_tracked) ? JSON.stringify(dashboardStats.data.n_total_medications_tracked) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Messages Sent</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_messages_sent) ? JSON.stringify(dashboardStats.data.n_total_messages_sent) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Policies Procedures</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_policies_procedures) ? JSON.stringify(dashboardStats.data.n_total_policies_procedures) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Revoked Users</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_revoked_users) ? JSON.stringify(dashboardStats.data.n_total_revoked_users) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Shifts Booked</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_shifts_booked) ? JSON.stringify(dashboardStats.data.n_total_shifts_booked) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Staff Pay</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_staff_pay) ? JSON.stringify(dashboardStats.data.n_total_staff_pay) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Support Tickets Answered</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_support_tickets_answered) ? JSON.stringify(dashboardStats.data.n_total_support_tickets_answered) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Support Tickets Raised</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_support_tickets_raised) ? JSON.stringify(dashboardStats.data.n_total_support_tickets_raised) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Suspended Users</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_suspended_users) ? JSON.stringify(dashboardStats.data.n_total_suspended_users) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Training Records Uploaded</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_training_records_uploaded) ? JSON.stringify(dashboardStats.data.n_total_training_records_uploaded) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Users In Hospital</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_users_in_hospital) ? JSON.stringify(dashboardStats.data.n_total_users_in_hospital) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Users On Location</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_users_on_location) ? JSON.stringify(dashboardStats.data.n_total_users_on_location) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Users With DNACPR</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_users_with_dnacpr) ? JSON.stringify(dashboardStats.data.n_total_users_with_dnacpr) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Working Hours</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.n_total_working_hours) ? JSON.stringify(dashboardStats.data.n_total_working_hours) : ''}</span></div>
                                                </div>
                                                {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total End Of Life Users</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="text-dark fw-bold h5 mb-0"><span>{dashboardStats && dashboardStats.data && dashboardStats.data && JSON.stringify(dashboardStats.data.total_service_users_more_geche) ? JSON.stringify(dashboardStats.data.total_service_users_more_geche) : ''}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

