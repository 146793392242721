import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { NavLink, useParams } from 'react-router-dom';

// import DatePicker from "react-datepicker";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

// import { TimePicker } from 'react-ios-time-picker';
import "react-datepicker/dist/react-datepicker.css";

import { retrieveServiceUser } from '../architectureServers/actions/arcServerServiceUsers';

import { retrieveActivityList, retrieveRecommandEmployeeList, create } from './actions/arcServerVisits';

function getDates(startDate, endDate) {
    const dates = []
    let currentDate = startDate
    const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
    }
    while (currentDate <= endDate) {
        dates.push(currentDate)
        currentDate = addDays.call(currentDate, 1)
    }
    return dates
}


export default function ArchitectureServerVisits_add(props) {
    const { server_id } = useParams();
    let InitialData = {
        adminID: 'superadmin',
        assignedActivities: [],
        cancelled: false,
        date: '',
        datetime: new Date(),
        documentURL: [],
        phoneNumber: '',
        priorityTasks: [],
        staffIDs: [],
        status: 'pending',
        taskCompleted: 0,
        taskLocation: { Longitude: '', Latitude: '' },
        timeRange: { from: '10:00:00', to: '10:00:00' },
        visit_time: 0,
        totalTasks: 0,
        userID: '',
        visitTime: 0,
        country: '',
        county: '',
        town: '',
        postalCode: '',
        street: '',
        firstLineOfAddress: '',
        secondLineOfAddress: '',
    }
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };

    const [errorData, setErrorData] = useState(InitialErrData);
    const [oldProp, setOldProp] = useState({});
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState(InitialData);
    const [date, setDate] = useState(InitialData.date);
    const [alldates, setAllDates] = useState([]);
    const [createModalVisibility, setCreateModalVisibility] = useState((props.visibility || props.is_edit) ? true : false);
    const [startTimeValue, setStartTimeValue] = useState(InitialData.timeRange.from);
    const [endTimeValue, setEndTimeValue] = useState(InitialData.timeRange.to);
    const [visitTimeValue, setVisitTimeValue] = useState(0);
    const [duration, setDuration] = useState(0);

    const [serviceUserData, setServiceUserData] = useState([]);
    const [ActivityData, setActivityData] = useState([]);
    const [RecommandEmployee, setRecommandEmployee] = useState([]);
    var RecommandEmployeeList = [];
    const [SelectedActivities, setSelectedActivities] = useState([])
    const [SelectedRecommendedEmployee, setSelectedRecommendedEmployee] = useState([])
    const [IsRangePicker, setIsRangePicker] = useState(false);
    const [Counter, setCounter] = useState(0);
    const dispatch = useDispatch();



    useEffect(() => {
        if (props && props.visibility) {
            setCreateModalVisibility(true);
        } else {
            setCreateModalVisibility(false);
        }
        let data = {
            server_id: server_id,
            limit: 1000,
            last_id: '',
            first_id: '',
        };
        dispatch(retrieveServiceUser(data)).then((response) => {
            setServiceUserData(response);
        });

    }, [oldProp, props]);




    function diff(start, end) {
        start = start.split(":");
        end = end.split(":");
        var startDate = new Date(0, 0, 0, start[0], start[1], start[2]);
        var endDate = new Date(0, 0, 0, end[0], end[1], end[2]);
        var diff = endDate.getTime() - startDate.getTime();
        var hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);
        diff -= minutes * 1000 * 60;
        var seconds = Math.floor(diff / 1000);
    
        // If using time pickers with 24 hours format, add the below line get exact hours
        if (hours < 0)
           hours = hours + 24;
    
        return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes + ":" + (seconds <= 9 ? "0" : "") + seconds;
    }





    let handleStep = (step) => {
        if (step == 2 && SelectedActivities.length == 0) {
            alert("Please choose service user & activities.");
        } else if (step == 3 && date.length == 0) {
            alert("Please choose dates");
        } else if (step == 3 && startTimeValue.length == 0) {
            alert("Please choose start time");
        } else if (step == 3 && endTimeValue.length == 0) {
            alert("Please choose end time");
        } else if (step == 3 && startTimeValue == endTimeValue) {
            alert("Please choose a valid end time");
        } else {
            // console.log(alldates)

            if (step == 3) {
                let tempRecommandEmployee = [];
                // console.log(alldates)
                for (let tempi = 0; tempi < alldates.length; tempi++) {
                    let tempDate = new Date(alldates[tempi]);
                    let tempday = tempDate.getDate();
                    let tempmonth = parseInt(tempDate.getMonth()) + 1;
                    let tempyear = tempDate.getFullYear();
                    let temphour = startTimeValue.split(':')[0];
                    let tempminute = startTimeValue.split(':')[1];
                    // let tempsecond = tempDate.getSeconds();
                    let tempmeridium = startTimeValue.split(':')[0] >= 12 ? 'pm' : 'am';

                    let tempTime = (temphour < 10 ? '0' + parseInt(temphour) : temphour) % 12;
                    tempTime += ':' + (tempminute < 10 ? '0' + parseInt(tempminute) : tempminute) + ' ' + tempmeridium;
                    // tempTime = (temphour < 10 ? '0'+temphour : temphour);


                    setTimeout(() => {
                        // console.log(JSON.stringify(RecommandEmployee))
                        // console.log(JSON.stringify(tempRecommandEmployee))
                        let data = {
                            server_id: server_id,
                            date: tempyear + '-' + (tempmonth < 10 ? '0' + tempmonth : tempmonth) + '-' + (tempday < 10 ? '0' + tempday : tempday),
                            start_time: tempTime,
                            limit: 1000,
                            last_id: '',
                            first_id: '',
                        };

                        dispatch(retrieveRecommandEmployeeList(data)).then((response) => {
                            tempRecommandEmployee.push(response);
                            RecommandEmployeeList = tempRecommandEmployee;
                            if(response)
                                setRecommandEmployee([...tempRecommandEmployee]);
                        });
                    }, (tempi+1)*1000)
                }
            }
            if (step < 4) {
                setStep(step);
            } else {
                let tempHandleData = {}
                for(let xi = 0; xi < RecommandEmployee.length; xi++)
                    console.log(RecommandEmployee.length)
                for(let xj = 0; xj < alldates.length; xj++)
                    console.log(alldates.length)

                for(let xi = 0; xi < RecommandEmployee.length; xi++) {
                    for(let xj = 0; xj < alldates.length; xj++) {
                        if(alldates[xj].toISOString().split('T')[0] == RecommandEmployee[xi]['date']) {
                            tempHandleData = {
                                server_id: server_id,
                                admin_id: 'superadmin',
                                service_user_id: formData?.userID,
                                activities: SelectedActivities,
                                date: alldates[xj].toISOString().split('T')[0],
                                start_time: startTimeValue,
                                end_time: endTimeValue,
                                visit_time: duration,
                                employee_id: [RecommandEmployee[xi]['recommendation']],
                            }
                            dispatch(create(tempHandleData)).then((response) => {
                                console.log(response);
                            });
                        }
                    }
                }
            }
        }
    }


    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        // if(props.callingExternal)
        props.updateParentState(false)
    }

    let handleActivitySelect = (activity) => {
        let SelectedActivitiesTemp = SelectedActivities;
        const index = SelectedActivitiesTemp.indexOf(activity);
        if (index > -1) {
            SelectedActivitiesTemp.splice(index, 1);
        } else {
            SelectedActivitiesTemp.push(activity);
        }
        setSelectedActivities(SelectedActivitiesTemp);
        setCounter(Counter + 1);
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    const onChange = (key, timeValue) => {
        let durationTemp = 0;
        if (key == 'start_time')
            setStartTimeValue(timeValue.time);
        if (key == 'end_time')
            setEndTimeValue(timeValue.time);
        if ((timeValue.time.length > 0 && endTimeValue.length > 0) || (startTimeValue.length > 0 && timeValue.time.length > 0)) {
            if (key == 'start_time') {
                durationTemp = diff(timeValue.time, endTimeValue).split(':');
                durationTemp = parseInt(durationTemp[0]) * 3600 + parseInt(durationTemp[1]) * 3600 + parseInt(durationTemp[2]);
                // console.log(durationTemp)
            }
            if (key == 'end_time') {
                durationTemp = diff(startTimeValue, timeValue.time).split(':');
                durationTemp = parseInt(durationTemp[0]) * 3600 + parseInt(durationTemp[1]) * 3600 + parseInt(durationTemp[2]);
                // console.log(durationTemp)
            }
        }
        console.log(durationTemp, timeValue.time, startTimeValue, endTimeValue)
        setDuration(durationTemp);
    }


    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
        console.log(name)
        let data = {
            server_id: server_id,
            username: value,
            limit: 1000,
            last_id: '',
            first_id: '',
        };
        if (name == 'userID') {
            setSelectedActivities([])
            dispatch(retrieveActivityList(data)).then((response) => {
                console.log(response)
                setActivityData(response);
            });
        }
    }


    let handleDatePicker = (date) => {
        setDate(date)
        let dateArr = []
        if(IsRangePicker) {
            setAllDates(getDates(new Date(date[0]), new Date(date[1])))
        } else {
            for(let xi = 0; xi < date.length; xi++) {
                dateArr.push(new Date(date[xi]));
            }
            setAllDates(dateArr);
        }
        // console.log(date)
    }



    let checkValidation = (name, value) => {
        let errorDataTemp = { name: name, status: 0, message: ' Invalid Data. ' };
        let errorstatus = 0;
        setErrorData({});
        if (value != '' && name == 'userID' && !isAlphaNumeric(value)) {
            // console.log(value)
            setErrorData(errorDataTemp);
            errorstatus = 1;
        } else if (value == '' && name == 'date') {
            // console.log(value)
            setErrorData(errorDataTemp);
            errorstatus = 1;
        } else if (value == '' && name == 'start_time') {
            // console.log(value)
            setErrorData(errorDataTemp);
            errorstatus = 1;
        } else if (value == '' && name == 'end_time') {
            // console.log(value)
            setErrorData(errorDataTemp);
            errorstatus = 1;
            // } else if (value != '' && name == 'email' && !isEmail(value)) {
            //     setErrorData(errorDataTemp);
            //     errorstatus = 1;
        } else if (value != '' && name == 'phoneNumber' && !isNumeric(value)) {
            setErrorData(errorDataTemp);
            errorstatus = 1;
        } else { }
        // console.log(name, value, name == 'ContactNumber', isText(value), errorstatus)
        return errorstatus;
    }
    // console.log("visibility", props.visibility, createModalVisibility);
    // console.log(serviceUserData.service_users);
    return (
        <>
            <div className="card card-default" role="document">
                <div className="card-body">
                    <div className="card-header">
                        <h4 className="card-title">Add Visits</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}></button>
                    </div>
                    <div className="card-body">
                        {step == 1 ?
                            <div className="row">
                                <div className="container-fluid">
                                    {/* <div className="row">
                                        <div className='form-group'>
                                            <label className="form-label">Service User</label>
                                            <select className="form-select" type="text" value={formData?.userID} onChange={handleChange} required="" autofocus="" name="userID" placeholder="Service User">
                                                <option value={''}>Select</option>
                                                {adminData && adminData.service_users && adminData.service_users.length > 0 ?
                                                    adminData.service_users.map((val, index) => {
                                                        return (
                                                            <option value={val.username}>{val.full_name}</option>
                                                        )
                                                    })
                                                    : ''
                                                }
                                            </select>
                                            {(errorData?.res == 0 && errorData?.key == 'date') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errorData?.msg}</small>
                                                : ''}
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className='form-group'>
                                            <label className="form-label">Service User</label>
                                            <select className="form-select" type="text" value={formData?.userID} onChange={handleChange} required="" autofocus="" name="userID" placeholder="Service User">
                                                <option value={''}>Select</option>
                                                {serviceUserData && serviceUserData.service_users && serviceUserData.service_users.length > 0 ?
                                                    serviceUserData.service_users.map((val, index) => {
                                                        return (
                                                            <option value={val.username}>{val.full_name}</option>
                                                        )
                                                    })
                                                    : ''
                                                }
                                            </select>
                                            {(errorData?.res == 0 && errorData?.key == 'date') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errorData?.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    {!formData?.userID ? '' :
                                        <div className="row">
                                            <div className='container-fluid'>
                                                <label className="form-label mt-2">Choose Activities</label>
                                                <div className="row">
                                                    <div className='container-fluid p-0' style={{ height: '500px', overflowY: 'auto' }}>
                                                        {ActivityData && ActivityData.length == 0 ? 'No Activities for this user.' :
                                                            ActivityData.map((value, index) => {
                                                                return (
                                                                    <div className="card mt-1 mb-1 p-cursor" onClick={() => handleActivitySelect(value.name)}>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-1">
                                                                                    {SelectedActivities.indexOf(value.name) > -1 ?
                                                                                        <i className='fa fa-check'></i>
                                                                                        : ''
                                                                                    }
                                                                                </div>
                                                                                <div className="col-9" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                    <div className="text-uppercase fw-bold h6 mb-0 Poppins fg-theme"><span>{value.name}</span></div>
                                                                                </div>
                                                                                <div className="col-2 text-right">
                                                                                    <img className='img-icon-activityList' src={value.icon} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            : step == 2 ?
                                <div className="row">
                                    {!formData?.userID ? '' :
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="container-fluid text-center">
                                                    <button type='button' className={`btn btn-primary mr-2 btn-block ${IsRangePicker ? 'active' : ''}`} onClick={() => { setIsRangePicker(false); setDate('') }}>Multiple</button>
                                                    <button type='button' className={`btn btn-primary btn-block ${IsRangePicker ? 'active' : ''}`} onClick={() => { setIsRangePicker(true); setDate('') }}>Range</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className='form-group'>
                                                        <label className="form-label">Date</label>
                                                        <DatePicker multiple={!IsRangePicker} range={IsRangePicker} selected={date} value={date} dateFormat="dd/MM/yyyy" onChange={(date) => handleDatePicker(date)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className='form-group'>
                                                        <label className="form-label">Start Time</label>
                                                        <input type='time' className='form-control' step="1" placeholder="Start Time" onChange={(ev) => { onChange('start_time', { time: ev.target.value }) }} value={startTimeValue} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className='form-group'>
                                                        <label className="form-label">End Time</label>
                                                        <input type='time' className='form-control' step="1" placeholder="End Time" onChange={(ev) => { onChange('end_time', { time: ev.target.value }) }} value={endTimeValue} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className='form-group'>
                                                        <label className="form-label">Visit Time</label>
                                                        <input type='text' disabled className='form-control' step="1" placeholder="Visit Time" value={duration} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                                : step == 3 ?
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className='container-fluid'>
                                                    <label className="form-label">Recommended Staff</label>

                                                    <>{JSON.stringify(RecommandEmployeeList)}</>
                                                    <div className="row">
                                                        <div className='container-fluid p-0' style={{ height: '300px', overflowY: 'scroll' }}>
                                                            {RecommandEmployee && RecommandEmployee.length > 0 ? 
                                                                RecommandEmployee.map((value, index) => {
                                                                    return (
                                                                        <div className="card mt-1 mb-1 p-cursor">
                                                                            <div className="card-body">
                                                                                <div className="row">
                                                                                    <div className="col-12" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                        <div className="text-uppercase fw-bold h6 mb-3 Poppins fg-theme"><span>{value.recommendation}</span></div>
                                                                                        <p className="text-uppercase fw-bold mb-0 Poppins"><span>{value.date}</span></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                :'No Recommandation for this user.' 
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                    </div>
                    <div className="card-footer flex-row-reverse">
                        <button className="btn btn-primary float-center" type="button" onClick={() => handleStep(step == 4 ? 4 : step + 1)}>{(step == 3) ? 'Save' : 'Next'}</button>
                        {(step > 1) ?
                            <button className="btn btn-primary" type="button" onClick={() => setStep(step == 0 ? 0 : step - 1)}>Previous</button>
                            : ''}
                    </div>
                </div>
            </div>
        </>
    )
}
