import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import store from "./store";

import { Provider } from "react-redux"






// if ('serviceWorker' in navigator) {
//   // window.addEventListener('sw-cached-site.js', function () {
//     const registration = await navigator.serviceWorker.register('/service-worker.js')
//       .then(registration => {
//         console.log('Service Worker registered with scope:', registration.scope);
//       })
//       .catch(error => {
//         console.error('Service Worker registration failed:', error);
//       });
//       // console.log(registration);
//       console.log("registration.installing", registration.installing)
//       console.log("registration.waiting", registration.waiting)
//       console.log("registration.active", registration.active)
//       const sw = registration.installing || registration.waiting || registration.active
//       sw.postMessage({ milliseconds: Date.now() })
//   // });
// }




const checkPermission = () => {
  if (!('serviceWorker' in navigator)) {
      console.log("No support for service worker!");
      throw new Error("No support for service worker!")
  }

  if (!('Notification' in window)) {
      console.log("No support for notification API");
      throw new Error("No support for notification API");
  }

  if (!('PushManager' in window)) {
      console.log("No support for Push API");
      throw new Error("No support for Push API")
  }
}

const registerSW = async () => {
  const registration = await navigator.serviceWorker.register('http://localhost:3000/service-worker.js')
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
      return registration;
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
      return error;
  });
  console.log("registration", registration);
  // console.log("registration.installing", registration.installing)
  // console.log("registration.waiting", registration.waiting)
  // console.log("registration.active", registration.active)
  const sw = registration.installing || registration.waiting || registration.active;
  sw.postMessage({ milliseconds: Date.now() });
  return registration;
}

const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission();

  if (permission !== 'granted') {
      console.log("Notification permission not granted");
      throw new Error("Notification permission not granted");
  }

}

const main = async () => {
  checkPermission()
  await requestNotificationPermission()
  await registerSW()
}
main();


// if ('serviceWorker' in navigator) {
//   window.addEventListener('sw-cached-site.js', function() {
//     navigator.serviceWorker.register('service-worker.js', {
//       scope: '/',
//     });
//   });
// } 
// window.process = { env: { NODE_ENV: 'production' } };







const root = ReactDOM.createRoot(document.getElementById('wrapper'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
