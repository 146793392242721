import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import { retrieve } from './actions/AdminSocialCareNavigator'
import CalenderView from './components/CalendarView';

export default function AdminSocialCareNavigator() {
  const { server_id, admin_id } = useParams();
  const [VisitData, setVisitData] = useState([]);
  const [loading, setLoading] = useState(true);


  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    let data = {
      server_id: server_id ? server_id : '',
      username: admin_id ? admin_id : '',
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      // console.log(response)
      let tempVisitData = [];
      let start_date = '';
      let end_date = '';
      for(let i=0; i < response.length; i++) {
        start_date = formatDateTime(response[i]['data']['date'], response[i]['data']['timeRange']['from'], 'd/m/y H:i a')
        end_date = formatDateTime(response[i]['data']['date'], response[i]['data']['timeRange']['to'], 'd/m/y H:i a')
        // console.log(new Date(response[i]['data']['startedDate']).toISOString())
        tempVisitData.push({
          end: end_date,
          resourceId: response[i]['id'],
          start: start_date,
          title: response[i]['data']['userID']
        })
      }
      // console.log(tempVisitData)
      setVisitData(tempVisitData);
    });
  }, [server_id, admin_id]);


  let formatDateTime = (date, time, format) => {
    if(date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
      // console.log(date, time)
      let tempDay = date.split('/')[0]
      let tempMonth = date.split('/')[1]
      let tempYear = date.split('/')[2]
      let time_ = time.split(' ')[0];
      let hour = time_.split(':')[0];
      hour = parseInt(time_.split(':')[0]);
      let minute = time_.split(':')[1];
      if(time.split(' ')[1] == 'PM') {
        return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
      } else {
        return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
      }
    } else {
      return new Date().toISOString();
    }
  }
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <CalenderView CalendarData={loading ? [] : VisitData} />
        </>
      }
    </>
  )
}
