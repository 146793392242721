import React, { useRef, useState } from 'react'
import GoogleMap from 'google-maps-react-markers'

import Marker from './Marker'

const GoogleMapMarkers = ({ 
  EmployeeDetails, 
  ServiceUsersDetails, 
  onMarkerClick, 
  OnlineEmployeeVisibility,
  OfflineEmployeeVisibility,
  OnlineServiceUserVisibility,
  OfflineServiceUserVisibility,
}) => {
  // console.log("EmployeeDetails", EmployeeDetails)
  const mapRef = useRef(null)
  const [mapReady, setMapReady] = useState(false)

  /**
   * @description This function is called when the map is ready
   * @param {Object} map - reference to the map instance
   * @param {Object} maps - reference to the maps library
   */
  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map
    setMapReady(true)
  }

  return (
    <>
      {mapReady && <div></div>}
      <GoogleMap
        apiKey="AIzaSyAnfyGtcIMpkHEDVmVEsCgeC1cIqNXdsFo"
        defaultCenter={{ lat: 40, lng: 0 }}
        // defaultCenter={{ lat: 40, lng: 0 }}
        defaultZoom={4}
        // options={mapOptions}
        // mapMinHeight="100vh"
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChange={(mapProps, map) => console.log('Map moved', mapProps, map)}
        // onChange={(map) => console.log('Map moved', map)}
      >
        {OnlineEmployeeVisibility && EmployeeDetails && EmployeeDetails.data && EmployeeDetails.data.length > 0 ?
          EmployeeDetails.data.map((value, index) => (
          value?.is_online == true ? 
            <Marker
              key={1}
              size={30}
              color="#1d46b5"
              lat={value?.coordinates ? value?.coordinates[0] : ''}
              lng={value?.coordinates ? value?.coordinates[1] : ''}
              markerId={value?.username}
              userType={'Employee'}
              userStatus={'Online'}
              onClick={(e, { markerId, lat, lng }) => onMarkerClick(e, { markerId, lat, lng }, mapRef)} // you need to manage this prop on your Marker component!
            // draggable={true}
            // onDragStart={(e, { latLng }) => {}}
            // onDrag={(e, { latLng }) => {}}
            // onDragEnd={(e, { latLng }) => {}}
            />
          :''
        )) : ''}
        {OfflineEmployeeVisibility && EmployeeDetails && EmployeeDetails.data && EmployeeDetails.data.length > 0 ?
          EmployeeDetails.data.map((value, index) => (
          value?.is_online == false ? 
            <Marker
              key={1}
              size={35}
              color="rgb(185 75 26)"
              lat={value?.coordinates ? value?.coordinates[0] : ''}
              lng={value?.coordinates ? value?.coordinates[1] : ''}
              markerId={value?.username}
              userType={'Employee'}
              userStatus={'Offline'}
              onClick={(e, { markerId, lat, lng }) => onMarkerClick(e, { markerId, lat, lng }, mapRef)} // you need to manage this prop on your Marker component!
            // draggable={true}
            // onDragStart={(e, { latLng }) => {}}
            // onDrag={(e, { latLng }) => {}}
            // onDragEnd={(e, { latLng }) => {}}
            />
          :''
        )) : ''}
        {OnlineServiceUserVisibility && ServiceUsersDetails && ServiceUsersDetails.data && ServiceUsersDetails.data.length > 0 ?
          ServiceUsersDetails.data.map((value, index) => (
            value?.is_online == true ? 
            <Marker
              key={1}
              size={30}
              color="#9003fc"
              lat={value?.coordinates ? value?.coordinates[0] : ''}
              lng={value?.coordinates ? value?.coordinates[1] : ''}
              markerId={value?.username}
              userType={'User'}
              userStatus={'Online'}
              onClick={(e, { markerId, lat, lng }) => onMarkerClick(e, { markerId, lat, lng }, mapRef)} // you need to manage this prop on your Marker component!
            // draggable={true}
            // onDragStart={(e, { latLng }) => {}}
            // onDrag={(e, { latLng }) => {}}
            // onDragEnd={(e, { latLng }) => {}}
            />
          :''
        )) : ''}
        {OfflineServiceUserVisibility && ServiceUsersDetails && ServiceUsersDetails.data && ServiceUsersDetails.data.length > 0 ?
          ServiceUsersDetails.data.map((value, index) => (
            value?.is_online == false ? 
            <Marker
              key={1}
              size={35}
              color="#fc9803"
              lat={value?.coordinates ? value?.coordinates[0] : ''}
              lng={value?.coordinates ? value?.coordinates[1] : ''}
              markerId={value?.username}
              userType={'User'}
              userStatus={'Offline'}
              onClick={(e, { markerId, lat, lng }) => onMarkerClick(e, { markerId, lat, lng }, mapRef)} // you need to manage this prop on your Marker component!
            // draggable={true}
            // onDragStart={(e, { latLng }) => {}}
            // onDrag={(e, { latLng }) => {}}
            // onDragEnd={(e, { latLng }) => {}}
            />
          :''
        )) : ''}
      </GoogleMap>
    </>
  )
}

export default GoogleMapMarkers