import {
    // FILE_UPLOAD,
    CREATE_MESSAGE,
    RETRIEVE_MESSAGE,
    RETRIEVESINGLE_MESSAGE,
    UPDATE_MESSAGE,
    DELETE_MESSAGE,
} from "./type";
import Message from "../service/Message";
export const create = (data) => async (dispatch) => {
    try {
        const res = await Message.create(data);
        dispatch({
            type: CREATE_MESSAGE,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await Message.fetchList(data);
        dispatch({
            type: RETRIEVE_MESSAGE,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await Message.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_MESSAGE,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await Message.update(data);
        dispatch({
            type: UPDATE_MESSAGE,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await Message.changeStatus(data);
//         dispatch({
//             type: UPDATE_MESSAGE,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await Message.trash(data);
        dispatch({
            type: DELETE_MESSAGE,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};