import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import { retrieve, retrieveSingle } from './actions/InspectionAreaTaxonomy'

export default function InspectionAreaTaxonomy() {
  const [inspectionAreaData, setInspectionAreaData] = useState(null);
  const [InspectionAreas, setInspectionAreas] = useState([]);
  const [currentPageInspectionArea, setCurrentPageInspectionArea] = useState(1);
  const [perPageInspectionArea, setPerPageInspectionArea] = useState(10);
  const [inspectionAreaIdInspectionArea, setInspectionAreaIdInspectionArea] = useState('');
  const dispatch = useDispatch();

  let fetchInspectionAreas = (page, perPage) => {
    setCurrentPageInspectionArea(page)
    setPerPageInspectionArea(perPage)
    let data = {
      page: String(page),
      perPage: String(perPage),
      partnerCode: 'OpenAnswers',
    };
    dispatch(retrieve(data)).then(response => {
      setInspectionAreaData(response.response)
      // console.log(response)
    });
  }


  useEffect(() => {
    fetchInspectionAreas(currentPageInspectionArea, perPageInspectionArea);
  }, []);

  const handleInspectionAreaPreviousClick = () => {
    if (currentPageInspectionArea > 1) {
      fetchInspectionAreas(currentPageInspectionArea - 1, perPageInspectionArea);
    }
  };

  const handleInspectionAreaNextClick = () => {
    if (currentPageInspectionArea < inspectionAreaData.totalPages) {
      fetchInspectionAreas(currentPageInspectionArea + 1, perPageInspectionArea);
    }
  };
  return (
    <>
      <div class="container-fluid mt-5">
        <div className='row'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <div class="d-sm-flex justify-content-between align-items-center mb-4">
                  <h3 class="mb-0 fg-theme">InspectionAreas</h3>
                </div>
                <div class="card shadow">
                  <div class="card-body">
                    <div className='container-fluid'>
                      <div class="row">
                        <div class="col-md-6 text-nowrap">
                          <div id="dataTable_length" class="dataTables_length" aria-controls="dataTable">
                            <label class="form-label">Show&nbsp;
                              <select class="d-inline-block form-select form-select-sm" name="record_length" value={perPageInspectionArea} onChange={(e) => { console.log("InspectionArea", e.target.value); fetchInspectionAreas(perPageInspectionArea, e.target.value); }}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="text-md-end dataTables_filter" id="dataTable_filter">
                            <label class="form-label">
                              <input type="search" class="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='container-fluid'>
                      {inspectionAreaData && (
                        <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                          <table class="table my-0" id="dataTable">
                            <thead>
                              <tr>
                                <th>Inspection Area ID</th>
                                <th>Inspection Area Name</th>
                                <th>Inspection Area Type</th>
                                <th>Inspection Area End Date</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {inspectionAreaData && inspectionAreaData.inspectionAreas && inspectionAreaData.inspectionAreas.length > 0 ? inspectionAreaData.inspectionAreas.map((inspectionArea) => {
                                return (
                                  <tr key={inspectionArea.inspectionAreaId}>
                                    <td>{inspectionArea.inspectionAreaId}</td>
                                    <td>{inspectionArea.inspectionAreaName}</td>
                                    <td>{inspectionArea.inspectionAreaType}</td>
                                    <td>{inspectionArea.endDate}</td>
                                    <td>{inspectionArea.status}</td>
                                    {/* <td><button className='btn btn-primary btn-sm' type='button' onClick={() => setInspectionAreaIdInspectionArea(inspectionArea.inspectionAreaId)}><i className='fa fa-angle-right'></i></button></td> */}
                                  </tr>
                                )
                              }) : ''}
                            </tbody>
                          </table>
                          <div class="">
                            {/* <div class="col-md-6 align-self-center">
                        <p id="dataTable_info" class="dataTables_info" role="status" aria-live="polite">Total Records 5</p>
                      </div> */}
                            <div class="offset-md-6 col-md-6">
                              <nav class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                <ul class="pagination">
                                  <li class="page-item" onClick={handleInspectionAreaPreviousClick} disabled={currentPageInspectionArea === 1}>
                                    <a class="page-link" aria-label="Previous" href="#">
                                      <span aria-hidden="true">«</span> Previous</a>
                                  </li>
                                  <li class="page-item" onClick={handleInspectionAreaNextClick} disabled={currentPageInspectionArea === inspectionAreaData.totalPages}>
                                    <a class="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span>
                                    </a>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

