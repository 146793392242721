import {
    CREATE_ARCHITECTURESERVERVISITS,
    RETRIEVE_ARCHITECTURESERVERVISITS,
    RETRIEVE_ARCHITECTURESERVERACTIVITIES,
    RETRIEVE_ARCHITECTURESERVERRECOMMENDEMPLOYEE,
    RETRIEVESINGLE_ARCHITECTURESERVERVISITS,
    UPDATE_ARCHITECTURESERVERVISITS,
    DELETE_ARCHITECTURESERVERVISITS,
  } from "../actions/type";
  const initialState = [];
  function ARCServerVisitsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTURESERVERVISITS:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTURESERVERVISITS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVE_ARCHITECTURESERVERACTIVITIES:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVE_ARCHITECTURESERVERRECOMMENDEMPLOYEE:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTURESERVERVISITS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTURESERVERVISITS:
        return {data: state.data, payload};
      case DELETE_ARCHITECTURESERVERVISITS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCServerVisitsReducer};