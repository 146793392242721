import React, { useState, useEffect, useRef } from 'react'

import { Loader, Placeholder } from 'rsuite';
import { NavLink, useParams } from 'react-router-dom';

import axios from 'axios';

import { ReactSession } from 'react-client-session';

import Avatar from '../../../assets/avatars/avatar1.jpeg'

function SingleMessage(props) {
    // const { ticket_id } = useParams();
    let [loading, setLoading] = useState(false)
    let [shortView, setShortView] = useState(true);
    return (
        <div className="container-fluid SingleMessage mt-2 mb-2 d-grid" onClick={() => setShortView(!shortView)}>
            <div className="row align-items-center no-gutters">
                {/* <div className="col-auto">
                    <img src={props.single_user_image} className='box-image p-1 img-circle' />
                </div> */}
                <div className="col me-2">
                    <div className="text-uppercase fw-bold h6 mb-1 Poppins fg-theme"><span>{props.single_user_name}</span></div>
                    <div className="MSG text-ash mb-0 Poppins">
                        <div className='card'>
                            <div className='card-body'>
                                <span>{props.single_user_message_text}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function ArchitectureSupportTicketsChat(props) {
    ReactSession.setStoreType("localStorage");
    const { ticket_id, server_id } = useParams();
    const [IsSendResult, setIsSendResult] = useState(false);
    const [IsFocus, setIsFocus] = useState(false);
    const [MinKeywords, setMinKeywords] = useState(1);
    const [ChatResultData, setChatResultData] = useState([]);
    const [message, setMessage] = useState('');
    const [chat_type, setChatType] = useState('');
    const [sent_by, setSentBy] = useState(ReactSession.get("username") ? ReactSession.get("username") : '');

    const messagesEndRef = useRef(null)

    useEffect(() => {
        setInterval(() => {
            fetchChatData()
        }, 3000);
    }, []);

    let fetchChatData = async () => {
        let data = { server_id: server_id, support_id: ticket_id };
        try {
            // Make a POST request using axios
            // ${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/support_tickets/fetch_chats`, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }, data
            });
            setChatResultData(response.data.data)
            // Handle the response data
            // if (response && response.data && response.data.data && response.data.data.response) {
            //     setIsFocus(true);
            //     setIsSendResult(true);
            //     setSendResultData(response.data.data.response)
            // }
            // console.log('Response response:', response.data.data.response);
        } catch (error) {
            // Handle any errors that occurred during the request
            console.error('Error:', error);
        }
    }








    let fetchSendKeyword = async () => {
        let data = { server_id: server_id, support_id: ticket_id, message: message, chat_type: chat_type, sent_by: 'superadmin' };
        setIsSendResult(false);
        setIsFocus(true);
        try {
            // Make a POST request using axios
            // ${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/support_tickets/send_chat`, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }, data
            });

            setMessage('')
            // const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/architecture/learners/keywordSend`, { keyword: message });
            // Handle the response data
            // if (response && response.data && response.data.data && response.data.data.response) {
            //     setIsFocus(true);
            //     setIsSendResult(true);
            //     setSendResultData(response.data.data.response)
            // }
            // console.log('Response response:', response.data.data.response);
        } catch (error) {
            // Handle any errors that occurred during the request
            console.error('Error:', error);
        }
    }

    let handleSend = (e) => {
        setMessage(e.target.value);
        setChatType('text')   // 
        if (e.target.value.length == 0)
            setIsFocus(false)
        else if (e.target.value.length >= MinKeywords)
            if (e.key === 'Enter') {
                fetchSendKeyword();
                setIsFocus(true);
                setIsSendResult(false)
            }
            else
                setIsSendResult(false)
    }

    let handleSendIcon = () => {
        if (message.length < MinKeywords) {
            setIsSendResult(false);
        } else if (message.length >= MinKeywords) {
            setIsFocus(true);
            fetchSendKeyword();
            setIsSendResult(true);
        } else {
            setIsSendResult(false);
        }
    }

    let handleScroll = () => {
        console.log(messagesEndRef.current)
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }


    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-2 d-grid ">
            <div className='card'>
                <div className='card-header'>
                    <div className="row">
                        {/* <div className="col-auto">
                            <img src={Avatar} className='box-image p-1 img-circle rad-50pc' />
                        </div> */}
                        <div className="col me-2">
                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>{'Support Ticket'}</span></div>
                            <div className="h6 mb-1 Poppins"><span>{ticket_id ? ticket_id : ''}</span></div>
                        </div>
                    </div>
                </div>
                <div className='card-body h-calc2'>
                    <ul className='messageBox'>
                        {ChatResultData && ChatResultData.length > 0 ?
                            ChatResultData.map((val, index) => {
                                return (
                                    <>
                                        {
                                            (val.sent_by == 'superadmin') ?
                                                <li className='sender'><SingleMessage single_user_image={Avatar} single_user_name={val.sent_by} single_user_message_text={val.message} /></li>
                                                :
                                                <li className='receiver'><SingleMessage single_user_image={Avatar} single_user_name={val.sent_by} single_user_message_text={val.message} /></li>
                                        }
                                    </>

                                )
                            })
                            : ''}
                    </ul>
                    <div id="bottomScroll" ref={messagesEndRef}>&nbsp;</div>
                </div>
                <div className='card-footer searchBox'>
                    {/* <span className='input-group-text p-cursor' onClick={handleSendIcon}> <i className='fa fa-image'></i> </span> */}
                    {/* <span className='input-group-text p-cursor' onClick={handleSendIcon}> <i className='fa fa-paperclip'></i> </span> */}
                    <div className='input-group'>
                        {/* { IsFocus ? 'Send Here' : IsSendResult ? 'Send Here' : '' } */}
                        <input className='form-control' value={message} onChange={(e) => setMessage(e.target.value)}  type='text' placeholder={IsFocus ? 'Type your message' : IsSendResult ? 'Type your message' : 'Type your message'} onKeyDown={handleSend} />
                        {/* onFocus={() => setIsFocus(!IsFocus)} onBlur={() => setIsFocus(!IsFocus)} */}
                        <span className='input-group-text p-cursor' onClick={handleSendIcon}> <i className='fa fa-paper-plane'></i> </span>
                        <span className='input-group-text p-cursor' onClick={handleScroll}> <i className='fa fa-angle-down'></i> </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
