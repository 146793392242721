import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink } from 'react-router-dom';


import Logo2 from '../assets/logo.png'
import Logo from '../assets/wt-logo.png'

import SidebarMenus from './sidebarMenus';
import { ReactSession } from 'react-client-session';

ReactSession.setStoreType("localStorage");

export default function Header() {
  return (
    <>
        <nav className="navbar align-items-start sidebar sidebar-dark accordion bg-gradient-architecture navbar-dark">
            <div className="container-fluid flex-column p-0"><NavLink className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0" to="/Architecture">
                    <div className="sidebar-brand-icon">
                        {/* <i className="fas fa-laugh-wink"></i> */}
                        <div className='card'>
                        <div className='card-body p-2'>
                        <img src={Logo} style={{height: '25px'}} />
                        </div>
                        </div>
                    </div>
                    {/* <div className="sidebar-brand-text mx-3"><span>Brand</span></div> */}
                </NavLink>
                <hr className="sidebar-divider my-0" />
                <SidebarMenus />
                <hr class="sidebar-divider my-0"></hr>
                <ul className="w-100pc navbar-nav text-light" id="accordionSidebar">
                    <li className="nav-item" onClick={() => {ReactSession.set("login_status", 0); window.location.reload();}}>
                        <NavLink className="nav-link indent-18px" to="#">
                            <span>Logout</span>
                        </NavLink>
                    </li>
                </ul>
                {/* <ul className="navbar-nav text-light" id="accordionSidebar">
                    
                    <li className="nav-item">
                        <div className="accordion" role="tablist" id="accordion-1" style={{background: 'rgba(255,255,255,0)',borderRadius: '0'}}>
                            <div className="accordion-item" style={{borderStyle: 'none', borderRadius: '0px'}}>
                                <h2 className="accordion-header" role="tab" style={{borderStyle: 'none', borderRadius: '0px'}}>
                                    <button className="accordion-button collapsed text-light" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-1 .item-1" aria-expanded="false" aria-controls="accordion-1 .item-1" style={{background: '#AF1F24', borderStyle: 'none', padding: '16px', fontSize: '13.6px',borderRadius: '0px'}}>Architecture</button>
                                </h2>
                                <div className="accordion-collapse collapse item-1" role="tabpanel" data-bs-parent="#accordion-1" style={{padding: '0px',margin: '0px'}}>
                                    <div className="accordion-body">
                                        <NavLink className="d-block td-none" to="/Architecture/Dashboard" style={{padding: '5px 5px'}}>Dashboard</NavLink>
                                        <NavLink className="d-block td-none" to="/Architecture/Servers" style={{padding: '5px 5px'}}>Servers</NavLink>
                                        <NavLink className="d-block td-none" to="/Architecture/TypeOfService" style={{padding: '5px 5px'}}>Types of service</NavLink>
                                        <NavLink className="d-block td-none" to="/Architecture/SupportTickets" style={{padding: '5px 5px'}}>Support Tickets</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item">
                        <div className="accordion" role="tablist" id="accordion-2" style={{background: 'rgba(255,255,255,0)',borderRadius: '0'}}>
                            <div className="accordion-item" style={{borderStyle: 'none', borderRadius: '0px'}}>
                                <h2 className="accordion-header" role="tab" style={{borderStyle: 'none', borderRadius: '0px'}}>
                                    <button className="accordion-button collapsed text-light" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-2 .item-2" aria-expanded="false" aria-controls="accordion-2 .item-2" style={{background: '#AF1F24', padding: '16px', borderStyle: 'none', borderColor: 'rgb(255,255,255)',fontSize: '13.6px',borderRadius: '0px'}}>Social JobFindr</button>
                                </h2>
                                <div className="accordion-collapse collapse item-2" role="tabpanel" data-bs-parent="#accordion-2" style={{padding: '0px',margin: '0px'}}>
                                    <div className="accordion-body">
                                        <NavLink className="d-block td-none" to="/SocialJobFinder/Dashboard" style={{padding: '5px 5px'}}>Dashboard</NavLink>
                                        <NavLink className="d-block td-none" to="/SocialJobFinder/Companies" style={{padding: '5px 5px'}}>Companies</NavLink>
                                        <NavLink className="d-block td-none" to="/SocialJobFinder/Applicants" style={{padding: '5px 5px'}}>Applicants</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/Regulators"><span>Regulators</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/Attorneys"><span>Attorneys</span></NavLink>
                    </li>
                </ul> */}
                {/* <div className="text-center d-none d-md-inline"><button className="btn rounded-circle border-0" id="sidebarToggle" type="button"></button></div> */}
            </div>
        </nav>
    </>
  )
}
