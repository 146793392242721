import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";


import {
    updatePhone, retrieve
} from './actions/arcServerStaff';
import { useParams } from 'react-router-dom';

export default function ArchitectureServerStaff_phone(props) {
    const {server_id} = useParams();
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [ FormData, setFormData ] = useState(props?.singleValuePhone);
    const [ PhoneCode, setPhoneCode ] = useState('');
    const [ Phone, setPhone ] = useState('');
    

    const dispatch = useDispatch();



    useEffect(() => {
        setFormData(props?.singleValuePhone)
        setPhoneCode("");
        setPhone("");    
    }, [props?.singleValuePhone])

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        props?.updateParentStatePhone(false)
    }

    let handlePhoneCodeChange = (e) => {
        const { name, value } = e.target;
        setPhoneCode(value);
    }

    let handlePhoneChange = (e) => {
        const { name, value } = e.target;
        setPhone(value);
    }

    let handleUpdate = () => {
        console.log(FormData)
        let data = {
            uid: FormData?.uid,
            server_id: server_id,
            username: FormData?.username,
            phone_code: PhoneCode,
            phone_number: Phone,
        };
        dispatch(updatePhone(data)).then((response) => {
            alert(response.data.msg);
            props.updateParentStatePhone(false);
            setCreateModalVisibility(false);
            data = {
                server_id: server_id,
                limit: 10,
                last_id: '',
                first_id: '',
            };
            dispatch(retrieve(data)).then(() => { });
            setPhoneCode("");
            setPhone("");    
        })
        console.log(FormData)
    }


    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='container-fluid mt-4 mb-4'>
                    <div className='row'>
                        <div className='container-fluid mb-4'>
                            <i className='fa fa-times float-right' onClick={handleCreateModalVisibility}></i>
                            <h3 className='fg-theme text-left'>Update Staff Phone Number</h3>
                        </div>
                        <hr />
                        <div className='container-fluid'>
                            <div className='form-group'>
                                <label>Phone Code</label>
                                <input type='text' className='form-control' name='phone_code' value={PhoneCode} onChange={handlePhoneCodeChange} />
                            </div>
                            <div className='form-group'>
                                <label>Phone Number</label>
                                <input type='text' className='form-control' name='phone_number' value={Phone} onChange={handlePhoneChange} />
                            </div>
                        </div>
                        <hr />
                        <div className='container-fluid'>
                            <button className='btn btn-primary btn-theme' type='button' onClick={handleCreateModalVisibility}>Close</button>
                            <button className='btn btn-primary btn-theme float-right' type='button' onClick={handleUpdate}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
