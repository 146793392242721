import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

export default function Popup({children, updateParentState, visibility_status}) {
    console.log("Popup.js visibility_status", visibility_status)
    const [createModalVisibility, setCreateModalVisibility] = useState(visibility_status);
    const [simpleModal, setSimpleModal] = useState(true);
    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        updateParentState(false);
    }

  return (
    <>
        <div className={visibility_status?"modal fade show":"modal fade"} role="dialog" tabIndex="-1" id="modal-1">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    {/* <div className="modal-header">
                        <h4 className="modal-title">Add Regulator</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" onClick={handleCreateModalVisibility}></button>
                    </div> */}
                    <div className={simpleModal?"modal-body simple":"modal-body"}>
                        {children}
                    </div>
                    {/* <div className="modal-footer">
                        <button className="btn btn-light" type="button" data-bs-dismiss="modal" onClick={handleCreateModalVisibility}>Close</button>
                        <button className="btn btn-primary" type="button">Save</button>
                    </div> */}
                </div>
            </div>
        </div>
    </>
  )
}
