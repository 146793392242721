import {
    // FILE_UPLOAD,
    CREATE_ARCHITECTURESERVERDETAILS,
    RETRIEVE_ARCHITECTURESERVERDETAILS,
    RETRIEVESINGLE_ARCHITECTURESERVERDETAILS,
    UPDATE_ARCHITECTURESERVERDETAILS,
    DELETE_ARCHITECTURESERVERDETAILS,
} from "./type";
import ARCServerDetails from "../service/arcServerDetails";
// export const fileUpload = (data) => async (dispatch) => {
//   try {
//     const res = await ARCServerDetails.fileUpload(data);
//     dispatch({
//       type: FILE_UPLOAD,
//       payload: res.data.data,
//     });
//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
export const create = (data) => async (dispatch) => {
    try {
        const res = await ARCServerDetails.create(data);
        dispatch({
            type: CREATE_ARCHITECTURESERVERDETAILS,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await ARCServerDetails.fetchList(data);
        dispatch({
            type: RETRIEVE_ARCHITECTURESERVERDETAILS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await ARCServerDetails.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_ARCHITECTURESERVERDETAILS,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveMetricsEmployeeStarterLeaver = (data) => async (dispatch) => {
    try {
        const res = await ARCServerDetails.metricsEmployeeStarterLeaver(data);
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveMetricsEmployeeVisitSummery = (data) => async (dispatch) => {
    try {
        const res = await ARCServerDetails.metricsEmployeeVisitSummery(data);
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveMetricsEmployeeVisitStatistics = (data) => async (dispatch) => {
    try {
        const res = await ARCServerDetails.metricsEmployeeVisitStatistics(data);
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await ARCServerDetails.update(data);
        dispatch({
            type: UPDATE_ARCHITECTURESERVERDETAILS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await ARCServerDetails.changeStatus(data);
//         dispatch({
//             type: UPDATE_ARCHITECTURESERVERDETAILS,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await ARCServerDetails.trash(data);
        dispatch({
            type: DELETE_ARCHITECTURESERVERDETAILS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};