import {
    CREATE_ARCHITECTUREATTORNEYCLIENTS,
    RETRIEVE_ARCHITECTUREATTORNEYCLIENTS,
    RETRIEVESINGLE_ARCHITECTUREATTORNEYCLIENTS,
    UPDATE_ARCHITECTUREATTORNEYCLIENTS,
    DELETE_ARCHITECTUREATTORNEYCLIENTS,
  } from "../actions/type";
  const initialState = [];
  function ARCAttorneyClientsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTUREATTORNEYCLIENTS:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTUREATTORNEYCLIENTS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTUREATTORNEYCLIENTS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTUREATTORNEYCLIENTS:
        return {data: state.data, payload};
      case DELETE_ARCHITECTUREATTORNEYCLIENTS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCAttorneyClientsReducer};