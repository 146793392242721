import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";
import ReactSession from '../../components/ReactSession';

import {
    create, update, retrieve, createFranchise
} from "../Architecture/architectureServers/actions/arcServers";

export default function SignUp(props) {
    ReactSession.setStoreType('localStorage');
    const dispatch = useDispatch();
    const InitialData = {
        server_id: '',
        server_name: '',
        company_name: '',
        company_email: '',
        phone_number: '',
        company_address: '',
        cqc_provider_id: '',
        cqc_location_id: '',
        opening_hours: '',
        website: '',
        is_architecture: true,
        is_sjf: false,
        is_elearning: false,
        user_admin_id: '',
        user_email: '',
        user_full_name: '',
        user_username: '',
        user_password: '',
        user_phone_code: '',
        user_phone_number: '',
        user_gender: '',
        user_date_of_birth: new Date().toISOString(),
        user_religion: '',
        user_country: '',
        user_county: '',
        user_town: '',
        user_postal_code: '',
        user_street: '',
        user_is_elearning: false,
        user_elearning_data: [],
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [oldProp, setOldProp] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState((props && props.is_edit && props.props && props.props.server_id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);

    useEffect(() => {
        setFormData(InitialData)
        setCreateModalVisibility(false);
    }, [oldProp, props]);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        // if (props.callingExternal)
        props.updateParentState(false)
    }



    // const [file, setFile] = useState()

    // function handleFile(event) {
    //   setFile(event.target.files[0])

    //   let formData2 = new FormData();
    //   formData2.append('file', event.target.files[0]);
    //   formData2.append('fileName', event.target.files[0]['name']);
    //   setFormData({ ...formData, ['company_logo']: event.target.files[0] })
    // }
    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let validationHandler = () => {
        let response = { res: 1, msg: 'Validated.' };
        const re = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$&*~]).{8,16}$');
        if (formData?.server_name === undefined || formData?.server_name === '' || formData?.server_name === '')
            response = { res: 0, key: 'server_name', msg: 'Server name is required.' };
        else if (formData?.user_full_name === undefined || formData?.user_full_name === '' || formData?.user_full_name.length == 0)
            response = { res: 0, key: 'user_full_name', msg: 'Full name is required.' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.user_full_name))
        //     response = { res: 0, key: 'user_full_name', msg: 'Invalid Full name.' };
        else if (formData?.user_username === undefined || formData?.user_username === '' || formData?.user_username.length < 2)
            response = { res: 0, key: 'user_username', msg: 'Username is required & atleast 3 Charectors.' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.user_username))
        //     response = { res: 0, key: 'user_username', msg: 'Invalid Username.' };
        else if (formData?.user_email === undefined || formData?.user_email === '' || formData?.user_email.length == 0)
            response = { res: 0, key: 'user_email', msg: 'Email is required' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.user_email))
        //     response = { res: 0, key: 'user_email', msg: 'Invalid Email.' };
        else if (formData?.user_password === undefined || formData?.user_password === '' || formData?.user_password === '') {
            response = { res: 0, key: 'user_password', msg: 'Password is required.' };
        }
        else if (!re.test(formData?.user_password)) {
            response = { res: 0, key: 'user_password', msg: formData?.user_password + ' is Invalid Password.' };
        }
        return response;
    }

    let handleSave = () => {
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            console.log(formData)
            dispatch(create(formData)).then((response) => {
                if (response.data.key)
                    setErrData(response.data)
                else {
                    alert(response.data.msg);
                    window.location.href = "/SignIn"
                }
            });
        
        }
    }

    let handleSaveFranchise = () => {
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            console.log(formData)
            if (formData?.server_id == undefined || formData?.server_id == '' || formData?.server_id.length == 0)
                dispatch(createFranchise(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false)
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            last_id: '',
                            first_id: '',
                            franchise_code: ReactSession.get('franchise_code'),
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
            else
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false)
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            last_id: '',
                            first_id: '',
                            franchise_code: ReactSession.get('franchise_code'),
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
        }
    }
    // console.log("props", props.props, (props && props.props && props.props.server_id)?1:0);

    return (
        <>
            <div className="col-md-6 offset-md-3" role="document">
                <div className="card card-default" role="document">
                    <div className="card-body">
                        <div className="container-fluid text-center">
                            <h4 className="card-title">Sign Up as Franchise</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className='form-group'>
                                        <input type="hidden" name="csrf_token" value="" />
                                    </div>
                                    <div className='form-group'>
                                        <label className="form-label">Company Name</label>
                                        <input className="form-control" type="text" required="" autofocus="" name="company_name" onChange={handleChange} value={formData.company_name} placeholder="ex: Recruit-Tech" />
                                        {(errData.res == 0 && errData.key == 'company_name') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Company Email</label>
                                                <input className="form-control" type="email" name="company_email" onChange={handleChange} value={formData.company_email} placeholder="jack@jcwhite.group" required="" />
                                                {(errData.res == 0 && errData.key == 'company_email') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Company Phone Number</label>
                                                <input className="form-control" type="tel" name="phone_number" onChange={handleChange} value={formData.phone_number} placeholder="+1 0000000000" required="" />
                                                {(errData.res == 0 && errData.key == 'phone_number') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    {/* <label className="form-label">Company Logo</label>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon5">image</span>
                                        <input type="file" className="form-control" onChange={handleFile} placeholder="" name="company_logo" />
                                        {(errData.res == 0 && errData.key == 'company_logo') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                    </div> */}
                                    {/* <img id="company_logo_preview" className="d-none" src="#" alt="logo" width="200" height="200" /> */}
                                    <div className='form-group'>
                                        <label className="form-label">Website</label>
                                        <input className="form-control" type="url" name="website" onChange={handleChange} value={formData.website} placeholder="" />
                                        {(errData.res == 0 && errData.key == 'website') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                    <div className='form-group'>
                                        <label className="form-label">Opening Hours</label>
                                        <input className="form-control" type="text" name="opening_hours" onChange={handleChange} value={formData.opening_hours} placeholder="10:00 am - 6:00 pm" />
                                        {(errData.res == 0 && errData.key == 'opening_hours') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                </div>
                            </div>




                            <div className="row">
                                <div className="col">
                                    <div className='form-group'>
                                        <label className="form-label">Company Server Name</label>
                                        <input className="form-control" type="text" name="server_name" onChange={handleChange} value={formData.server_name} placeholder="architecture.care" required="" />
                                        {(errData.res == 0 && errData.key == 'server_name') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">CQC Provider ID</label>
                                                <input className="form-control" type="text" name="cqc_provider_id" onChange={handleChange} value={formData.cqc_provider_id} placeholder="" />
                                                {(errData.res == 0 && errData.key == 'cqc_provider_id') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">CQC Location ID</label>
                                                <input className="form-control" type="text" name="cqc_location_id" onChange={handleChange} value={formData.cqc_location_id} placeholder="" />
                                                {(errData.res == 0 && errData.key == 'cqc_location_id') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label className="form-label">Company Full Address</label>
                                        <textarea className="form-control" type="text" name="company_address" onChange={handleChange} value={formData.company_address} placeholder="" rows="3"></textarea>
                                        {(errData.res == 0 && errData.key == 'company_address') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">

                                    <div className='form-group'>
                                        <label className="form-label">Full Name</label>
                                        <input className="form-control" type="text" name="user_full_name" value={formData?.user_full_name} onChange={handleChange} placeholder="Full Name" required="" />
                                        {(errData.res == 0 && errData.key == 'user_full_name') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                    {props?.tempLearnerData?.user_id ? '' :
                                        <div className="row">
                                            <div className="col">
                                                <div className='form-group'>
                                                    <label className="form-label">User Name</label>
                                                    <input className="form-control" type="tel" name="user_username" placeholder="User Name" value={formData?.user_username} onChange={handleChange} required="" />
                                                    {(errData.res == 0 && errData.key == 'user_username') ?
                                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className='form-group'>
                                                    <label className="form-label">Email ID</label>
                                                    <input className="form-control" type="text" required="" autofocus="" name="user_email" value={formData?.user_email} onChange={handleChange} placeholder="Email ID" />
                                                    {(errData.res == 0 && errData.key == 'user_email') ?
                                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="row">
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Password</label>
                                                <input className="form-control" type="password" name="user_password" value={formData?.user_password} onChange={handleChange} placeholder="password" required="" />
                                                {(errData.res == 0 && errData.key == 'user_password') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Date of Birth</label>
                                                <DatePicker className='form-control' selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} />
                                                {/* <input className="form-control" type="text" name="date_of_birth" placeholder="Date of Birth" value={formData?.date_of_birth} onChange={handleChange} required="" /> */}
                                                {(errData.res == 0 && errData.key == 'user_date_of_birth') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Phone Code</label>
                                                <input className="form-control" type="text" name="user_phone_code" placeholder="Phone Code" value={formData?.user_phone_code} onChange={handleChange} required="" />
                                                {(errData.res == 0 && errData.key == 'user_phone_code') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Phone Number</label>
                                                <input className="form-control" type="tel" required="" autofocus="" name="user_phone_number" value={formData?.user_phone_number} onChange={handleChange} placeholder="Phone Number" />
                                                {(errData.res == 0 && errData.key == 'user_phone_number') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Gender</label>
                                                {/* <input className="form-control" type="text" required="" autofocus="" name="gender" value={formData?.gender} onChange={handleChange} placeholder="Gender" /> */}
                                                <select className='form-select' name='user_gender' value={formData?.user_gender} onChange={handleChange}>
                                                    <option value={''}> - Select - </option>
                                                    <option value={'Male'}>Male</option>
                                                    <option value={'Female'}>Female</option>
                                                    <option value={'Transgender'}>Transgender</option>
                                                </select>
                                                {(errData.res == 0 && errData.key == 'user_gender') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Religion</label>
                                                <input className="form-control" type="text" name="user_religion" value={formData?.user_religion} onChange={handleChange} placeholder="Religion" required="" />
                                                {(errData.res == 0 && errData.key == 'user_religion') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Country</label>
                                            <input className="form-control" type="text" name="country" placeholder="Country" value={formData?.country} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'country') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">County</label>
                                            <input className="form-control" type="text" name="county" placeholder="County" value={formData?.county} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'county') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Town</label>
                                            <input className="form-control" type="text" name="town" placeholder="Town" value={formData?.town} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'town') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Postal Code</label>
                                            <input className="form-control" type="text" name="postal_code" placeholder="Postal Code" value={formData?.postal_code} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'postal_code') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Street Address</label>
                                            <input className="form-control" type="text" name="street" placeholder="Street Address" value={formData?.street} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'street') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <button className="btn btn-light" type="button" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}>Close</button>
                            {
                                ReactSession.get("login_status") ?
                                    <button className="btn btn-primary float-right" type="button" onClick={handleSave}>Save</button>
                                    :
                                    <button className="btn btn-primary float-right" type="button" onClick={handleSaveFranchise}>Create Franchise</button>
                            }
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
