import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";

import { useParams } from 'react-router-dom';

import {
    create, update, retrieve
} from "./actions/arcService";
export default function ARCService_add(props) {
    const dispatch = useDispatch();

    // const { id } = useParams();
    const InitialData = {
        total: props.totalCount,
        type_id: props.serviceTypeId,
        service_id: '',
        name: '',
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [oldProp, setOldProp] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState((props && props.is_edit && props.props && props.props.id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);

    useEffect(() => {
        console.log("oldProp", oldProp, "props", props)
        if (props && props.is_edit && props.props && props.props.id) {
            setCreateModalVisibility(true);
            setFormData({
                type_id: props.serviceTypeId,
                total: props.props.record_data.order,
                service_id: props.props.id,
                name: props.props.record_data.name,
            })
        } else {
            setCreateModalVisibility(false);
            setFormData(InitialData)
        }
    }, [oldProp, props]);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        // if (props.callingExternal)
        props.updateParentStateService(false)
    }


    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let validationHandler = () => {
        let response = { res: 1, msg: 'Validated.' };
        if (formData?.name === undefined || formData?.name === '' || formData?.name === '')
            response = { res: 0, key: 'name', msg: 'Service name is required.' };
        return response;
    }

    let handleSave = () => {
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            if (formData?.service_id == undefined || formData?.service_id == '' || formData?.service_id.length == 0)
                dispatch(create(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentStateService(false)
                        setCreateModalVisibility(false);
                        let data = {
                            type_id: props.serviceTypeId,
                            limit: 10,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
            else
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentStateService(false)
                        setCreateModalVisibility(false);
                        let data = {
                            type_id: props.serviceTypeId,
                            limit: 10,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
        }
    }
    // console.log("props", props.props, (props && props.props && props.props.id)?1:0);


    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="card-header">
                        <h4 className="card-title">{(props && props.is_edit) ? 'Update' : 'Add'} Service</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" onClick={handleCreateModalVisibility}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className='form-group'>
                                <label>Service Type</label>
                                <input type='text' className='form-control' placeholder='Enter Service Name ' name='name' value={formData?.name} onChange={handleChange} />
                                {(errData.res == 0 && errData.key == 'name') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                    : ''}
                            </div>
                        </form>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-light" type="button" data-bs-dismiss="modal" onClick={handleCreateModalVisibility}>Close</button>
                        <button className="btn btn-primary" type="button" onClick={handleSave}>{(props && props.is_edit) ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
