import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import {
    create, update, retrieve
} from "./actions/InactiveSharableLinks";
export default function InactiveSharableLinks_add(props) {
    const dispatch = useDispatch();
    const InitialData = {
        support_ticket_id: '',
        support_ticket_name: '',
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [oldProp, setOldProp] = useState({});
    const [formData, setFormData] = useState((props && props.props && props.props.support_ticket_id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);

    useEffect(() => {
        // console.log("oldProp", oldProp, "props", props)
        if (props && props.props && props.props.support_ticket_id && (oldProp && oldProp.props && oldProp.props.support_ticket_id) !== (props && props.props && props.props.support_ticket_id)) {
            setCreateModalVisibility(true);
            setFormData({
                support_ticket_id: props.props.support_ticket_id,
                support_ticket_name: props.props.record_data.name,
            })
        } else {
            setCreateModalVisibility(false);
        }
    }, [oldProp, props]);


    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
    }
    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    let validationHandler = () => {
        let response = { res: 1, msg: 'Validated.' };
        if (formData.support_ticket_name === undefined || formData.support_ticket_name === '' || formData.support_ticket_name === '')
            response = { res: 0, key: 'support_ticket_name', msg: 'Support ticket name is required.' };
        return response;
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let handleSave = () => {
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            if (formData.support_ticket_id == undefined || formData.support_ticket_id == '' || formData.support_ticket_id.length == 0)
                dispatch(create(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            last_support_ticket_id: '',
                            first_support_ticket_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
            else
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            last_support_ticket_id: '',
                            first_support_ticket_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
        }
    }
    // console.log("props", props.props, (props && props.props && props.props.support_ticket_id)?1:0);


    return (
        <>
            {(props && props.is_edit) ? ''
                :
                <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                    <i className="fas fa-plus fa-sm"></i>&nbsp;Add New Support Ticket
                </a>
            }

            <div className={createModalVisibility ? "modal fade show" : "modal fade"} role="dialog" tabIndex="-1" id="modal-1">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{(props && props.is_edit) ? 'Update' : 'Add'} Support Ticket</h4>
                            <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" onClick={handleCreateModalVisibility}></button>
                        </div>
                        <div className="modal-body">
                            {/* <p>The content of your modal.</p> */}
                            <form>
                                <div className='form-group'>
                                    <label>Support Ticket Name</label>
                                    <input type='text' className='form-control' placeholder='Enter Support Ticket ' name='support_ticket_name' value={formData.support_ticket_name} onChange={handleChange} />
                                    {(errData.res == 0 && errData.key == 'support_ticket_name') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-light" type="button" data-bs-dismiss="modal" onClick={handleCreateModalVisibility}>Close</button>
                            <button className="btn btn-primary" type="button" onClick={handleSave}>{(props && props.is_edit) ? 'Update' : 'Save'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
