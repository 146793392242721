import http from "../../../../http-axios.function";


// const create = (data) => {
//     return http.post(`/super_admin/architecture/servers/create`, {
//         headers: {
//             'Content-Type': 'multipart/form-data;charset=UTF-8',
//             "Access-Control-Allow-Origin": "*",
//         }, data
//     });
// };

const create = (data) => {
    return http.post(`/super_admin/architecture/servers/create_by_franchise`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};

const createFranchise = (data) => {
    return http.post(`/super_admin/architecture/servers/create_franchise_request`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


// const fetchList = (data) => {
//     return http.post(`/super_admin/architecture/servers/fetch`, {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             "Access-Control-Allow-Origin": "*",
//         }, data
//     });
// };


const fetchList = (data) => {
    return http.post(`/super_admin/architecture/servers/fetch_franchise_wise`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchServerList = (data) => {
    return http.get(`/fetch_servers`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchSingle = (data) => {
    return http.post(`/super_admin/architecture/servers/fetch_single`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const update = (data) => {
    return http.post(`/super_admin/architecture/servers/update`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


// const changeStatus = (data) => {
//     return http.post(`/super_admin/architecture/servers/changestatus`, {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             "Access-Control-Allow-Origin": "*",
//         }, data
//     });
// };


const trash = (data) => {
    return http.post(`/super_admin/architecture/servers/delete`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};



const Servers = {
    fetchList,
    fetchServerList,
    fetchSingle,
    create,
    createFranchise,
    update,
    // changeStatus,
    trash,
};
export default Servers;