import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { NavLink, useParams } from 'react-router-dom';

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";

import { retrieveServiceUser } from '../architectureServers/actions/arcServerServiceUsers';

export default function ArchitectureServerVisits_add() {
    const { server_id } = useParams();
    let InitialData = {
        adminID: 'superadmin',
        assignedActivities: [],
        cancelled: false,
        date: new Date(),
        datetime: new Date(),
        documentURL: [],
        phoneNumber: '',
        priorityTasks: [],
        staffIDs: [],
        status: 'pending',
        taskCompleted: 0,
        taskLocation: { Longitude: '', Latitude: '' },
        timeRange: { from: '10:00', to: '10:00' },
        totalTasks: 0,
        userID: '',
        visitTime: 0,
        country: '',
        county: '',
        town: '',
        postalCode: '',
        street: '',
        firstLineOfAddress: '',
        secondLineOfAddress: '',
    }
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };

    const [errorData, setErrorData] = useState(InitialErrData);
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState(InitialData);
    const [date, setDate] = useState(InitialData.date);
    const [startTimeValue, setStartTimeValue] = useState(InitialData.timeRange.from);
    const [endTimeValue, setEndTimeValue] = useState(InitialData.timeRange.to);
    const [serviceUserData, setServiceUserData] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        let data = {
            server_id: server_id,
            limit: 1000,
            last_id: '',
            first_id: '',
        };
        dispatch(retrieveServiceUser(data)).then((response) => {
            setServiceUserData(response);
        });
    }, []);



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    const onChange = (key, timeValue) => {
        if (key == 'start_time')
            setStartTimeValue(timeValue.time);
        if (key == 'end_time')
            setEndTimeValue(timeValue.time);
    }


    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }





    let checkValidation = (name, value) => {
        let errorDataTemp = { name: name, status: 0, message: ' Invalid Data. ' };
        let errorstatus = 0;
        setErrorData({});
        if (value != '' && name == 'userID' && !isAlphaNumeric(value)) {
            setErrorData(errorDataTemp);
            errorstatus = 1;
        } else if (value == '' && name == 'date') {
            setErrorData(errorDataTemp);
            errorstatus = 1;
        } else if (value == '' && name == 'start_time') {
            setErrorData(errorDataTemp);
            errorstatus = 1;
        } else if (value == '' && name == 'end_time') {
            setErrorData(errorDataTemp);
            errorstatus = 1;
        } else if (value != '' && name == 'phoneNumber' && !isNumeric(value)) {
            setErrorData(errorDataTemp);
            errorstatus = 1;
        } else { }
        return errorstatus;
    }
    // console.log("visibility", props.visibility, createModalVisibility);
    // console.log(serviceUserData.service_users);
    return (
        <>
            <div className="card card-default" role="document">
                <div className="card-body">
                    <div className="card-header">
                        <h4 className="card-title">Add Visits</h4>
                    </div>
                    <div className="card-body">
                        {(step == 1) ?
                        <div className="row">
                            <div className="col-6">
                                <div className="row">
                                    <div className='container-fluid'>
                                        <label className="form-label">Choose Activities</label>
                                        <div className="row" style={{ height: '300px', overflowY: 'scroll' }}>
                                            <div className="card mt-1 mb-1">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-2">
                                                            <i className='fa fa-check'></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>Activity Name</span></div>
                                                            <div className="text-ash mb-0 Poppins"><span>Test</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card mt-1 mb-1">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-2">
                                                            <i className='fa fa-check'></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>Activity Name</span></div>
                                                            <div className="text-ash mb-0 Poppins"><span>Test</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card mt-1 mb-1">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-2">
                                                            <i className='fa fa-check'></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>Activity Name</span></div>
                                                            <div className="text-ash mb-0 Poppins"><span>Test</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card mt-1 mb-1">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-2">
                                                            <i className='fa fa-check'></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>Activity Name</span></div>
                                                            <div className="text-ash mb-0 Poppins"><span>Test</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className='form-group'>
                                        <label className="form-label">Service User</label>
                                        <select className="form-select" type="text" required="" autofocus="" name="userID" placeholder="Service User">
                                            <option value={''}>Select</option>
                                            {serviceUserData && serviceUserData.service_users && serviceUserData.service_users.length > 0 ?
                                                serviceUserData.service_users.map((val, index) => {
                                                    return (
                                                        <option value={val.username}>{val.full_name}</option>
                                                    )
                                                })
                                                : ''
                                            }
                                        </select>
                                        {(errorData?.res == 0 && errorData?.key == 'date') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errorData?.msg}</small>
                                            : ''}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Date</label>
                                            <DatePicker className='form-control' selected={date} dateFormat="dd/MM/yyyy" onChange={(date) => setDate(date)} />
                                            {/* {(errorData?.res == 0 && errorData?.key == 'date') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errorData?.msg}</small>
                                    : ''} */}
                                            {/* <input className="form-control" type="text" name="date" placeholder="Date" required="" /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Start Time</label>
                                            <input type='time' className='form-control' step="1" placeholder="Start Time" onChange={(ev) => { onChange('start_time', { time: ev.target.value }) }} value={startTimeValue} />
                                            {/* {(errorData?.res == 0 && errorData?.key == 'time') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errorData?.msg}</small>
                                    : ''} */}
                                            {/* <input className="form-control" type="text" name="start_time" placeholder="Start Time" required="" /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">End Time</label>
                                            <input type='time' className='form-control' step="1" placeholder="End Time" onChange={(ev) => { onChange('end_time', { time: ev.target.value }) }} value={endTimeValue} />
                                            {/* {(errorData?.res == 0 && errorData?.key == 'time') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errorData?.msg}</small>
                                    : ''} */}
                                            {/* <input className="form-control" type="text" name="end_time" placeholder="End Time" required="" /> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">Phone Number</label>
                                    <input className="form-control" type="tel" name="phoneNumber" placeholder="Phone Number" required="" />
                                </div>
                            </div>
                        </div> */}
                            </div>
                            {/* <div className="col-8">
                        <div className="row">
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">Country</label>
                                    <input className="form-control" name="country" placeholder="Country" />
                                </div>
                            </div>
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">County</label>
                                    <input className="form-control" name="county" placeholder="County" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">Town</label>
                                    <input className="form-control" name="town" placeholder="Town" />
                                </div>
                            </div>
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">Postal Code</label>
                                    <input className="form-control" name="postalCode" placeholder="Postal Code" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">Street</label>
                                    <input className="form-control" name="street" placeholder="Street" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">First Line of Address</label>
                                    <input className="form-control" name="firstLineOfAddress" placeholder="First Line of Address" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">Second Line of Address</label>
                                    <input className="form-control" type="text" name="secondLineOfAddress" placeholder="Second Line of Address" required="" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                        </div>
                        : (step == 2) ?

                        <div className="row">
                            <div className="col-6">
                                <div className="row">
                                    <div className='container-fluid'>
                                        <label className="form-label">Choose Priority Task</label>
                                        <div className="row" style={{ height: '300px', overflowY: 'scroll' }}>
                                            <div className="card mt-1 mb-1">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-2">
                                                            <i className='fa fa-check'></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>Activity Name</span></div>
                                                            <div className="text-ash mb-0 Poppins"><span>Test</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card mt-1 mb-1">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-2">
                                                            <i className='fa fa-check'></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>Activity Name</span></div>
                                                            <div className="text-ash mb-0 Poppins"><span>Test</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card mt-1 mb-1">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-2">
                                                            <i className='fa fa-check'></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>Activity Name</span></div>
                                                            <div className="text-ash mb-0 Poppins"><span>Test</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card mt-1 mb-1">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-2">
                                                            <i className='fa fa-check'></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>Activity Name</span></div>
                                                            <div className="text-ash mb-0 Poppins"><span>Test</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Upload Files</label>
                                            <input type='file' multiple className='form-control' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : (step == 3) ?

                        <div className="row">
                            <div className="col-6">
                                <div className="row">
                                    <div className='container-fluid'>
                                        <label className="form-label">Choose Staff</label>
                                        <div className="row" style={{ height: '300px', overflowY: 'scroll' }}>
                                            <div className="card mt-1 mb-1">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-2">
                                                            <i className='fa fa-check'></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>Activity Name</span></div>
                                                            <div className="text-ash mb-0 Poppins"><span>Test</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card mt-1 mb-1">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-2">
                                                            <i className='fa fa-check'></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>Activity Name</span></div>
                                                            <div className="text-ash mb-0 Poppins"><span>Test</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card mt-1 mb-1">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-2">
                                                            <i className='fa fa-check'></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>Activity Name</span></div>
                                                            <div className="text-ash mb-0 Poppins"><span>Test</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card mt-1 mb-1">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-2">
                                                            <i className='fa fa-check'></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>Activity Name</span></div>
                                                            <div className="text-ash mb-0 Poppins"><span>Test</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''
                        }
                    </div>
                    <div className="card-footer">
                        {(step > 1) ?
                            <button className="btn btn-primary" type="button">Previous</button>
                            : ''}
                        <div>
                            <button className="btn btn-primary" type="button" onClick={() => setStep(step + 1)}>{(step == 3) ? 'Save' : 'Next'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
