import {
    // FILE_UPLOAD,
    CREATE_MEDICINE_COMPOSITIONS,
    RETRIEVE_MEDICINE_COMPOSITIONS,
    RETRIEVESINGLE_MEDICINE_COMPOSITIONS,
    UPDATE_MEDICINE_COMPOSITIONS,
    DELETE_MEDICINE_COMPOSITIONS,
} from "./type";
import MedicineCompositions from "../service/MedicineCompositions";
export const create = (data) => async (dispatch) => {
    try {
        const res = await MedicineCompositions.create(data);
        dispatch({
            type: CREATE_MEDICINE_COMPOSITIONS,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await MedicineCompositions.fetchList(data);
        dispatch({
            type: RETRIEVE_MEDICINE_COMPOSITIONS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await MedicineCompositions.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_MEDICINE_COMPOSITIONS,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await MedicineCompositions.update(data);
        dispatch({
            type: UPDATE_MEDICINE_COMPOSITIONS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await MedicineCompositions.changeStatus(data);
//         dispatch({
//             type: UPDATE_MEDICINE_COMPOSITIONS,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await MedicineCompositions.trash(data);
        dispatch({
            type: DELETE_MEDICINE_COMPOSITIONS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};