import {
    // FILE_UPLOAD,
    CREATE_VISITMANAGEMENT,
    RETRIEVE_VISITMANAGEMENT,
    RETRIEVESINGLE_VISITMANAGEMENT,
    UPDATE_VISITMANAGEMENT,
    DELETE_VISITMANAGEMENT,
} from "./type";
import VisitManagement from "../service/VisitManagement";
export const create = (data) => async (dispatch) => {
    try {
        const res = await VisitManagement.create(data);
        dispatch({
            type: CREATE_VISITMANAGEMENT,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await VisitManagement.fetchList(data);
        // dispatch({
        //     type: RETRIEVE_VISITMANAGEMENT,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveServiceUserStats = (data) => async (dispatch) => {
    try {
        const res = await VisitManagement.fetchServiceUserStatsList(data);
        // dispatch({
        //     type: RETRIEVE_VISITMANAGEMENT,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveEmployeeStats = (data) => async (dispatch) => {
    try {
        const res = await VisitManagement.fetchEmployeeStatsList(data);
        // dispatch({
        //     type: RETRIEVE_VISITMANAGEMENT,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await VisitManagement.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_VISITMANAGEMENT,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await VisitManagement.update(data);
        dispatch({
            type: UPDATE_VISITMANAGEMENT,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await VisitManagement.changeStatus(data);
//         dispatch({
//             type: UPDATE_VISITMANAGEMENT,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await VisitManagement.trash(data);
        dispatch({
            type: DELETE_VISITMANAGEMENT,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};