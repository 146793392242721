import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ReactSession from '../../components/ReactSession';
// import { ReactSession } from 'react-client-session';

import Logo from '../../assets/wt-logo.png';

import {
  makeLogin,
  findUser,
  getEmailOtp,
  getVerifyEmailOtp,
  genarateValidToken,
} from './actions/SignIn';

// import { initializeApp } from 'firebase/app';
import getFirebase from '../../Firebase';
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  getRedirectResult,
  signInWithRedirect,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPhoneNumber,
  signInWithCredential,
  RecaptchaVerifier,
  OAuthProvider,
} from 'firebase/auth';
import { NavLink } from 'react-router-dom';

export default function SignIn() {
  const InitialData = {
    server_name: 'admin.onboarding.test',
    server_id: '',
    user_type: '',
    username: '',
    password: '',
    phone: '',
    otp: '',
  };
  const InitialUserData = {
    uid: '',
    displayName: '',
    email: '',
    phoneNumber: '',
  };
  const InitialEmailData = {
    server_id: '',
    user_type: '',
    email: '',
  };
  const InitialOTPData = {
    email: '',
    otp: '',
  };

  const [loginData, setLoginData] = useState(InitialData);
  const [emailOtp, setEmailOtp] = useState(InitialEmailData);
  const [verifyEmailOtp, setVerifyEmailOtp] = useState(InitialOTPData);
  const [userData, setUserData] = useState(InitialUserData);
  const [login_type_phone, setLoginTypePhone] = useState(false);
  const [login_type_email, setLoginTypeEmail] = useState(true);
  const [login_type_otp, setLoginTypeOTP] = useState(false);
  const [error_status, setErrorStatus] = useState(false);
  const [error_massage, setErrorMassage] = useState('');
  const [msg_status, setMsgStatus] = useState(false);
  const [otp_status, setOtpStatus] = useState('');
  const [login_status, setLoginStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otpType, setOtpType] = useState('email');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  const dispatch = useDispatch();

  // const app = initializeApp(getFirebase);
  const auth = getFirebase;
  // auth.languageCode = 'en';

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: response => {
        console.log(response);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': err => {
        console.log(err);
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      },
    });
  }, []);
  ReactSession.setStoreType('localStorage');

  const setFirebaseErrorMassage = code => {
    switch (code) {
      case 'auth/invalid-email':
        setErrorMassage('User name is not valid!');
        break;
      case 'auth/user-disabled':
        setErrorMassage('User has been disabled!');
        break;
      case 'auth/user-not-found':
        setErrorMassage('User not found!');
        break;
      case 'auth/wrong-password':
        setErrorMassage('Password invalid!');
        break;
      case 'auth/too-many-requests':
        setErrorMassage('To many attempts try again later!');
        break;
      case 500:
        setErrorMassage('An error occurred please try again!');
        break;
      case 'status/failed':
        setErrorMassage('OTP is incorrect!');
        break;
      case 'try/again':
        setErrorMassage('Please Try Again!');
        break;
      default:
        setErrorMassage('An Error has occurred please try again!');
    }
  };
  // let handleLoginType = e => {
  //   setLoginTypePhone(!login_type_phone);
  //   setLoginTypeEmail(!login_type_email);
  // };
  const handleOTPTypeChange = e => {
    setOtpType(e.target.value);
    setLoginTypeOTP(false);
    setLoginTypeEmail(true);
  };
  let handleChange = event => {
    setErrorStatus(false);
    setMsgStatus(false);
    let { name, value } = event.target; 
    // console.log('name - ', name, value);
    if (name === 'username' || name === 'server_name') {
      setLoginTypeEmail(true);
      setLoginTypePhone(false);
      setLoginTypeOTP(false);
    }
    if (name === 'server_name') {
      let server_id = value.split('.');
      server_id.shift();
      setLoginData({
        username: loginData.username,
        password: loginData.password,
        phone: loginData.phone,
        user_type: value.split('.')[0],
        server_id: server_id.join('.'),
        [name]: value,
      });
    } else if (name === 'otp') {
      setVerifyEmailOtp({
        email: emailOtp.email,
        otp: value,
      });
    } else {
      setLoginData({ ...loginData, [name]: value });
    }
  };

  const verifyEmail = async (auth, requestData) => {
    dispatch(getEmailOtp(requestData))
      .then(response => {
        setMsgStatus(true);
        setOtpStatus('An OTP sent to your linked Email');
        setLoginTypeOTP(true);
        setLoginTypeEmail(false);
      })
      .catch(error => {
        setErrorStatus(true);
        if (error.response.status === 500) setFirebaseErrorMassage(error.response.status);
        else setFirebaseErrorMassage('try/again');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const verifyPhoneNumber = async (auth, phone) => {
    const appVerifier = window.recaptchaVerifier;
    const phoneNumber = phone;
    return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then(response => {
        window.confirmationResult = response;
        setMsgStatus(true);
        setOtpStatus('An OTP sent to your Phone Number');
        setLoginTypePhone(false);
        setLoginTypeEmail(false);
        setLoginTypeOTP(true);
      })
      .catch(error => {
        setErrorStatus(true);
        setFirebaseErrorMassage(error.code);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const signInWithVerificationCode = code => {
    if (otpType === 'email') {
      dispatch(getVerifyEmailOtp(verifyEmailOtp))
        .then(response => {
          if (response.status === 'Success') OtpResponse(response);
          else {
            setErrorStatus(true);
            setFirebaseErrorMassage('status/failed');
          }
        })
        .catch(error => {
          alert(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
    if (otpType === 'phone') {
      window.confirmationResult
        .confirm(verifyEmailOtp.otp)
        .then(result => {
          OtpResponse(result);
        })
        .catch(error => {
          setErrorStatus(true);
          setFirebaseErrorMassage('status/failed');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
    const OtpResponse = res => {
      // const temp = `uid=${userData.uid}&user_type=${loginData.user_type}`;
      // const temp2 = { uid: userData.uid, user_type: loginData.user_type };
      dispatch(genarateValidToken({ uid: userData.uid, user_type: loginData.user_type }))
        .then(response => {
          ReactSession.set('login_status', 1);
          ReactSession.set('uid', userData.uid);
          ReactSession.set('email', userData.email);
          ReactSession.set('username', userData.displayName);
          ReactSession.set('phoneNumber', userData.phoneNumber);
          ReactSession.set('validate_token', response.token);
          ReactSession.set('server_name', loginData.server_name);
          ReactSession.set('server_id', loginData.server_id);
          ReactSession.set('user_type', loginData.user_type);
          window.location.reload();
        })
        .catch(error => {
          setErrorStatus(true);
          console.log(error);
          if (error?.response?.status === 500) setFirebaseErrorMassage(error.response.status);
          else setFirebaseErrorMassage('try/again');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    };
    // const phoneCredential = auth.PhoneAuthProvider.credential(id, code);
    // console.log(phoneCredential)
    // return signInWithCredential(auth, phoneCredential).then(response => {
    //   console.log('Login');
    // });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);
  const resendOTP = otpData => {
    setMinutes(1);
    setSeconds(30);
    if (otpType === 'email') {
      verifyEmail(auth, otpData);
    }
    if (otpType === 'phone') {
      verifyPhoneNumber(auth, loginData.phone);
    }
  };
  let handleLogin = e => {
    e.preventDefault();
    setErrorStatus(false);
    setMsgStatus(false);
    if (loginData.server_name === '') {
      setErrorStatus(true);
      setErrorMassage('Server name is empty!');
      return;
    } else if (loginData.server_name === 'admin.onboarding.test') {
      setErrorStatus(true);
      return setErrorMassage('Server name not exsist!');
    } else if (loginData.server_id === '') {
      setErrorStatus(true);
      return setErrorMassage('Server name is not valid!');
    } else if (loginData.user_type !== 'admin') {
      setErrorStatus(true);
      return setErrorMassage('Account not found!');
    } else if (loginData.username === '') {
      setErrorStatus(true);
      return setErrorMassage('Please enter your Username!');
    } else if (loginData.password === '') {
      setErrorStatus(true);
      return setErrorMassage('Please enter a valid Password!');
    }
    setIsSubmitting(true);
    // For Dynamic
    // console.log(loginData);
    // if (login_type_phone) {
    //   verifyPhoneNumber(auth, loginData.phone);
    // }
    if (login_type_otp) {
      return signInWithVerificationCode();
    }
    // if (login_type_email) {
    dispatch(
      findUser({
        server_id: loginData.server_id,
        user_type: loginData.user_type,
        username: loginData.username,
      })
    )
      .then(response => {
        // console.log(response);
        if (response.email === '') {
          setErrorStatus(true);
          return setErrorMassage('User not found!');
        } else {
          ReactSession.set('franchise_code', response.franchise_code);
          signInWithEmailAndPassword(auth, response.email, loginData.password)
            .then(userCredential => {
              const user = userCredential.user;
              setUserData({
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                phoneNumber: user.phoneNumber,
              });
              setLoginData({
                server_name: loginData.server_name,
                server_id: loginData.server_id,
                user_type: loginData.user_type,
                username: loginData.username,
                password: loginData.password,
                phone: user.phoneNumber,
                otp: loginData.otp,
              });
              setEmailOtp({
                server_id: loginData.server_id,
                user_type: loginData.user_type,
                email: response.email,
              });
              if (otpType === 'email') {
                resendOTP({
                  server_id: loginData.server_id,
                  user_type: loginData.user_type,
                  email: response.email,
                });
                // verifyEmail(auth, );
              }
              if (otpType === 'phone') {
                verifyPhoneNumber(auth, user.phoneNumber);
              }
            })
            .catch(error => {
              setErrorStatus(true);
              setFirebaseErrorMassage(error.code);
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        }
      })
      .catch(error => {
        setErrorStatus(true);
        setFirebaseErrorMassage(error.code);
      }).finally(()=>{
        setIsSubmitting(false)
      })
  };

  return (
    <>
      {/* <div className="col-lg-6 d-none d-lg-flex h-100vh">
                <div className="flex-grow-1 bg-login-image"></div>
            </div> */}
      <div className="offset-lg-4 col-lg-4 offset-md-3 col-md-6 offset-sm-3 col-sm-6 col-xs-12 card mt-5">
        <div className="card-body p-5">
          <div className="text-center">
            <img src={Logo} height={'40px'} alt="ima" />
            <h4 className="text-dark mt-4 mb-4">
              {loginData.username.length >= 3 ? 'Welcome ' + loginData.username + '!' : 'Hey Hi !'}
            </h4>
          </div>
          <form className="user" autoComplete="off" onSubmit={handleLogin}>
            <div className="mb-3 border-bottom pb-2">
              <input
                className="form-control form-control-user"
                type="text"
                id="server_name"
                onChange={handleChange}
                value={loginData.server_name}
                aria-describedby="serverName"
                name="server_name"
                placeholder="admin.onboarding.test"
              />
            </div>
            <div className="mb-3">
              <input
                className="form-control form-control-user"
                type="text"
                id="username"
                onChange={handleChange}
                value={loginData.username}
                aria-describedby="emailHelp"
                placeholder="Enter Username"
                name="username"
              />
            </div>
            <div className="mb-3">
              <input
                className="form-control form-control-user"
                type="password"
                id="password"
                onChange={handleChange}
                value={loginData.password}
                placeholder="Password"
                name="password"
              />
            </div>
            {/* {login_type_phone || login_type_otp ? (
              <>
                <div className="mb-3">
                  <input
                    className="form-control form-control-user"
                    type="text"
                    id="phone"
                    onChange={handleChange}
                    value={loginData.phone}
                    aria-describedby="phoneHelp"
                    placeholder="Enter Phone"
                    name="phone"
                  />
                </div>
              </>
            ) : (
              ''
            )} */}
            <div className="mb-3 p-2">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="email"
                  checked={otpType === 'email'}
                  onChange={handleOTPTypeChange}
                />
                <label className="form-check-label" htmlFor="exampleRadios1">
                  Send OTP to Email
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  value="phone"
                  checked={otpType === 'phone'}
                  onChange={handleOTPTypeChange}
                />
                <label className="form-check-label" htmlFor="exampleRadios2">
                  Send OTP to Phone Number
                </label>
              </div>
            </div>
            {login_type_otp ? (
              <div className="mb-3">
                <input
                  className="form-control form-control-user"
                  type="text"
                  id="otp"
                  onChange={handleChange}
                  value={verifyEmailOtp.otp}
                  aria-describedby="otpHelp"
                  placeholder="Enter OTP"
                  name="otp"
                />
              </div>
            ) : (
              ''
            )}
            <button
              className="btn btn-primary d-block btn-user w-100"
              type="submit"
              disabled={isSubmitting}
            >
              {login_type_email ? 'Log In' : 'Verify OTP'}
            </button>
            {!login_type_email ? (
              <div className="col-md-12 mt-2">
                <div className="d-flex justify-content-between">
                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p>Didn't recieve code?</p>
                  )}

                  <button
                    disabled={seconds > 0 || minutes > 0}
                    style={{
                      color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
                    }}
                    className="btn border-0"
                    onClick={() => resendOTP(emailOtp)}
                  >
                    Resend OTP
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}

            <div id="recaptcha-container" className="justify-center flex"></div>
            {/* <button className="btn btn-primary d-block btn-facebook btn-user w-100" onClick={googleLogin}>
                            <i className="fab fa-facebook-f"></i>
                            &nbsp; Login with Facebook
                        </button> */}
            <hr />
            <div className="col-md-12  text-center">
              <label className="fg-theme fst-italic">{error_status ? error_massage : ''}</label>
              <label className="text-success fst-italic">{msg_status ? otp_status : ''}</label>
            </div>
          </form>
          {/* <div className="text-center"><a className="small" href="forgot-password.html">Forgot Password?</a></div> */}
          {/* <div className="text-center"><a className="small" href="register.html">Create an Account!</a></div> */}
        </div>

        <div className='container-fluid text-center mt-1 mb-2'>
          <NavLink to={'/SignUp'}><h5 className='fg-theme'>Sign Up for Franchise</h5></NavLink>
        </div>
      </div>
    </>
  );
}
