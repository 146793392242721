import {
    // FILE_UPLOAD,
    CREATE_ARCHITECTUREATTORNEYCLIENTS,
    RETRIEVE_ARCHITECTUREATTORNEYCLIENTS,
    RETRIEVESINGLE_ARCHITECTUREATTORNEYCLIENTS,
    UPDATE_ARCHITECTUREATTORNEYCLIENTS,
    DELETE_ARCHITECTUREATTORNEYCLIENTS,
} from "./type";
import ARCAttorneyClients from "../service/arcAttorneyClients";
// export const fileUpload = (data) => async (dispatch) => {
//   try {
//     const res = await ARCAttorneyClients.fileUpload(data);
//     dispatch({
//       type: FILE_UPLOAD,
//       payload: res.data.data,
//     });
//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
export const create = (data) => async (dispatch) => {
    try {
        const res = await ARCAttorneyClients.create(data);
        dispatch({
            type: CREATE_ARCHITECTUREATTORNEYCLIENTS,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await ARCAttorneyClients.fetchList(data);
        dispatch({
            type: RETRIEVE_ARCHITECTUREATTORNEYCLIENTS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await ARCAttorneyClients.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_ARCHITECTUREATTORNEYCLIENTS,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await ARCAttorneyClients.update(data);
        dispatch({
            type: UPDATE_ARCHITECTUREATTORNEYCLIENTS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await ARCAttorneyClients.changeStatus(data);
//         dispatch({
//             type: UPDATE_ARCHITECTUREATTORNEYCLIENTS,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await ARCAttorneyClients.trash(data);
        dispatch({
            type: DELETE_ARCHITECTUREATTORNEYCLIENTS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};