import http from "../../../../http-axios.function";


const create = (data) => {
    return http.post(`/super_admin/architecture/servers/create`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchList = (data) => {
    return http.post(`/super_admin/architecture/servers/fetch_single`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const metricsEmployeeStarterLeaver = (data) => {
    // return http.post(`/super_admin/architecture/servers/employees_starters_leavers`, {
    return http.post(`/super_admin/architecture/employees/starters_leavers`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const metricsEmployeeVisitStatistics = (data) => {
    // return http.post(`/super_admin/architecture/servers/employees_starters_leavers`, {
    return http.post(`/super_admin/architecture/servers/visit_statistics_percentage`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const metricsEmployeeVisitSummery = (data) => {
    // return http.post(`/super_admin/architecture/servers/employees_starters_leavers`, {
    return http.post(`/super_admin/architecture/servers/visit_summary`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchSingle = (data) => {
    return http.post(`/super_admin/architecture/servers/fetch_single`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const update = (data) => {
    return http.post(`/super_admin/architecture/servers/update`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


// const changeStatus = (data) => {
//     return http.post(`/super_admin/architecture/servers/changestatus`, {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             "Access-Control-Allow-Origin": "*",
//         }, data
//     });
// };


const trash = (data) => {
    return http.post(`/super_admin/architecture/servers/delete`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};



const ServerDetails = {
    metricsEmployeeVisitStatistics,
    metricsEmployeeVisitSummery,
    metricsEmployeeStarterLeaver,
    fetchList,
    fetchSingle,
    create,
    update,
    // changeStatus,
    trash,
};
export default ServerDetails;