import React from 'react'

export default function ServiceUserForm() {
    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='card-header align-items-center no-gutters'>
                                <h5>Add Service User</h5>
                                <i className={"fas fa-times h6 text-gray-300"}></i>
                            </div>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <form className='form-horizontal p-0'>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Server</label>
                                            <input className='form-control' placeholder='user.shaderbytes.com' type='text' />
                                        </div>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Email</label>
                                            <input className='form-control' placeholder='name@domain.com' type='text' />
                                        </div>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Full Name</label>
                                            <input className='form-control' placeholder='Jhon Doe' type='text' />
                                        </div>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Phone Number</label>
                                            <input className='form-control' placeholder='Valid Phone Number' type='text' />
                                        </div>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>User Name</label>
                                            <input className='form-control' placeholder='jhondoe' type='text' />
                                        </div>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Pin</label>
                                            <input className='form-control' placeholder='*******' type='password' />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='card-footer d-block'>
                                <div className='row'>
                                    <div className='d-flex align-items-center content-space-between'>
                                        <button className='btn btn-default border-ash-1'>Close</button>
                                        <button className='btn btn-primary'>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
