import {
    CREATE_ARCHITECTURETYPEOFSERVICE,
    RETRIEVE_ARCHITECTURETYPEOFSERVICE,
    RETRIEVESINGLE_ARCHITECTURETYPEOFSERVICE,
    UPDATE_ARCHITECTURETYPEOFSERVICE,
    DELETE_ARCHITECTURETYPEOFSERVICE,
  } from "../actions/type";
  const initialState = [];
  function ARCTypeOfServiceReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTURETYPEOFSERVICE:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTURETYPEOFSERVICE:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTURETYPEOFSERVICE:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTURETYPEOFSERVICE:
        return {data: state.data, payload};
      case DELETE_ARCHITECTURETYPEOFSERVICE:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCTypeOfServiceReducer};