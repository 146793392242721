import {
    // FILE_UPLOAD,
    CREATE_FRANCHISE_ARCHITECTURESERVERADMIN,
    CREATE_ARCHITECTURESERVERADMIN,
    RETRIEVE_ARCHITECTURESERVERADMIN,
    RETRIEVESINGLE_ARCHITECTURESERVERADMIN,
    UPDATE_ARCHITECTURESERVERADMIN,
    DELETE_ARCHITECTURESERVERADMIN,
    RETRIEVE_ARCHITECTURESERVERSTAFF,
    RETRIEVE_ARCHITECTURESERVERSERVICEUSERS,
} from "./type";
import ARCServerAdmin from "../service/arcServerAdmin";
// export const fileUpload = (data) => async (dispatch) => {
//   try {
//     const res = await ARCServerAdmin.fileUpload(data);
//     dispatch({
//       type: FILE_UPLOAD,
//       payload: res.data.data,
//     });
//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
export const create = (data) => async (dispatch) => {
    try {
        const res = await ARCServerAdmin.create(data);
        dispatch({
            type: CREATE_ARCHITECTURESERVERADMIN,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await ARCServerAdmin.fetchList(data);
        dispatch({
            type: RETRIEVE_ARCHITECTURESERVERADMIN,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await ARCServerAdmin.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_ARCHITECTURESERVERADMIN,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await ARCServerAdmin.update(data);
        dispatch({
            type: UPDATE_ARCHITECTURESERVERADMIN,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const updateEmail = (data) => async (dispatch) => {
    try {
        const res = await ARCServerAdmin.updateEmail(data);
        // dispatch({
        //     type: UPDATE_ARCHITECTURESERVERADMIN,
        //     status: '',
        //     response: {},
        //     payload: res.data,
        // });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const updatePhone = (data) => async (dispatch) => {
    try {
        const res = await ARCServerAdmin.updatePhoneNumber(data);
        // dispatch({
        //     type: UPDATE_ARCHITECTURESERVERADMIN,
        //     status: '',
        //     response: {},
        //     payload: res.data,
        // });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const changeStatus = (data) => async (dispatch) => {
    try {
        const res = await ARCServerAdmin.changeStatus(data);
        dispatch({
            type: UPDATE_ARCHITECTURESERVERADMIN,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const trash = (data) => async (dispatch) => {
    try {
        const res = await ARCServerAdmin.trash(data);
        dispatch({
            type: DELETE_ARCHITECTURESERVERADMIN,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};