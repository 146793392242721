import React, { useEffect, useState } from 'react';
// import Avatar from '../../../assets/avatars/avatar1.jpeg';
import CircularProgressBar from './CircularProgressBar';

export default function SingleServiceUser(props) {
  let [shortView, setShortView] = useState(true);
  let [progressValue, setProgressValue] = useState({})

  useEffect(() => {
    setProgressValue({
      early: {
        type: "value",
        value: props?.single_service_user?.stats?.early,
        total_value: props?.single_service_user?.stats?.total,
      },
      late: {
        type: "value",
        value: props?.single_service_user?.stats?.late,
        total_value: props?.single_service_user?.stats?.total,
      },
      missed: {
        type: "value",
        value: props?.single_service_user?.stats?.missed,
        total_value: props?.single_service_user?.stats?.total,
      },
      total: {
        type: "value",
        value: props?.single_service_user?.stats?.total,
        total_value: props?.single_service_user?.stats?.total,
      }
    })
  }, [props])
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-2 d-grid"
      onClick={() => setShortView(!shortView)}
    >
      <a href='/ServiceUser/Overview' className="card">
        <div className="card-body">
          <div className="row align-items-center no-gutters">
            {/* <div className="col-auto">
              <img src={props?.single_service_user?.image} className="box-image p-1 img-circle" />
            </div> */}
            <div className="col me-2">
              <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                <span>{props?.single_service_user?.username}</span>
              </div>
              {/* <div className="text-ash mb-0 Poppins">
                <span>{props?.single_service_user?.type}</span>
              </div> */}
            </div>
            {/* <div className="col-auto"><i className={shortView ? "fas fa-angle-up fa-2x text-gray-300" : "fas fa-angle-down fa-2x text-gray-300"}></i></div> */}
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9">
              <div className="row">
                <div className="col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 p-4 mt-1">
                  <div className="container-fluid text-center p-0">Early</div>
                  {/* <div className='container-fluid'> */}
                  <CircularProgressBar data={progressValue.early} />
                  {/* </div> */}
                  <div className="container-fluid text-center">{progressValue?.early?.value ? progressValue?.early?.value : 0}</div>
                </div>

                <div className="col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 p-4 mt-1">
                  <div className="container-fluid text-center p-0">Late</div>
                  {/* <div className='container-fluid'> */}
                  <CircularProgressBar data={progressValue.late} />
                  {/* </div> */}
                  <div className="container-fluid text-center">{progressValue?.late?.value ? progressValue?.late?.value : 0}</div>
                </div>

                <div className="col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 p-4 mt-1">
                  <div className="container-fluid text-center p-0">Missed</div>
                  {/* <div className='container-fluid'> */}
                  <CircularProgressBar data={progressValue.missed} />
                  {/* </div> */}
                  <div className="container-fluid text-center">{progressValue?.missed?.value ? progressValue?.missed?.value : 0}</div>
                </div>

                <div className="col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 p-4 mt-1">
                  <div className="container-fluid text-center p-0">Total</div>
                  {/* <div className='container-fluid'> */}
                  <CircularProgressBar data={progressValue.total} />
                  {/* </div> */}
                  <div className="container-fluid text-center">{progressValue?.total?.value ? progressValue?.total?.value : 0}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
