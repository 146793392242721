import React, { Component, useEffect, useRef, useState } from "react";
import { render } from "react-dom";

import CanvasDraw from "react-canvas-draw";

import html2canvas from 'html2canvas';

import PalletImage1 from '../../../../assets/Canvas/img1.jpg'
import PalletImage2 from '../../../../assets/Canvas/img2.jpg'
import PalletImage3 from '../../../../assets/Canvas/img3.jpg'
import PalletImage4 from '../../../../assets/Canvas/img4.jpg'


let ServiceUserActivityCanvas = (props) => {
    let InitialData = {
        brushColor: "000000",
        color: "#ffc600",
        canvasWidth: 400,
        canvasHeight: 600,
        brushRadius: 10,
        lazyRadius: 10,
        backgroundImg: PalletImage3,
        activeCanvasId: 0,
        PalletImage1: PalletImage1,
        PalletImage2: PalletImage2,
        PalletImage3: PalletImage3,
        PalletImage4: PalletImage4,
        PalletImage1_canvas: {lines: [], width: 400, height: 600},
        PalletImage2_canvas: {lines: [], width: 400, height: 600},
        PalletImage3_canvas: {lines: [], width: 400, height: 600},
        PalletImage4_canvas: {lines: [], width: 400, height: 600},
        PalletImage_confirmed1: 0,
        PalletImage_confirmed2: 0,
        PalletImage_confirmed3: 0,
        PalletImage_confirmed4: 0,
    };

    // let [CanvasDraw, setCanvasDraw] = useState('');
    let [CanvasOption, setCanvasOption] = useState(InitialData);
    let [Loading, setLoading] = useState(false);

    const saveableCanvasRef = useRef(null);

    useEffect(() => {
        // let's change the background image every 2 seconds. fun!
        setTimeout(() => {
            setLoading(true)
        }, 2000);

    }, [])

    let handleBrushColor = (color) => {
        setCanvasOption({ ...CanvasOption, ['brushColor']: color })
    }

    let handleImagePallet = (image, id) => {
        setCanvasOption({ ...CanvasOption, ['backgroundImg']: image, ['activeCanvasId']: id })
    }

    let handleConfirm = () => {
        const input = document.getElementById('canvasImageContainer');
        if(CanvasOption?.activeCanvasId == 1) {
            localStorage.setItem("PalletImage1_canvas", saveableCanvasRef.current.getSaveData());
            html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                setCanvasOption({ ...CanvasOption, 
                    ['PalletImage1']: imgData, 
                    ['PalletImage_confirmed1']: 1, ['activeCanvasId']: 0 
                });
            });
        }
        if(CanvasOption?.activeCanvasId == 2) {
            localStorage.setItem("PalletImage2_canvas", saveableCanvasRef.current.getSaveData());
            html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                setCanvasOption({ ...CanvasOption, 
                    ['PalletImage1']: imgData, 
                    ['PalletImage_confirmed2']: 2, ['activeCanvasId']: 0 
                });
            });
        }
        if(CanvasOption?.activeCanvasId == 3) {
            localStorage.setItem("PalletImage3_canvas", saveableCanvasRef.current.getSaveData());
            html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                setCanvasOption({ ...CanvasOption, 
                    ['PalletImage1']: imgData, 
                    ['PalletImage_confirmed3']: 3, ['activeCanvasId']: 0 
                });
            });
        }
        if(CanvasOption?.activeCanvasId == 4) {
            localStorage.setItem("PalletImage4_canvas", saveableCanvasRef.current.getSaveData());
            html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                setCanvasOption({ ...CanvasOption, 
                    ['PalletImage1']: imgData, 
                    ['PalletImage_confirmed4']: 4, ['activeCanvasId']: 0 
                });
            });
        }
    }

    return (
        <div className="container-fluid h-inherit">
            <div className="row">
                <div className="container-fluid text-center mt-2">
                    <div className="pallet redPallet" onClick={() => handleBrushColor('#ff0000')}></div>
                    <div className="pallet bluePallet" onClick={() => handleBrushColor('#0004ff')}></div>
                    <div className="pallet greenPallet" onClick={() => handleBrushColor('#3cff00')}></div>
                    <div className="pallet yellowPallet" onClick={() => handleBrushColor('#fff700')}></div>
                    <div className="pallet orangePallet" onClick={() => handleBrushColor('#ffaa00')}></div>
                    <div className="pallet skyPallet" onClick={() => handleBrushColor('#00fffb')}></div>
                    <div className="pallet limePallet" onClick={() => handleBrushColor('#00ff8c')}></div>
                    <div className="pallet purplePallet" onClick={() => handleBrushColor('#c800ff')}></div>
                    <div className="pallet pinkPallet" onClick={() => handleBrushColor('#ff46cb')}></div>
                    <div className="pallet blackPallet" onClick={() => handleBrushColor('#000000')}></div>
                    <div className="pallet whitePallet" onClick={() => handleBrushColor('#ffffff')}></div>
                </div>
            </div>
            <div className="ml-2" id="canvasImageContainer">
                {Loading ?
                    CanvasOption?.activeCanvasId == 1 ?
                        <CanvasDraw
                            ref={saveableCanvasRef}
                            saveData={localStorage.getItem("PalletImage1_canvas")}
                            canvasWidth={CanvasOption.canvasWidth}
                            canvasHeight={CanvasOption.canvasHeight}
                            brushColor={CanvasOption.brushColor}
                            brushRadius="2"
                            lazyRadius="2"
                            imgSrc={CanvasOption.backgroundImg}
                            style={{margin: 'auto'}}
                        />
                    :
                    CanvasOption?.activeCanvasId == 2 ?
                        <CanvasDraw
                            ref={saveableCanvasRef}
                            saveData={localStorage.getItem("PalletImage2_canvas")}
                            canvasWidth={CanvasOption.canvasWidth}
                            canvasHeight={CanvasOption.canvasHeight}
                            brushColor={CanvasOption.brushColor}
                            brushRadius="2"
                            lazyRadius="2"
                            imgSrc={CanvasOption.backgroundImg}
                            style={{margin: 'auto'}}
                        />
                    :
                    CanvasOption?.activeCanvasId == 3 ?
                        <CanvasDraw
                            ref={saveableCanvasRef}
                            saveData={localStorage.getItem("PalletImage3_canvas")}
                            canvasWidth={CanvasOption.canvasWidth}
                            canvasHeight={CanvasOption.canvasHeight}
                            brushColor={CanvasOption.brushColor}
                            brushRadius="2"
                            lazyRadius="2"
                            imgSrc={CanvasOption.backgroundImg}
                            style={{margin: 'auto'}}
                        />
                    :
                    CanvasOption?.activeCanvasId == 4 ?
                        <CanvasDraw
                            ref={saveableCanvasRef}
                            saveData={localStorage.getItem("PalletImage4_canvas")}
                            canvasWidth={CanvasOption.canvasWidth}
                            canvasHeight={CanvasOption.canvasHeight}
                            brushColor={CanvasOption.brushColor}
                            brushRadius="2"
                            lazyRadius="2"
                            imgSrc={CanvasOption.backgroundImg}
                            style={{margin: 'auto'}}
                        />
                    :
                    CanvasOption?.activeCanvasId == 0 ?
                        <div className="container-fluid text-center mt-2">
                            <div className="row text-center mt-2">
                                <div className="col-md-3 ImagePallet text-center">
                                    <img src={CanvasOption?.PalletImage1} />
                                    <i className="fa fa-check-circle d-block"></i>
                                </div>
                                <div className="col-md-3 ImagePallet text-center">
                                    <img src={CanvasOption?.PalletImage2} />
                                    <i className="fa fa-check-circle d-block"></i>
                                </div>
                                <div className="col-md-3 ImagePallet text-center">
                                    <img src={CanvasOption?.PalletImage3} />
                                    <i className="fa fa-check-circle d-block"></i>
                                </div>
                                <div className="col-md-3 ImagePallet text-center">
                                    <img src={CanvasOption?.PalletImage4} />
                                    <i className="fa fa-check-circle d-block"></i>
                                </div>
                            </div>
                            <div className="row text-center mt-2">
                                <div className="col-md-6 ImagePallet" onClick={() => handleImagePallet(PalletImage1, 1)}>
                                    <img src={PalletImage1} />
                                </div>
                                <div className="col-md-6 ImagePallet" onClick={() => handleImagePallet(PalletImage2, 2)}>
                                    <img src={PalletImage2} />
                                </div>
                                <div className="col-md-6 ImagePallet" onClick={() => handleImagePallet(PalletImage3, 3)}>
                                    <img src={PalletImage3} />
                                </div>
                                <div className="col-md-6 ImagePallet" onClick={() => handleImagePallet(PalletImage4, 4)}>
                                    <img src={PalletImage4} />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="container-fluid text-center mt-2">
                            <div className="ImagePallet" onClick={() => handleImagePallet(PalletImage1, 1)}>
                                <img src={PalletImage1} />
                            </div>
                            <div className="ImagePallet" onClick={() => handleImagePallet(PalletImage2, 2)}>
                                <img src={PalletImage2} />
                            </div>
                            <div className="ImagePallet" onClick={() => handleImagePallet(PalletImage3, 3)}>
                                <img src={PalletImage3} />
                            </div>
                            <div className="ImagePallet" onClick={() => handleImagePallet(PalletImage4, 4)}>
                                <img src={PalletImage4} />
                            </div>
                        </div>
                    : ''}
            </div>
            {CanvasOption?.activeCanvasId > 0 ?
                <div className="container-fluid text-center mt-2 mb-2">
                    <button className="btn btn-primary btn-theme" onClick={handleConfirm}>Confirm</button>
                </div>
            : ''}
        </div>
    );
}

export default ServiceUserActivityCanvas;